define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = [["en", {
    "account": {
      "accountSettings": "Account settings",
      "administration": "Administration",
      "books": "Products",
      "changeSchool": "Change school",
      "classes": "Classes",
      "connectToSchool": "Connect to a new school",
      "groups": "Groups",
      "licenses": "Licenses",
      "logout": "Log out",
      "school": "My school",
      "students": "Students",
      "teachers": "Teachers"
    },
    "admin": {
      "CSVFileTooLarge": "The file can not contain more than 1000 rows.",
      "activate": "Activate",
      "active": "Active",
      "addBooks": "Select one or more products",
      "addButton": "Add {role}",
      "addClass": "Create new class",
      "addClassText": "Create classes to enable you to divide your students by class. A student can only belong to one class. Classes simplify for you when you create courses and assign licenses.",
      "addRows": "Add rows",
      "addStudents": "Add students",
      "addStudentsPasswordError": {
        "minLength": "The password is too short",
        "minLengthSub": "The password has to be at least 8 characters",
        "required": "Password missing",
        "requiredSub": "You have to enter a username to create a user"
      },
      "addStudentsPrint": {
        "addedRows": "{rows, plural, =1 {# account has been created and can be used by your student} other {# accounts have been created and can be used by your students}}",
        "closeButton": "Close",
        "confirm": "Did you save the students passwords? Do you want to close the window",
        "confirmButton": "Close window",
        "printButton": "Print or save as PDF",
        "printText": "The students can now login at konto.nok.se with the following usernames and passwords.",
        "sub": "The students have been created, it's important that you save the students passwords. We recommend that you print this list or choose to save the print-out as a PDF",
        "title": "The students have been created"
      },
      "addStudentsRow": {
        "clearRow": "Rensa rad",
        "removeRow": "Ta bort rad"
      },
      "addStudentsUsernameError": {
        "exists": "The username is already in use",
        "existsSchool": "The username is already in use",
        "existsSchoolSub": "There is a user with this username in your school already",
        "existsSub": "There is a user with this username on another school. Contact support if you want this user should be added to this school too.",
        "inputTwice": "The username is entered multiple times",
        "inputTwiceSub": "You can only enter a username once",
        "minLength": "The username is too short",
        "minLengthSub": "The username has to be at least 6 characters",
        "required": "Username missing",
        "requiredSub": "You have to enter a username to create a user"
      },
      "addTeachers": "Add teachers",
      "addUsers": {
        "multipleUsersFailed": "{count} users could not be added",
        "multipleUsersSucceeded": "{count} users was added",
        "userFailed": "The user {user} could not be added",
        "userSucceeded": "The user {user} was added"
      },
      "addUsersFailed": "The users could not be added",
      "addedClass": "Created class {name}",
      "address": "Invoice address",
      "allClasses": "All classes",
      "amount": "Amount",
      "archiveConfirm": "This course will be archived and moved to administration.",
      "archiveConfirmAction": "Archive course",
      "archiveCourse": "Archive",
      "archivedCourses": "Archived courses",
      "books": "Products",
      "bought": "Bought",
      "buyBooks": "Buy more products",
      "calendarYear": "Calendar year",
      "cancel": "Cancel",
      "checkingEmails": "Checking the e-mail addresses",
      "class": "Class",
      "classNameExists": "The class name already exists",
      "classNameLabel": "Class name",
      "classNameMissing": "Students without a class",
      "classNamePlaceholder": "Enter class name",
      "classNoUsers": "The class has no students",
      "classNumUsers": "{count, plural, =0 {No students} =1 {One student} other {# students}}",
      "classTooltip": "Here you can enter a class for the student.<br />It can also be done at a later time.",
      "classes": "Classes",
      "code": "Code",
      "codeUsesText": "{uses} {role} have used this code",
      "codeUsesTextAlternative": "{code} has been used {uses} times by {role}",
      "codeValidUntil": "Valid until {date}",
      "confirmAdd": "Add users",
      "confirmImport": "Import",
      "confirmOrder": "Confirm order",
      "continue": "Continue",
      "cost": "Cost",
      "courseCreatedAt": "Created at",
      "courses": "Courses",
      "createAccounts": "Create accounts",
      "createAccountsClass": "Class name",
      "createAccountsExplanation": "<p class=\"my1\">Fill in the table with the student accounts you want to create. Each student needs a <b> username </b> and <b> password </b>. You can also select or create a <b> class </b> for students.<br />Once you have created the accounts, you will be given a list to print or save.</p>",
      "createAccountsFirstName": "Firstname",
      "createAccountsLastName": "Lastname",
      "createAccountsPassWord": "Password *",
      "createAccountsUserName": "Username *",
      "createClass": "Create class",
      "createClassButton": "Add",
      "createStudentAccountsWithUserNameTitle": "Create student accounts with username",
      "createStudentAccountsWithUserNamesTitle": "Create student accounts with usernames",
      "createStudentsWithUsernameContent": "For students without an e-mail address",
      "createStudentsWithUsernameTitle": "Create student account with username",
      "csvImportFail": "Then CSV import failed. Please try again.",
      "csvInstruction": "Drag the headings to the correct column",
      "csvInstructionHeader": "Choose which field to use for what",
      "customerService": "Customer support",
      "demos": "Demo",
      "discount": "Discount",
      "eMail": "E-mail",
      "emails": "E-mail addresses",
      "emailsInfo": "Write e-mail addresses separated by comma, space or a line break.",
      "emptyClassName": "You have to enter a class name",
      "enterAddresses": "Write or paste one or more e-mail addresses.",
      "enterMessage": "Enter any address changes here",
      "enterPassword": "Enter password",
      "expandLabel": "Current period: {start_date} until {end_date}",
      "expandLicense": "Buy more licenses",
      "exportUsersToExcel": "Download a list with all students and teachers (Excel)",
      "extendLabel": "Next period: {start_date} until {end_date}",
      "extendLicense": "Buy a new period",
      "externalLicensesMessage": "<p class=\"m0\">{provider, select, null {Your school's license management has been disabled. Contact <a class=\"text-color-default text-underline\" href=\"https://support.nok.se\" target=\"_blank\">customer support</a> regarding purchase of digital products.} other {Your school's license management has been disabled because the school's licenses are managed externally through an integration with <strong> {provider} </strong>. To buy digital products, contact the person at the school who is responsible for the integration.}}</p>",
      "externalUsersMessage": "<p class=\"m0\">{provider, select, null {Your school's user management has been disabled. Contact <a class=\"text-color-default text-underline\" href=\"https://support.nok.se\" target=\"_blank\">customer support</a> if you need to make changes to your classes or manage students or teachers.} other {Your school's user management has been disabled because the school's users are managed externally through an integration with <strong>{provider}</strong>. Teacher and student accounts are automatically created when users log in through the integration. If you need to make changes to your classes or manage students or teachers accounts, you should contact the person at the school who is responsible for the integration.}}</p>",
      "findingEmails": "Finding e-mail addresses",
      "firstName": "First name",
      "firstNameTooltip": "If you leave the first name blank,<br />the student must fill it in at the first login.",
      "generate": "Generate",
      "generatePopup": {
        "addAllToSameClass": "Add all to the same class",
        "addToClassHelpText": "You can change the class for all students in the<br />table. Choose a class that already exists<br />at the school or create a completely new class.",
        "addToExistingClassLabel": "Existing class",
        "addToNewClassLabel": "New class",
        "changeClassForEveryone": "Change class for everyone",
        "enterEightChars": "Enter at least 8 characters",
        "enterFiveChars": "Enter at least 5 characters",
        "enterPassword": "Enter password",
        "enterPasswordHelpText": "All users will get the same password",
        "enterPasswordPlaceholder": "E.g butterfly",
        "generateNewPasswords": "Generate new passwords",
        "generatePassword": "Generate password",
        "generatePasswordHelpText": "If you choose to generate passwords, all existing passwords<br />in the table will be replaced with new passwords.",
        "helpTextAddUsers": "<strong>Example of usernames from template</strong><p><span class=\"text-muted\">dandelion1, dandelion2, dandelion3...</p>",
        "noClassLabel": "Without class",
        "numOfUsersLabel": "Number of accounts to generate",
        "passwordExamples": "<p><strong>Example password<br /></strong>W3@OxA7£</p>",
        "passwordPlaceholder": "At least 8 characters",
        "prefixTooShort": "The prefix is too short, must be at least 5 characters",
        "prefixUsed": "{prefix} already used",
        "templateForUserNamesLabel": "Template for usernames",
        "tooManyUsers": "You can only create {number} users at a time",
        "useSameForAllLabel": "Same for everyone",
        "usernamePlaceholder": "At least 6 characters"
      },
      "handleLicenses": "Handle licenses",
      "importCSVChooseFile": "Choose CSV-file",
      "importCSVContent": "Import existing student data using a CSV file.",
      "importCSVContentTeachers": "Import existing teacher data using a CSV file.",
      "importCSVDescriptionStudents": "<b>E-mail</b> is the only required field. If <b>name</b> is missing, the students will have to enter that after logging in. An e-mail with a link is sent to the e-mail address. <b>Class</b> kan be choosen or changed afterwards.",
      "importCSVDescriptionTeachers": "<b>E-mail</b> is the only required field. If <b>name</b> is missing, the teachers will have to enter that after logging in. An e-mail with a link is sent to the e-mail address.",
      "importCSVDownloadExample": "Download an example",
      "importCSVModalTitle": "Import {role} with e-mail",
      "importCSVNoFile": "Choose a CSV-file",
      "importCSVNoMoreProperties": "No more fields available",
      "importCSVTitle": "Import {role} from CSV file",
      "importCSVValidating": "validating",
      "importCSVValidatorAnd": "and",
      "importCSVValidatorCanNotBeImported": "and can not be imported",
      "importCSVValidatorInvalidEmailContent": "The e-mail address is invalid and the account can not be created.",
      "importCSVValidatorInvalidEmailDescription": "Make sure the e-mail address is correct in your CSV-file and upload it again.",
      "importCSVValidatorInvalidEmailTitle": "Invalid e-mail address",
      "importCSVValidatorInvalidEmailValidationMessage": "{count, plural, =1 {1 e-mail address is} other {# e-mail addresses are}} invalid",
      "importCSVValidatorTeacherContent": "The e-mail address is already used by a teacher.",
      "importCSVValidatorTeacherDescription": "Remove the e-mail address in your CSV-file and upload it again.",
      "importCSVValidatorTeacherTitle": "The e-mail is owned by a teacher",
      "importCSVValidatorTeacherValidationMessage": "{count, plural, =1 {1 e-mail address is used by a teacher} other {# e-mail addresses are used by teachers}}",
      "inactivate": "Inactivate",
      "inactive": "Inactive",
      "inactiveUser": "The user will be removed {date} due to inactivity. This will not happen if the user logs in or is assigned a license before that.",
      "inactiveUserList": "The user will be removed due to inactivity",
      "invalidCSV": "The file could not be interpreted.",
      "invalidEmails": "{numUsers, plural, =1 {1 e-mail address is incorrect} other {# e-mail address is incorrect}}",
      "invalidEmailsSub": "{numUsers, plural, =1 {Check that the e-mail address is correctly entered} other {Check that the e-mail addresses are correctly entered}}",
      "invite": "Invite {role}",
      "inviteDescription": "An activation link will be sent to the e-mail addresses, where the {roleName, select, students {students} other {teachers} } will be able to enter their name and password.",
      "inviteRole": "Invite {role}",
      "inviteWithCodeContent": "Invite your {role, select, students {students} other {colleagues} } using a link or code",
      "inviteWithCodeTitle": "With link or school code",
      "inviteWithEmailContent": "{role} will receive an activation link in their e-mail",
      "inviteWithEmailTitle": "Via e-mail",
      "invoiceSentTo": "Fakturan skickas till {username}",
      "lastName": "Surname",
      "lastNameTooltip": "If you leave the last name blank,<br /the student must fill it in at the first login.",
      "licenseForNumUsers": "For {count} users",
      "licenseFrom": "From: {date}",
      "licenseOverview": {
        "email": "E-mail address",
        "heading": "License overview",
        "name": "Name",
        "noSearchResults": "<em>Could not find any users matching <strong>{search}</strong></em>",
        "role": "Role",
        "searchPlaceholder": "Search users",
        "student": "Student",
        "teacher": "Teacher"
      },
      "licensePeriod": "License period",
      "licenseStatus": "Status",
      "licenseTimePeriod": "Valid thru: {active_from} until {active_until}",
      "licenseUntil": "Until: {date}",
      "licenses": "Licenses",
      "message": "Message",
      "moveStudentsToClass": "Move students to this",
      "moveUsers": {
        "allGroups": "All classes",
        "cancel": "Cancel",
        "chooseStudents": "Select students to move to {class_name}",
        "chosen": "{num_users} selected students",
        "eMail": "E-mail",
        "groupNoUsers": "The class has no students",
        "groupNumUsers": "{count, plural, =0 {No students} =1 {One student} other {# students}}",
        "groups": "Classes",
        "move": "Move",
        "name": "Name",
        "noStudents": "No students",
        "numStudents": "{count, plural, one {One student} other {{count} students }}{hasGroups, select, true { in {countGroups, plural, one {one group} other {{countGroups} groups}}} other {}}",
        "search": "Search",
        "searchStudents": "Search students",
        "snack": "{num_users, plural, =1 {{num_users} student moved to {class_name}} other {{num_users} students moved to {class_name}}}"
      },
      "mySchool": "My school",
      "name": "Name",
      "neverLogin": "Never logged in",
      "newClass": "New class",
      "noBooks": "No product licenses found",
      "noClassSearchResults": "No classes match the search",
      "noCourses": "No courses found",
      "noProducts": "You have not added any products",
      "noSchoolLicenses": "The school does not have any licenses.",
      "noStudents": "Could not find any students",
      "noTeachers": "Could not find any teachers",
      "numProducts": "{products} products",
      "numStudents": "{count, plural, one {One student} other {{count} students }}{hasGroups, select, true { in {countGroups, plural, one {one group} other {{countGroups} groups}}} other {}}",
      "numTeachers": "{count} teachers",
      "openCourse": "Open course",
      "orderConfirmFailed": "Your order could not be delivered.",
      "orderConfirmSuccess": "Your order has been delivered and an order confirmation has been sent to your e-mail address.",
      "orderHistory": "History",
      "orderTime": "Date",
      "orderedItems": "{count, plural, =1 {You have added an item to an order} other {You have added # items to an order}}",
      "orderer": "Orderer",
      "periodLabel": "Period: {start_date} until {end_date}",
      "personal": "Personal",
      "productPrice": "{license, select, null {} other {{license} }}at {amount} SEK per unit",
      "purchaseInfo": "<p>För att erhålla de priser och villkor som din kommun upphandlat, måste beställningar läggas hos den återförsäljare som kommunen har ramavtal med; <a href=\"https://www.laromedia.se/\" target=\"_blank\">LäroMedia</a> eller <a href=\"https://www.goteborgsregionen.se/laromedel\" target=\"_blank\">GR (Göteborgsregionen)</a>.</p><p>När du lägger din beställning här skickas fakturan från Natur & Kultur till din e-postadress.</p>",
      "reference": "Reference",
      "removeAllStudents": "Remove all students",
      "removeAllStudentsWarning": "Warning. All students and classes will be removed. This cannot be undone.",
      "removeClass": "Remove class",
      "removeClassConfirm": "Remove the class and students from the school?",
      "removeCourseWarning": "Remove the course?",
      "removeOrder": "Remove order",
      "removeStudent": "Remove student",
      "removeStudentsAndClasses": "Remove all students and classes from the school",
      "removeStudentsConfirmExplanation1": "Please type",
      "removeStudentsConfirmExplanation2": "in the field below to confirm.",
      "removeStudentsConfirmText": "Remove all students at the school",
      "removeUser": "Remove user",
      "removeUserWarning": "The user will be removed from the school",
      "removeUsers": "Remove all users",
      "removeUsersFailed": "{count, plural, =1 {The user could not be removed} other {The users could not be removed}}",
      "removeUsersSucceeded": "{count, plural, =1 {The user was removed} other {The users were removed}}",
      "renameClass": "Rename",
      "renewCode": "Create new code",
      "resetPassword": "Reset password",
      "restoreCourse": "Restore course",
      "saveClassName": "Save",
      "saveNewClass": "Save",
      "savePassword": "Save password",
      "schoolLicense": "School license",
      "schoolYear": "School year",
      "search": "Search",
      "searchBooks": "Search products",
      "searchClass": "Search class",
      "searchCourses": "Search courses",
      "searchStudents": "Search students or class",
      "searchTeachers": "Search teachers",
      "selectBooks": "Choose product",
      "selectClass": "Choose class",
      "setClassForAll": "Set classname for everyone",
      "setPassword": "Choose password",
      "shared": "Shared",
      "show": "Show",
      "showArchivedCourses": "Show archived courses",
      "showCodeHistory": "Show uses",
      "showMore": "Show more",
      "students": "Students",
      "studentsAlreadyPresent": "{numUsers, plural, =1 {1 student is already present} other {# students are already present}}",
      "studentsAlreadyPresentSub": "Existing e-mail addresses don't have to be added again",
      "studentsCanBeAdded": "{numUsers, plural, =1 {1 student can be added} other {# students can be added}}",
      "studentsCanBeAddedSub": "The e-mail addresses are correct and can be added as students",
      "sum": "{amount} SEK",
      "teachers": "Teachers",
      "teachersAlreadyPresent": "{numUsers, plural, =1 {1 e-mail address belongs to a teacher} other {# e-mail addresses belongs to teachers}}",
      "teachersAlreadyPresentSub": "{numUsers, plural, =1 {Can't be added as a student} other {Can't be added as students}}",
      "teachersCanBeAdded": "{numUsers, plural, =1 {1 teacher can be added} other {# teachers can be added}}",
      "teachersCanBeAddedSub": "The e-mail addresses are correct and can be added as teachers",
      "terms": "Terms of purchase",
      "timePeriod": "Time period",
      "totalSum": "Sum",
      "unlimited": "Unlimited",
      "userLastLoginHeading": "Logged In",
      "users": "Users",
      "usersCanBeAddedSub": "{numUsers, plural, =1 {The username us unique and valid} other {The usernames are unique and valid}}",
      "vatInfo": "All prices are shown without VAT.",
      "viewOrder": "View order",
      "viewUser": "View user",
      "viewingOrder": "Viewing order",
      "withoutClass": "Without class"
    },
    "answerComments": {
      "commentButton": "Comment",
      "commentPlaceholder": "Write a comment",
      "events": {
        "heading": "History",
        "rejectedUserCommented": "<em>Deleted user</em> added a comment",
        "rejectedUserCorrect": "<em>Deleted user</em> got version {version} correct",
        "rejectedUserStartedVersion": "<em>Deleted user</em> started a new version",
        "rejectedUserSubmitted": "<em>Deleted user</em> submitted version {version}",
        "rejectedUserWrong": "<em>Deleted user</em> got version {version} wrong",
        "someoneCommented": "{name} added a comment",
        "someoneCorrect": "{name} got version {version} correct",
        "someoneStartedVersion": "{name} started a new version",
        "someoneSubmitted": "{name} submitted version {version}",
        "someoneWrong": "{name} got version {version} wrong",
        "youCommented": "You added a comment",
        "youCorrect": "You got version {version} correct",
        "youNoted": "You added a note",
        "youStartedVersion": "You started a new version",
        "youSubmitted": "You submitted version {version}",
        "youWrong": "You got version {version} wrong"
      },
      "noComments": "No comments",
      "noteButton": "Note",
      "notePlaceholder": "Write a note",
      "notesDisclaimer": "Notes are only visible for you",
      "remove": "Remove",
      "tabs": {
        "comment": "Comment",
        "note": "Own note"
      }
    },
    "answerLog": {
      "commentFilter": {
        "all": "Show all",
        "commented": "Show commented"
      },
      "diagnosis": {
        "aborted": "The attempt was aborted",
        "active": "The attempt is active",
        "attempt": "Attempt {attempt}",
        "noResults": "No results"
      },
      "entityFilter": {
        "allOption": "All",
        "areaLabel": "Area",
        "areaPlaceholder": "Select book first",
        "assignmentSearchLabel": "Search",
        "assignmentSearchPlaceholder": "Search assignment",
        "bookLabel": "Book",
        "contentLabel": "Content",
        "contentPlaceholder": "Select area first"
      },
      "filterShowing": "Showing:",
      "filterString": "{numStatuses, plural, =0 {No statuses} =1 {{lastStatus}} =7 {All statuses} other {{otherStatuses} and {lastStatus}}} {commentStatus, select, commented {with comments} other {}} in {selectedContent, select, null {all contents} other {{selectedContent}}} {assignmentSearch, select, null {} other {where the assignment's title contains {assignmentSearch}}}",
      "markAllAsRead": "Mark all in the selection as reviewed",
      "standard": {
        "allAssignments": "All assignments",
        "autoCorrected": "Auto corrected",
        "filters": {
          "audited": "Reviewed",
          "correct": "Correct answer",
          "inbox": "Turned in*",
          "started": "Started",
          "teacherCorrect": "Completed",
          "teacherWrong": "Not completed",
          "turnedIn": "Turned in",
          "wrong": "Wrong answer"
        },
        "hideFilters": "Hide filters",
        "noResults": "No results",
        "showFilters": "Show filters",
        "teacherCorrected": "Teacher corrected"
      },
      "teacherAssignment": {
        "noResults": "No results"
      },
      "unknownDate": "Unknown date"
    },
    "archives": {
      "allCategories": "All categories",
      "category": "Category",
      "orderBy": "Sorting",
      "random": "Random",
      "search": {
        "noResultsOn": "Your search had no results. Use the general search function for a broad search in the library.",
        "placeholder": "Search the titles on the page …",
        "relatedSearchResults": "Related search results",
        "searchResults": "Search results",
        "searching": "Searching …",
        "showingResults": "Showing {results} of {total} results."
      },
      "showAllInFolder": "Show all in {folder}",
      "sort": {
        "date_asc": "Latest first",
        "date_desc": "Oldest first",
        "inverse": "Inverse",
        "name_asc": "Title A-Z",
        "name_desc": "Title Z-A",
        "random": "Random",
        "standard": "Standard"
      }
    },
    "area": "Area",
    "areas": {
      "search": {
        "label": "Search results",
        "noResultsOn": "Your search had no results. Use the general search function for a broad search in the library.",
        "placeholder": "Search the titles on the page …",
        "results": "Showing {hits} results.",
        "searching": "Searching …"
      },
      "view": {
        "repeat": "Repeat"
      }
    },
    "assignments": {
      "edit": "Edit",
      "editor": {
        "solution": "Key"
      },
      "heading": "Assignments",
      "missingAnswer": {
        "info": "The answer cannot be displayed. The assignment has been answered previously. The answer is missing but the result is still here. If you want, you can answer the assignment again.",
        "startOver": "Do the assignment again"
      },
      "removeFromWorkspace": "Unlink",
      "step": "Assignment {number} of {total}"
    },
    "browserTest": {
      "cancel": "Back",
      "cookiesDisabled": "Cookies are disabled",
      "cookiesEnabled": "Cookies are enabled",
      "description": "If all fields are green your browser works with the platform.",
      "header": "Browser test",
      "invalidBrowser": "Your browser ({browser}) is not supported.",
      "invalidBrowserVersion": "Your version of {browser} (version {currentVersion}) is not supported. Minimum version required is {minimumVersion}."
    },
    "calendar": {
      "lastWeek": "Last week",
      "lastWeekAtTime": "{dayName} at {time}",
      "lastWeekAtTimeLower": "{dayName} at {time}",
      "today": "Today",
      "todayAtTime": "Today at {time}",
      "todayAtTimeLower": "today at {time}",
      "yesterday": "Yesterday",
      "yesterdayAtTime": "Yesterday at {time}",
      "yesterdayAtTimeLower": "yesterday at {time}"
    },
    "category": "Category",
    "choosePassword": {
      "apiError": "Something went wrong",
      "changePassword": "Change password",
      "changePasswordButton": "Change password",
      "confirmPassword": "Write the same password again",
      "confirmPasswordPlaceholder": "Write your new password once more",
      "currentPassword": "Current password",
      "currentPasswordPlaceholder": "Write your current password",
      "description": "Enter a new password for your account. Your new password should contain at least 8 characters.",
      "errors": {
        "noTokenPosted": "Something went wrong. Go back to login and try again by pressing ”Forgot your password?”.",
        "other": "Something went wrong. Go back to login and try again by pressing ”Forgot your password?”.",
        "passwordToShort": "The password must be at least 8 characters.",
        "passwordsNotMatch": "The passwords did not match. Try again.",
        "sessionInvalid": "The session has expired. Go back to login and start over by pressing ”Forgot your password?”.",
        "wrongPassword": "Wrong password"
      },
      "header": "Choose a password",
      "password": "Fill in a new password",
      "passwordChanged": "The password has now changed",
      "passwordPlaceholder": "Fill in your new password",
      "success": "Now you can login with your new password. Click on \"Login\".",
      "token_already_used": "The link you followed is no longer active, as an account has already been created.",
      "token_expired": "The link you followed is no longer active.",
      "token_expired_button": "Request a new link",
      "token_expired_required": "A new link has been sent. Follow the instructions in the e-mail. If you do not receive an e-mail, please check your spam folder.",
      "token_expired_required_failed": "A new link could not be sent. Please try again later."
    },
    "codeCopiedToClipboard": "The code was added to the clipboard",
    "codeFailedToCopyToClipboard": "The code could not be added to the clipboard",
    "collection": {
      "audioToolTip": "Click here to listen",
      "backTooltip": "Back to assignments",
      "sidebarLabel": "Assignments"
    },
    "collectionAudit": {
      "assignments": "Assignments",
      "audited": "Reviewed",
      "compare": "Compare",
      "edit": "Edit",
      "nextStudent": "Next student",
      "numAnswers": "Amount of answers",
      "studentListLabel": "Students",
      "students": "Students",
      "teacherCorrect": "Completed",
      "teacherWrong": "Not completed",
      "version": "Version {version}",
      "versions": "Versions"
    },
    "components": {
      "activationCodeModal": {
        "activateButtonLabel": "Activate",
        "cancelButtonLabel": "Cancel",
        "infoText": "Do you have an activation code? Enter it here to get access to the product.",
        "inputErrorMessage": "Invalid activation code. Please check your input.",
        "inputLabel": "Activation code",
        "inputPlaceholder": "Example: N-NFEX-EB64-8BDA",
        "title": "Activate product"
      },
      "admin": {
        "schoolCode": {
          "activate": "Activate",
          "codeCopied": "Code copied to the clipboard",
          "copy": "Copy link",
          "copyUrlTitle": "Copy invitation link:",
          "done": "Done",
          "linkCopied": "Link copied to the clipboard",
          "menu": {
            "disableCodeTitle": "Disable access code",
            "disableDescription": "If you disabled the code you can no longer invite colleagues using the code or the link.",
            "historyDescription": "See how the code is being used.",
            "historyTitle": "Show history",
            "renewCodeDescription": "When renewing the code, the old one will be deactivated.",
            "renewCodeTitle": "Renew code"
          },
          "missingCode": "Access code is not active",
          "options": "Manage",
          "shareDescription": "Invite your colleagues by sharing this link. They will be able to connect to the school - and create an account if they don't already have one.",
          "shareTitle": "Copy invitation link:",
          "showQr": "Show QR code",
          "title": "Invite teacher with link or code",
          "useCode": "Use access code:"
        }
      },
      "availableLicensesModal": {
        "ableToAssignDemoAndRecyclableLicenses": "You are still able to assign demo licenses to your students.",
        "ableToAssignDemoLicenses": "You are still able to assign demo licenses to your students.",
        "allUsers": "All users ({num})",
        "assign": "Assign",
        "assignAllUsers": "Assign licenses",
        "assignFreeLicense": "Assign demo license",
        "assignLicense": "Assign new license",
        "assignStartedLicense": "Assign started license",
        "chooseLicense": "Choose license you want to assign",
        "chooseLicenses": "Please choose a set of licenses:",
        "classes": "Classes",
        "close": "Close",
        "confirmRecycleLicenses": "Assign {total, plural, =1 {license} other {licenses}}",
        "courses": "Courses",
        "done": "Done",
        "expired": "Expired {date}",
        "expires": "Expires {date}",
        "extend": "Extend",
        "extended": "Extended",
        "externalExpired": "External license expired {date}",
        "externalExpires": "External license expires {date}",
        "externalLicenses": "External licenses is managed at provider",
        "externalTotalLicenses": "<b>{number}</b> users have licenses",
        "freeExpires": "Demo license expires {date}",
        "freeExtraMaterial": "Free extramaterial",
        "freeTooltipHeading": "Try for free for 30 days",
        "freeTooltipMessage": "With a testlicense, a user on your school are able to try the product for free for 30 days.",
        "freeTooltipUntilHeading": "Try for free until {date}",
        "freeTooltipUntilMessage": "With a testlicense, a user on your school are able to try the product for free until {date}.",
        "freeUsed": "Demo used",
        "freeValidThru": "Valid 1 month",
        "freeValidUntil": "Valid until {date}",
        "groups": "Groups",
        "handledExternally": "Licenses are managed in Skolon",
        "handledInUnknownSystem": "another system",
        "hideUserGroups": "Hide groups",
        "license": "License",
        "licenseInfoModal": {
          "buyLicenses": "Buy licenses",
          "buyLicensesInfo": "Do you want to buy more license for {title}? Visit your retail or <a href=\"https://www.nok.se/redirect/products/{isbn}\" target=\"_blank\" rel=\"noopener noreferrer\" class=\"text-color-default text-ui\">nok.se</a>.",
          "header": "Licenser for school",
          "licenses": "Licenses",
          "licensesInfo": "Your school owns <b>{total}</b> licenses, <b>{assigned}</b> of them are assigned and <b>{unassigned}</b> are free.",
          "licensesInfoNoLicenses": "Your school owns <b>0</b> licenses.",
          "licensesInfoOnlyTeachers": "The licenses can only be assigned to teachers.",
          "readMoreLink": "Read more about licenses on our support page",
          "recyclable": {
            "info1": "Of <strong>{assignableLicenses}</strong> free licenses, <strong>{unstarted}</strong> are unstarted and <strong>{started}</strong> are started.",
            "info2": "Licenses older than 4 months will be started automatically.",
            "readMore": "Read more"
          },
          "testLicenses": "Test licenses",
          "testLicensesInfo": "With a test license you can try the product for 30 days. You can test one product once per year. You can try the product together with your students by assigning them test licenses.",
          "testLicensesInfoUntil": "With a test license you can try the product until {until}. You can test one product once per year. You can try the product together with your students by assigning them test licenses.",
          "usedLicenses": "Your school are now using <b>{demo}</b> test licenses."
        },
        "licenseTime": "A license is valid {licenseTime} from start.",
        "licenseTimeTestAgreement": "There are unlimited test licenses available until {agreementEndDate}.",
        "missingLicense": "Missing license",
        "mustBeStartedBefore": "Must be started before {date}",
        "nameAndNum": "{name} ({num})",
        "noClasses": "No classes",
        "noCourses": "No courses",
        "noGroups": "No groups",
        "noLicenses": "No licenses",
        "noUsers": "No users",
        "notEnoughLicenses": "Your school does not have enough licenses to assign all members of the course.",
        "notForStudents": "Not for students",
        "numberOfRecyclable": "{number_of_licenses}",
        "numberOfUsersWithoutLicenses": "users in",
        "ok": "Ok",
        "personalExpired": "Personal license expired {date}",
        "personalExpires": "Personal license expires {date}",
        "personalValidThru": "Personal license valid thru {date}",
        "ready": "Ready to be started",
        "recyclableActiveUntil": "Valid thru {date}",
        "recycle": "Recycle",
        "recycleLicense": "Recycle started license",
        "recycleLicenses": "Recycle started license ({number})",
        "recycleLicensesNotComplete": "You will assign <b>{available_licenses}</b> {available_licenses, plural, =1 {license} other {licenses}} valid to {date}.",
        "recycleLicensesToGroup": "You will assign <b>{number_of_licenses}</b> licenses valid to {date}. Everyone in the group will get a license.",
        "recycleLicensesToSingleUser": "You will assign <b>{number_of_licenses}</b> licenses valid to {date}.",
        "recycleLicensesWithRemainder": "You will assign <b>{available_licenses}</b> {available_licenses, plural, =1 {license} other {licenses}} valid to {date}. The remaining <b>{remaining_licenses}</b> users can be assigned a license after this assignment is complete.",
        "remove": "Retract",
        "removeExtension": "Remove extension",
        "removeLicense": "Remove licens",
        "removeLicensesInfo1": "keeps the started license but will lose the license that would be used as an extension.",
        "removeLicensesInfo2": "The license isnt started and will be available to assign again.",
        "removeLicensesInfo3": "The license is started bit can be recycled by another user at the school the validity period.",
        "removeLicensesInfo4": "Recyclable until:",
        "searchUsers": "Search users",
        "shortMore": "et al.",
        "showUserGroups": "Show groups",
        "started": "Started",
        "student": "Student",
        "teacher": "Teacher",
        "totalAssignedLicenses": "<b>{number}</b> assigned licenses",
        "totalFreeLicenses": "<b>{number}</b> licenses assigned",
        "totalLicenses": "<b>{unassigned}</b> of <b>{total}</b> licenses can be assigned",
        "unlimitedLicenses": "Unlimited number of licenses. A license is valid until {licenseTime}.",
        "usedDemoLicenses": "{numberOfUsedDemoLicenses} {numUsers, plural, =1 {user} other {users}} have used their test {numberOfUsedDemoLicenses} {numUsers, plural, =1 {license} other {licenses}} for {title} and will not be able to get a new.",
        "userWillLoseLicense": "will lose the license.",
        "validFromStart": "Valid {licenseTime} from start",
        "validFromStartUntil": "Valid until {licenseTime}",
        "validThru": "Valid thru {date}",
        "withoutLicenses": "without license."
      },
      "avatar-picker": {
        "avatar": "Avatar",
        "chooseAvatar": "Choose avatar",
        "search": "Search for emoji"
      },
      "contextSelect": {
        "selectCourseContext": {
          "heading": "Select course",
          "info": "This content is part of several courses you are participating in. Select a course to continue."
        }
      },
      "coursePostList": {
        "stateNavItems": {
          "all": "All",
          "published": "Published"
        }
      },
      "courseProductCard": {
        "courseAdministration": "Course administration",
        "new": "New"
      },
      "customContentDetailModal": {
        "cancelButtonLabel": "Cancel",
        "numberOfTriesInputLabel": "Number of tries",
        "numberOfTriesInputPlaceholder": "Unlimited",
        "saveButtonLabel": "Save",
        "teacherMaterial": "Teacher material",
        "title": "Customize: {entityTitle}"
      },
      "doneModal": {
        "doneButtonLabel": "Done"
      },
      "fileChooseModal": {
        "additionCollatorEmptyMessage": "<b>You have no files yet.</b> Here you will find all pictures, video files, audio files and documents that you have attached to various exercises in your books. You can reuse files and attach them to multiple exercises in your books.",
        "additionCollatorNoResultMessage": "No files were found.",
        "title": "Files"
      },
      "fileUpload": {
        "performButtonLabel": "Upload file ..."
      },
      "fileWarning": {
        "missingFiles": "{num, plural, =1 {An attached file} other {# attached files}} could not be found."
      },
      "footnoteList": {
        "headingLabel": "Footnotes"
      },
      "imageCardCollection": {
        "course": "Course",
        "students": "{students} {students, plural, =1 {student} other {students}}",
        "teachers": "{teachers} {teachers, plural, =1 {teacher} other {teachers}}"
      },
      "ltiInfoLink": {
        "infoText": "To use this product in a course in {provider}, copy the link below and follow the instructions.",
        "infoTextReadMore": "Read more",
        "title": "Add to {provider}"
      },
      "noticeManager": {
        "maintenanceMessage": {
          "buttonText": "Ok, thanks"
        }
      },
      "postItem": {
        "targetedToSelectedBadgeLabel": "Targeted to selected"
      },
      "productCollator": {
        "noResultAlertMessage": "No books were found.",
        "searchButtonLabel": "Search",
        "searchInputPlaceholder": "Search books",
        "showExtraMaterial": "Show extra material",
        "stageSelectPlaceholder": "All stages",
        "subjectSelectPlaceholder": "All subjects"
      },
      "productPicker": {
        "item": {
          "extramaterialTextLabel": "Extra material",
          "extramaterialTooltip": "Free extra material",
          "numberOfLicensesText": "{numberOfLicensesLeft, plural, one {1 license} other {# licenses} }",
          "numberOfLicensesTooltip": "Number of licenses to assign",
          "pickButtonLabel": "Add",
          "trialTextLabel": "Try for free",
          "trialTooltip": "Try 30 days for free",
          "trialTooltipUntil": "Try for free until {date}",
          "unableToPickButtonLabel": {
            "invalidType": "Internal and external products can not be used in the same course at the moment",
            "teachersOnly": "The product is for teachers only and can not be added to the course"
          },
          "unlimitedTextLabel": "Unlimited",
          "unlimitedTooltip": "Unlimited licenses to assign",
          "unpickButtonLabel": "Remove"
        }
      },
      "productPickerModal": {
        "abortButtonLabel": "Cancel",
        "activationCode": "Activation code",
        "handledExternally": "Licenses are managed in Skolon",
        "handledInUnknownSystem": "another system",
        "navItems": {
          "products": "Products",
          "tryForFree": "Try for free"
        },
        "saveButtonLabel": "Save",
        "title": "Choose products"
      },
      "productQuerier": {
        "errorAlertMessage": "The books could not be loaded.",
        "extraCheckboxLabel": "Show extra material",
        "noResultAlertMessage": "No books were found.",
        "searchButtonLabel": "Search",
        "searchInputPlaceholder": "Search books",
        "stageSelectPlaceholder": "All stages",
        "subjectSelectPlaceholder": "All subjects"
      },
      "productRoot": {
        "productInformation": "Product information"
      },
      "productsCoursesOverview": {
        "filters": {
          "ordering": {
            "all": "Courses first",
            "courses": "Courses first",
            "label": "Showing:",
            "products": "Products first"
          },
          "sorting": {
            "date": "Last added",
            "label": "Order by:",
            "title": "A - Z"
          }
        },
        "openProductsPopup": {
          "buttonText": "Add products",
          "popup": {
            "activationCode": {
              "description": "Use your activation code to activate product.",
              "title": "With activation code"
            },
            "products": {
              "description": "Choose from purchased products and from 100s of free extra materials.",
              "title": "Products or extra materials"
            },
            "tryFree": {
              "description": "Try products for free for 30 days.",
              "title": "Try for free"
            }
          }
        },
        "subtitles": {
          "courses": "Courses",
          "products": "Products"
        }
      },
      "productsOverview": {
        "filter": {
          "all": "All",
          "courses": "Courses",
          "products": "Products"
        },
        "order": {
          "date": "Last added",
          "title": "Alphabetical order"
        }
      },
      "profileSettingsAvatar": {
        "changeAvatar": "Choose avatar"
      },
      "profileSettingsName": {
        "missingFirstName": "You must provide a first name"
      },
      "shareLinkModal": {
        "copy": "Copy",
        "copyLink": "Copy link",
        "message": {
          "body": "{title}",
          "title": "{title}"
        },
        "shareTo": "Share to:",
        "title": "Share content"
      },
      "tbRender": {
        "assignmentLink": {
          "excludedAlertMessage": "The teacher has removed the exercise"
        },
        "attachment": {
          "download": "Download"
        },
        "image": {
          "fullscreenTooltip": "View in full screen"
        }
      },
      "userExternalLinkDialog": {
        "cancelButtonLabel": "Cancel",
        "continueButtonLabel": "Continue",
        "message": "You will be transferred to the following address. Do you want to continue?",
        "title": "You are leaving Natur & Kultur's platform"
      },
      "userModal": {
        "change": "Change",
        "choosePassword": "Choose password",
        "class": "Class",
        "courses": "Courses",
        "coursesTab": {
          "noCourses": "The user does not participate in any courses"
        },
        "enterPassword": "Enter password",
        "externalLicenses": "The user's licenses can not be modified as your school handles licenses externally.",
        "externalUsers": "The user can not be modified as your school handles user data externally.",
        "firstName": "First name",
        "general": "General",
        "lastName": "Last name",
        "licenses": "Licenses",
        "licensesTab": {
          "handleLicenses": "Handle licenses",
          "license": "License",
          "noLicenses": "The user does not have any licenses.",
          "product": "Product",
          "showLicenses": "Show licenser"
        },
        "noDigilarLogin": "The user's login details cannot be changed here as they are managed through an integration.",
        "password": "Password",
        "passwordResetFailed": "A link could not be sent. Please try again later.",
        "passwordResetSuccess": "A link was sent.",
        "passwordSetFailed": "The password could not be saved. Please try again later.",
        "passwordSetSuccess": "The password was saved.",
        "passwordWarning": "You must enter at least 8 characters",
        "resetPassword": "Forgotten password?",
        "resetPasswordButton": "Reset password",
        "resetPasswordMessage": "Sends a link that allows the user to enter a new password to the user's email address",
        "restore": "Restore",
        "save": "Save",
        "saveFailed": "The user could not be saved",
        "saveSuccess": "The user was saved",
        "showPassword": "Show password",
        "userCanNotSetPassword": "The user's password can not be changed.",
        "userSaved": "The user was saved",
        "viewCourses": "View courses",
        "viewLicenses": "View licenses",
        "viewPassword": "Reset password",
        "viewStudent": "Studentsettings",
        "viewUser": "View user"
      },
      "waitFor": {
        "errorAlertMessage": "The data could not be loaded.",
        "retryButtonLabel": "Retry"
      }
    },
    "compton": {
      "alert": {
        "dismissButtonLabel": "Dismiss"
      },
      "audio": {
        "airPlayLabel": "AirPlay",
        "errorMessage": "The audio could not be loaded.",
        "loadingLabel": "Loading …",
        "pauseLabel": "Pause",
        "playLabel": "Play",
        "settingsLabel": "Settings"
      },
      "await": {
        "errorMessage": "The data could not be loaded.",
        "loaderLabel": "Loading ...",
        "retryButtonLabel": "Retry"
      },
      "confirm": {
        "cancelLabel": "Cancel",
        "okLabel": "OK"
      },
      "copyrightList": {
        "item": {
          "detailLabel": "Ⓒ {copyright}",
          "typeLabel": "{type, select, audios {Audio} images {Image} videos {Video} interactiveImages {Interactive image} other {Material} }",
          "typeWithIndexLabel": "{type, select, audios {Audio} images {Image} videos {Video} interactiveImages {Interactive image} other {Material} } in chapter {index}"
        }
      },
      "copyrightPopup": {
        "headingLabel": "Copyright"
      },
      "copyrightPopupButton": {
        "label": "Copyright"
      },
      "datepicker": {
        "months": "January,February,March,April,May,June,July,August,September,October,November,December",
        "nextMonth": "Next month",
        "previousMonth": "Previous month",
        "weekdays": "Sunday,Monday,Tuesday,Wednesday,Thursday,Friday,Saturday",
        "weekdaysShort": "Sun,Mon,Tue,Wed,Thu,Fri,Sat"
      },
      "dialog": {
        "header": {
          "closeButtonLabel": "Close"
        }
      },
      "dismissibleTooltip": {
        "dismissButtonLabel": "Dismiss"
      },
      "humanSize": {
        "units": {
          "byte": "{size, plural, one {Byte} other {Bytes} }",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "tb": "TB"
        }
      },
      "image": {
        "errorMessage": "The image could not be loaded."
      },
      "imageViewer": {
        "overlay": {
          "closeButtonLabel": "Close",
          "nextButtonLabel": "Next image",
          "previousButtonLabel": "Previous image"
        }
      },
      "input": {
        "clearButtonLabel": "Clear"
      },
      "interactiveImage": {
        "close": "Close",
        "errorMessage": "The image could not be loaded.",
        "fullscreen": "Fullscreen",
        "legendsMenuButtonLabel": "Legends",
        "menu": {
          "copyrightHeadingLabel": "Copyright",
          "legendsHeadingLabel": "Color key"
        },
        "nextStepButtonLabel": "Next step",
        "previousStepButtonLabel": "Previous step",
        "showMarkerTip": "Click on the marker",
        "showStepTip": "Proceed to the next step",
        "showZoomTip": "Zoom the image",
        "stepProgressLabel": "Step {step} of {totalSteps}",
        "zoomInButtonLabel": "Zoom in",
        "zoomOutButtonLabel": "Zoom out"
      },
      "pagination": {
        "firstLabel": "First",
        "lastLabel": "Last",
        "nextLabel": "Next",
        "page": "Page",
        "prevLabel": "Previous",
        "totalLabel": "out of {count}"
      },
      "select": {
        "label": "{count} options selected"
      },
      "snackbar": {
        "snack": {
          "dismissButtonLabel": "Dismiss"
        }
      },
      "tag": {
        "addNewButtonLabel": "Add new",
        "emptyMessage": "Add tags",
        "removeButtonLabel": "Remove"
      },
      "upload": {
        "multipleLabel": "Upload {format, select, image {images} video {videos} audio {audios} subtitle {subtitles} other {files} } … ",
        "singleLabel": "Upload {format, select, image {image} video {video} audio {audio} subtitle {subtitle} other {file} } … "
      },
      "uploadProgressModal": {
        "abortButtonLabel": "Abort",
        "message": "{uploadedSize} of {totalSize}",
        "title": "Uploading ..."
      },
      "video": {
        "subtitleLabel": {
          "en": "English",
          "sv": "Swedish"
        }
      },
      "vrImage": {
        "button": "View 360",
        "loading": "Loading ..."
      }
    },
    "content": {
      "chooseTextVersion": "Choose text version",
      "edition": "Version",
      "editionTooltip": "Text version",
      "level": "Level",
      "levelTooltip": "Difficulty",
      "noContent": "No content",
      "typeTooltip": "Type of content"
    },
    "contentInfo": {
      "about": "Information",
      "author": "Authors",
      "by": "by",
      "content": "Text",
      "copyLink": "Copy link",
      "copyReference": "Copy reference",
      "copyToClipboard": "Copy to clipboard",
      "copyright": "Copyright",
      "description": "Description",
      "dialect": "Accent",
      "goTo": "Go to",
      "guidance": "Teacher guidance",
      "includedInProducts": "{num, plural, =0 {The material is not included in any products.} =1 {The material is included in the product:} other {The material is included in the products:}}",
      "isbn": "(ISBN) {id}",
      "language": "Language",
      "lastUpdated": "Last updated",
      "level": "Level",
      "numComponents": "Paragraphs:",
      "numSections": "Sections:",
      "numWords": "Words:",
      "overview": "Overview",
      "product": {
        "articleNumber": "Article number",
        "authors": "Authors",
        "firstReleased": "First edition",
        "title": "Title"
      },
      "productInformation": "Product information",
      "readingTime": "Estimated reading time:",
      "readingTimeMin": "{time} min",
      "sourceDigilar": "This content as source",
      "sourceReference": "Source reference",
      "sourceString": "{url} (available: {datetime})",
      "textVersion": "Text{num, select, 1 {} other {: {version}}}",
      "textVersions": "Text versions",
      "type": "Content type"
    },
    "continue": "Continue",
    "courses": {
      "activeUntil": "Active until {date}",
      "activities": {
        "showMore": "Show more"
      },
      "adaptCourse": {
        "changeImageButtonText": "Change image",
        "chooseImageButtonText": "Choose image",
        "chooseLanguage": "Choose language",
        "coverImage": "Cover image",
        "generalLabel": "General",
        "hideDiagnosis": "Lock diagnostic tests",
        "hideDiagnosisSubLabel": "Unlock a diagnos by sharing it through an assignment",
        "hideKey": "Hide key",
        "hideLabel": "Key and diagnoses in the course",
        "languageLabel": "Language"
      },
      "addBook": "Add",
      "addProduct": "Add",
      "allGroups": "All classes",
      "analyze": "Analyze",
      "archive": "Archive",
      "archiveConfirm": "This course will be archived and no longer visible to the course members. An archived course can be restored from the school's administation.",
      "archiveConfirmAction": "Archive course",
      "archiveCourse": "Archive course",
      "archiveInfo": "If the course is archived it will no longer be visible to the course members.",
      "audit": "Review",
      "auditComments": "View comments",
      "auditExercises": "View exercises",
      "auditPage": {
        "allStudents": "All the students in the course",
        "allStudentsInTodo": "All the students in the to-do",
        "attempts": "{num} attempts",
        "breadcrumb": {
          "comments": "Comments",
          "inbox": "New",
          "index": "Index",
          "log": "Students",
          "overview": "All contents",
          "todos": "To-do"
        },
        "commentedOn": "{userString} commented {dateString}",
        "comments": "Comments",
        "compare": "Compare",
        "diagnosisResultTypes": {
          "attempt": "Attempt {attempt}",
          "best": "The best attempt",
          "first": "The first attempt",
          "latest": "The latest attempt"
        },
        "inbox": "New",
        "inboxNote": "* Note that this view only shows submissions from our teacher-assessed assignments and not diagnoses, self-corrected exercises and self-study questions.",
        "index": {
          "comments": {
            "buttonText": "Review comments",
            "description": "Here you can see all comments on exercises and assignments in chronological order. You can choose to see comments from the entire group of students in the course or for each student individually.",
            "title": "Comments to exercises"
          },
          "inbox": {
            "buttonText": "Review new",
            "description": "Here you can see all new answers that have been submitted for review. The list is updated when new answers are submitted and when you review. You can choose to review the entire group of students in the course or each student individually.",
            "title": "Inbox for assignments"
          },
          "log": {
            "buttonText": "Review student",
            "description": "Here you can see what an individual student in the course has worked with. The student's activity and results are displayed in chronological order.",
            "title": "Follow an individual student"
          },
          "notification": "{num, plural, other {{num} new}}",
          "overview": {
            "buttonText": "Review all",
            "description": "Here you can see the students' answers and results on all exercises and assignments in the course. The answers are arranged based on the structure of the books. You can choose to review the entire group of students in the course or each student individually.",
            "title": "All exercises and assignments"
          },
          "todos": {
            "auditButton": "Review",
            "buttonText": "Review to-do",
            "description": "Here you can see the students' answers and results on all exercises and assignments in each to-do. You can choose to review the entire group of students or each student individually.",
            "disabledText": "There are no to-dos to review",
            "noAudits": "There are no exercises here.",
            "title": "Results in to-do"
          }
        },
        "loading": "Loading answers",
        "loadingDiagnosis": "Loading attempts",
        "log": "Students",
        "noAnswers": "No answers",
        "noAttempts": "No attempts",
        "noComments": "Could not find any comments.",
        "noDiagnosis": "The book does not contain any diagnostic tests.",
        "noStudentSelected": "No student is selected.",
        "noStudents": "There are no students in the course",
        "noTeacherAssignments": "The book does not contain any teacher assignments.",
        "numAnswered": "{count} out of {total}",
        "overview": "All",
        "selectStudent": "Select student",
        "you": "You"
      },
      "books": "Content",
      "booksPage": {
        "booksWarning": "Your course contains {numBooks} books. It can be difficult to get an overview of the material and your students' results with so many. The course will still work, but consider hiding some books inside the course (you can choose to view them again at any time). Another alternative is to divide the books into several courses, for example one course per subject.",
        "heading": "Content"
      },
      "booksWarning": "Your course contains {numBooks} books. It can be difficult to get an overview of the material and your students' results with so many. The course will still work, but consider hiding some books inside the course (you can choose to view them again at any time). Another alternative is to divide the books into several courses, for example one course per subject.",
      "calendar": "Calendar",
      "cancel": "Cancel",
      "changeNameButtonText": "Change name",
      "chooseBooks": "Choose products ({numBooks, plural, =0 {0 selected} =1 {1 selected} other {# selected}})",
      "chooseGroups": "Choose classes",
      "chooseStudents": "Choose students",
      "chooseStudentsWithNumSelected": "Choose students ({numSelected} selected)",
      "chooseTeachers": "Choose teachers",
      "chooseTeachersWithNumSelected": "Choose teachers ({numSelected, plural, =0 {0 selected} =1 {1 selected} other {# selected}})",
      "continueWhereIwas": "Continue",
      "courseAdministration": "Administer course",
      "courseBooks": "Course content",
      "courseCard": {
        "scheduledBadge": "Scheduled for {startDate}"
      },
      "courseName": "Course name",
      "courseNamePlaceholder": "Give your course a name",
      "courseNoUsers": "There are no students connected to this course. You can add students under Administer course.",
      "courseSettings": "Administer course",
      "courseUpdated": "Course settings updated",
      "cover": "Cover image",
      "coverImage": "Cover image",
      "createCourse": "Create course",
      "createMessage": "The course connects students and teachers with our digital products - and gives you easier handling of product licenses. Depending on which products you choose, you can get more options such as to create your own planning, communicate with students and follow their progress. You can make changes and additional settings under Administer course once the course has been created.",
      "dates": "Start and end date",
      "demoLicense": "Demo",
      "done": "Done",
      "eMail": "E-mail",
      "editingCourse": "Course administration",
      "endDate": "End date",
      "finished": "Done",
      "generatedCourseName": "{productTitle} with {userName}",
      "groupNoUsers": "The class has no students",
      "groupNumUsers": "{count, plural, =0 {No students} =1 {One student} other {# students}}",
      "groups": "Classes",
      "handleLicenses": "Handle licenses",
      "hasNotLoggedIn": "Has not logged in",
      "hideBook": "Hide the book for your students",
      "history": "History",
      "invalidImage": "Image could not be loaded. Either the file was not an image or the image was too large.",
      "keepAlive": "Keep the course after the end date",
      "latestVisited": "Last visited",
      "latestVisitedEmpty": "You haven't visited any content yet.",
      "licenseExhausted": {
        "action": "Go to nok.se",
        "message": "Your school has run out of licenses in <strong>{product}</strong>. There {num, plural, =1 {is <strong>one</strong> user} other {are <strong>{num}</strong> users}} in this course who do not have access to this product right now. To resolve this issue, you can purchase additional licenses on <em>nok.se</em>.",
        "target": "https://nok.se"
      },
      "licenseExpired": {
        "action": "Go to nok.se",
        "message": "The license in <strong>{product}</strong> has expired. You can purchase new licenses on <em>nok.se</em>.",
        "target": "https://nok.se"
      },
      "licenseNotRenewed": {
        "action": "Swap license",
        "message": "The current license in <strong>{product}</strong> has expired, but there is a new license available. Would you like to use the new license in this course?"
      },
      "licenseWarning": {
        "buttonAction": "{canAssignAll, select, true {Assign licenses} other {Handle licenses}}",
        "heading": "{productTitle, select, null {} other {{productTitle}: }}{canAssignAll, select, true {Some users are missing licenses} other {There are not enough licenses}}",
        "linkAction": "{canAssignAll, select, true {Handle licenses} other {Administer course}}",
        "message": "{canAssignAll, select, true {Assign he users licenses or use \"Handle licenses\" to see which users are missing licenses for the product.} other {Your school needs to buy more licenses. If you want to continue with the course without {productTitle} you can remove the product from the course.}}",
        "userMissingLicense": {
          "action": "Handle licenses",
          "heading": "{productTitle}:",
          "message": "You do not have a license for the product.",
          "studentMessage": "You do not have a license for the product. Inform your teacher.",
          "teacherMessage": "You do not have a license for the product."
        }
      },
      "loadingImage": "Loading image",
      "menu": {
        "pages": "Course pages",
        "start": "Course startpage",
        "students": "Students"
      },
      "missingBooks": "This course has no content. Go to Administer course and choose which books you want to work with in the course.",
      "missingLicenses": "You can not use the course since you're missing {count, plural, =1 {a license} other {licenses}} in:",
      "missingLicensesButtonLabel": "Missing licenses",
      "missingLicensesDate": "The license expired {date}",
      "missingLicensesMessage": "To continue you need {count, plural, =1 {a license} other {licenses}} in:",
      "myResults": "My results",
      "name": "Name",
      "nameError": "Enter a name for the course",
      "noAddedBooks": "You have not selected any products",
      "noBooks": "Could not find any products",
      "noCourses": "Get started - create courses and assign product licenses!",
      "noLicenseWarningsAlert": "Your course has more than 80 users. No warnings will be shown when users are missing licenses. Click “Handle licenses”  to see if everyone has a license.",
      "noProducts": "Could not find any books",
      "noSelectedStudents": "You have not selected any students",
      "noSelectedTeachers": "You have not selected any teachers. We recommend to have at least one teacher in a course.",
      "noStudents": "Could not find any students",
      "noStudentsMessage": {
        "external": {
          "heading": "No students have logged in yet",
          "info": "Your school has an integration with {provider}. The students will be shown here once they have logged in through the integration."
        },
        "internal": {
          "button": "Add students",
          "heading": "Your school has not added any students",
          "info": "To use courses with your students you must first add the students to your school. This can easily be done in the administrative pages."
        }
      },
      "noTeachers": "Could not find any teachers",
      "noUsersMessage": "No {roleName, select, students {students} other {teachers} } found.",
      "numStudents": "{count, plural, one {One student} other {{count} students }}{hasGroups, select, true { in {countGroups, plural, one {one group} other {{countGroups} groups}}} other {}}",
      "numTeachers": "{count} teachers",
      "overview": "Overview",
      "overviewPage": {
        "activities": "Activities",
        "addActivity": "Create",
        "addPost": "Post",
        "addTodo": "To-do",
        "books": "Content",
        "dontShowMessageAgainButton": "Do not show this message again",
        "feed": "Overview",
        "hideBooksForStudentsButton": "Hide books on the overview",
        "noActivities": "There are no acitivities in the flow",
        "readMoreAboutCoursesButton": "Read more about how to use courses",
        "showBooksForStudentsButton": "Show books on the overview",
        "welcomeContent": "<p class=\"paragraph\">This is your overview that you share with your students. Here you can build your own planning and distribute exactly the content that you choose from the books. In the course menu you will find your tools:</p><ul class=\"unordered-list\"><li>The course books in their entirety are available under books and here on the overview.</li><li>You can activate your students, distribute content intertwined with your own material and communicate through Posts and To-do.</li><li>You can see students' responses to assignments and comment them under Review.</li><li>Through Administer course you can make further settings for the course.</li></ul>",
        "welcomeHeading": "Information about your course: ",
        "welcomeProducts": "Content"
      },
      "personalLicense": "Personal",
      "personalLicenseUntil": "Personal license until {date}",
      "plan": "Plan",
      "postItem": {
        "addHeading": "New post",
        "draft": "Draft",
        "editHeading": "Edit post",
        "editPost": "Edit post",
        "form": {
          "addInternalLink": "Add internal link",
          "cancel": "Cancel",
          "confirmDelete": "Are you sure you want to remove this post?",
          "content": "Content",
          "linkPreviews": "Link previews",
          "missingReceivers": "{num, plural, =1 {A receiver for your post has} other {# receivers for your post have}} been removed.",
          "more": "More options",
          "options": "Options",
          "publish": "Publish",
          "remove": "Remove",
          "removeInternalLink": "Remove",
          "saveAsDraft": "Save",
          "scheduleFrom": "Show from",
          "scheduler": "Scheduler",
          "snackbarDeletedMessage": "Post successfully removed",
          "snackbarSaveDraftMessage": "The post has been saved. Because it is a draft, it does not appear on the overview. To edit the post, go to the Posts tab.",
          "snackbarSaveMessage": "The post has been saved.",
          "snackbarSaveScheduledMessage": "The post has been saved. Because it is scheduled, it will not appear on the overview. To edit the post, go to the Posts tab.",
          "switchToDraft": "Unpublish",
          "targetStudents": "Target selected students",
          "title": "Title",
          "update": "Update"
        },
        "missingEntities": "One or more links have been removed because the content they were linked to is no longer available.",
        "scheduled": "Scheduled"
      },
      "postPage": {
        "createPost": "Add post",
        "ctaContent": "With posts, you can quickly notify your students of current events, conduct discussions, and provide link tips.",
        "ctaHeading": "Reach out to your students",
        "heading": "Posts",
        "noPosts": "There are no posts in this course yet",
        "noPublishedPosts": "There are no published posts in this course yet"
      },
      "posts": "Posts",
      "remainingInfo": {
        "remaining": "{remaining} left",
        "unlimited": "Unlimited"
      },
      "removeBook": "Hide",
      "removeGroupConfirm": "Do you wish to remove the group from the course?",
      "removeProduct": "Remove",
      "removeProductConfirm": "Do you wish to remove the book from the course?",
      "removeStudentConfirm": "Do you wish to remove the student from the course?",
      "removeTeacherConfirm": "Do you wish to remove the teacher from the course?",
      "restore": "Restore",
      "restoreCourse": "Restore course",
      "restoreInfo": "The course is archived and therefore not visible to the course members.",
      "save": "Save",
      "schoolLicense": "School license",
      "schoolLicenseUntil": "School license until {date}",
      "search": "Search",
      "searchHint": {
        "add": "Add {roleName, select, students {students} other {teachers} }",
        "addToSchool": "Add {roleName, select, students {students} other {teachers} } to the school",
        "description": "If you do not find a {roleName, select, students {student} other {teacher} }, that you would like to add to your course, it is probably because the {roleName, select, students {student} other {teacher} } is not added to this school.",
        "descriptionExtra": "You can add {roleName, select, students {students} other {teachers} } in the school administration.",
        "externalProvider": "The {roleName, select, students {students} other {teachers} } are synced from <b>{provider}</b>. If you do not find a {roleName, select, students {student} other {teacher} } in this listing, it is probably because the {roleName, select, students {student} other {teacher} } is not synced yet.",
        "lookingFor": "Can you not find a specific {roleName, select, students {student} other {teacher} }?"
      },
      "searchProduct": "Search books",
      "searchStudentOrClass": "Student or class",
      "searchStudentOrGroup": "Student or group",
      "searchTeachers": "Search teachers",
      "selectedStudentsGroupName": "Selected students",
      "settings": "Settings",
      "settingsPage": {
        "heading": "Settings"
      },
      "shortcuts": {
        "addShortcut": "Add shortcut",
        "deleteShortcut": "Remove",
        "label": "Shortcuts",
        "noShortcuts": "No shortcuts added"
      },
      "showBook": "Show the book for your students",
      "showLicenses": "Show licenses",
      "showLog": "View the log",
      "startDate": "Start date",
      "studentSelectTab": {
        "classes": "Classes",
        "groups": "Groups",
        "noUserGroupsMatchingSearch": "No groups found.",
        "searchGroups": "Search groups",
        "searchGroupsPlaceholder": "Enter group name"
      },
      "students": "Students",
      "studentsWarning": "Your course has {numStudents} students. Therefore, it can be difficult to review everyone's results in the review function. The course will still work, but consider dividing the students into several courses, for example one course per teaching group.",
      "tagLine": {
        "andStudents": "{num, plural, =1 {1 student} other {# students}}",
        "showMore": "Show more"
      },
      "teachers": "Teachers",
      "toCoursePage": "Open course",
      "todo": "To-do",
      "todoForm": {
        "addDescription": "Add description",
        "addStep": "Add step",
        "cancel": "Cancel",
        "delete": "Delete",
        "description": "Description",
        "errors": {
          "noName": "Name is missing",
          "noSteps": "You have to create at least one step."
        },
        "heading": "Edit to-do",
        "hideSolutions": "Hide key",
        "legacyAlertMessage": "This is a to-do with an older type created with our previous to-do builder. You can not create new to-do of this type, but you can still edit your old to-do.",
        "more": "More options",
        "name": "Name",
        "namePlaceholder": "Enter name for to-do",
        "noSteps": "No steps added",
        "publish": "Publish",
        "removeDescription": "Hide description",
        "saveAsDraft": "Save",
        "schedule": "Schedule",
        "scheduleFrom": "Show from",
        "snackbarCreatedMessage": "A new to-do was created",
        "snackbarDeletedMessage": "To-do successfully deleted",
        "snackbarUpdatedMessage": "To-do successfully updated",
        "stepForm": {
          "cancel": "Cancel",
          "chooseBook": "Choose book",
          "delete": "Delete",
          "diagnosisNumTries": "Change the total number of tries",
          "errors": {
            "noSelected": "You must choose a content"
          },
          "name": "Name",
          "placeholder": "Name your step",
          "save": "Save"
        },
        "stepTable": {
          "name": "Name",
          "type": "Type"
        },
        "steps": "Steps",
        "switchToDraft": "Unpublish",
        "targetStudents": "Target selected students",
        "update": "Update"
      },
      "todoItem": {
        "addHeading": "New to-do",
        "draft": "Draft",
        "edit": "Edit to-do",
        "editHeading": "Edit to-do",
        "scheduled": "Scheduled",
        "start": "Open"
      },
      "todoPage": {
        "createTodo": "Add to-do",
        "ctaContent": "With to-do, you select content from the products and distribute it to your students..",
        "ctaHeading": "Activate your students",
        "heading": "Legacy to-do",
        "noTodos": "There are no to-do in this course yet."
      },
      "unsavedWarning": "Your changes will not be saved if you leave the page.",
      "uploadImage": "Upload image",
      "userMissingLicense": "Missing license",
      "userMissingLicenses": "Missing {count, plural, =1 {a license} other {licenses}}",
      "usersMissingLicenses": {
        "action": "Activate licenses",
        "message": "There {num, plural, =1 {is <strong>one</strong> user} other {are <strong>{num}</strong> users}} in this course who do not have access to <strong>{product}</strong>."
      },
      "usesLicensesCheck": {
        "heading": "Automatic licensing",
        "label": "Use automatic licensing",
        "message": "This setting allows all students and teachers who are added to this course to be automatically assigned the school's licenses for the selected books.",
        "warning": "The school's licenses will not be used. In order for students to access the course, licenses need to be assigned manually or purchased by the users themselves."
      },
      "youAreMissingLicenses": "You are missing {count, plural, =1 {a license} other {licenses}}"
    },
    "cover": "Cover",
    "customize": "Customize",
    "deletedUser": "Deleted user",
    "detroit": {
      "canvasPresentor": {
        "pickColor": "Pick color",
        "pickPresetColor": "Customized",
        "presetColors": "Color",
        "removeObject": "Remove object",
        "restartCanvas": "Reset board",
        "restartConfirm": "Do you wish to restart?",
        "saved": "Saved",
        "saving": "Saving"
      },
      "confirm": {
        "cancelLabel": "Cancel",
        "okLabel": "OK"
      }
    },
    "diagnosis": {
      "aborted": "Aborted",
      "attempt": {
        "attemptNumber": "Attempt {attempt}",
        "showMyAnswers": "Show my answers"
      },
      "attemptsLeft": "{count} attempts left of {total}",
      "audit": {
        "attemptCompleted": "Completed attempt",
        "done": "Done",
        "goBack": "Back"
      },
      "confirmTurnInMessage": "This ends the diagnosis and submits the result to your teacher.",
      "confirmTurnInTitle": "Do you want to end the diagnosis?",
      "continue": "Continue",
      "done": {
        "close": "Close diagnosis",
        "controll": "Check answers",
        "goodJob": "Good job!",
        "goodTry": "Good try!",
        "results": "Your result",
        "text": "Well done!",
        "textResult": "You had {correct} out of {total} possible"
      },
      "end": "End diagnosis",
      "exercises": "exercises",
      "latestResult": "Latest result",
      "minutes": "minutes",
      "noResult": "No result yet",
      "onTimeUp": "If the time runs out, the diagnosis will end.",
      "pointsRequired": "If you score less than {required}, you need to practice more.",
      "result": "{score} correct out of {scoreMax}",
      "savingResult": "Saving your result",
      "showResults": "Show attempts",
      "start": "Start",
      "startInfo": "Once the diagnosis has been started, it cannot be paused.",
      "teacherAttemptsInfo": "As a teacher, you can always start a diagnosis."
    },
    "entityBrowser": {
      "collapse": "Close",
      "expand": "Open",
      "import": {
        "cancel": "Cancel",
        "done": "Done",
        "multipleSelected": "{number} selected",
        "oneSelected": "1 selected."
      },
      "moreInfo": "Information"
    },
    "entityImport": {
      "title": "Select content"
    },
    "entityMeta": {
      "menu": {
        "aboutContent": "Information",
        "copyLink": "Copy link",
        "share": "Share"
      }
    },
    "error": {
      "content": "Something went wrong while trying to retrieve data from the server. Please try again in a moment.",
      "heading": "The content could not be retrieved",
      "toHomescreen": "Go to the home screen",
      "tryAgain": "Try again"
    },
    "exercise": {
      "answer": "Answer",
      "button": {
        "correct": "Answer",
        "done": "Done",
        "hideKeyTooltip": "Hide key",
        "hideSolutionTooltip": "Hide key",
        "keyTooltip": "Show key",
        "keyboardTooltip": "Show keyboard",
        "next": "Next",
        "resetTooltip": "Reset answer",
        "solutionTooltip": "Show key",
        "submit": "Submit"
      },
      "check": {
        "incompleteMessage": "You must mark that you are done."
      },
      "choice": {
        "hint": "<strong>Tip!</strong> You have not checked all the correct boxes.",
        "incompleteMessage": "Choose an answer.",
        "incompleteMessageMultiple": "Choose one or more answers."
      },
      "commentTooltip": "Comments",
      "comments": "Comments",
      "editableMatrix": {
        "incompleteMessage": "Answer is missing."
      },
      "editor": {
        "incompleteMessage": "Write an answer."
      },
      "fullscreenTooltip": "Fullscreen",
      "goBack": "Exercises",
      "goToExercise": "Go to exercise",
      "hideDescription": "Hide description",
      "imageDragEmpty": "No more images to drag.",
      "index": "Index",
      "instruction": "Instruction",
      "level": "Level {level}",
      "levelTooltip": "Difficulty",
      "media": {
        "access": "You have not given access to your microphone",
        "incomplete": "You have to record an audio to submit.",
        "noRecording": "No audio recorded",
        "processing": "Processing",
        "readMore": "Read more",
        "record": "Record audio",
        "recordingFinished": "Recording finished",
        "removeConfirm": "Are you sure? This will delete the audio you have recorded.",
        "removeSoundFile": "Retake",
        "stop": "Stop"
      },
      "missingContenteditor": "<p class=\"text-secondary\">Something went wrong!</p> The assignment could not be loaded.",
      "noAnswers": "No answers turned in for this exercise",
      "numberLine": {
        "hint": "<strong>Hint!</strong> You have not selected the correct answer"
      },
      "placeChoices": {
        "hint": "<strong>Tip!</strong> You have not checked all the correct boxes.",
        "incompleteMessage": "Choose an answer.",
        "incompleteMessageMultiple": "Choose one or more answers."
      },
      "placeImages": {
        "hint": "Hint!",
        "hintMessage": "There are images left that you have to place on a marker.",
        "imagesEmpty": "No more images to pick",
        "triedDrag": "Click on a marker to place the image."
      },
      "placeWords": {
        "hint": "Hint!",
        "hintMessage": "There are words left that you have to place on a marker.",
        "triedDrag": "Click on a marker to place the word.",
        "wordsEmpty": "No more words to pick"
      },
      "reply": {
        "hint": "Tips!"
      },
      "reset": {
        "confirm": "Do you really want to reset your answer?"
      },
      "showDescription": "Show description",
      "sort": {
        "hint": "<strong>Tip!</strong> There are options left that you need to drag to the correct place."
      },
      "status": {
        "audited": "Reviewed",
        "correct": "Correct",
        "editing": "Editing …",
        "incomplete": "Answer(s) missing",
        "notStarted": "Not started",
        "saving": "Saving …",
        "started": "Started",
        "teacherCorrect": "Completed",
        "teacherWrong": "Not completed",
        "turnedIn": "Turned in",
        "wrong": "Wrong"
      },
      "textGap": {
        "incompleteMessage": "Answer is missing."
      },
      "textGapDragEmpty": "No more words to drag.",
      "version": {
        "current": "Current version",
        "header": "Versions",
        "label": "Version {version}"
      }
    },
    "externalLinkModal": {
      "cancelButtonLabel": "Cancel",
      "continueButtonLabel": "Continue",
      "message": "All links are carefully selected and quality reviewed by our authors. They are continually checked, but we can not be held responsible for any changes or errors that occur over time on other websites. Please help us by <a class=\"ui-link ui-link--link--default\" href=\"{contactUrl}\" target=\"_blank\">reporting</a> any problems to us.",
      "title": "You are leaving Natur & Kultur's platform"
    },
    "filePicker": {
      "attach": "Attach"
    },
    "files": {
      "alerts": {
        "noFiles": "<b>You have no files yet.</b> Here you will find all the pictures, movies, audio files and documents that you have attached to various exercises in your books or posts in courses. You can also upload new files here. You can reuse files and attach them to multiple exercises in your books."
      },
      "breadcrumb": {
        "edit": "Edit",
        "files": "Files",
        "new": "New"
      },
      "confirmDelete": "The file will be removed.",
      "details": {
        "aboutFile": "About the file",
        "deleteFile": "Delete file",
        "download": "Download",
        "uploaded": "Uploaded:"
      },
      "filesForm": {
        "cancel": "Cancel",
        "delete": "Delete",
        "save": "Save",
        "titlePlaceholder": "Name on note …"
      },
      "filter": "Filter",
      "filterOnFiletype": "Filter on type",
      "filterOptions": {
        "all": "All"
      },
      "filterTypeOptions": {
        "all": "All",
        "audios": "Audio files",
        "documents": "Documents",
        "images": "Images",
        "videos": "Videos"
      },
      "heading": "Files",
      "importFromDevice": "Import from device",
      "newFile": "Upload new",
      "preview": "Preview",
      "recordVideo": "Spela in video",
      "save": "Save",
      "search": "Search",
      "searchOptions": "Search options",
      "searchPlaceholder": "Search files …",
      "sort": "Sort",
      "sortOptions": {
        "fileNameAZ": "Filename A-Z",
        "fileNameZA": "Filename Z-A",
        "latest": "Latest",
        "oldest": "Oldest"
      },
      "takePicture": "Ta foto",
      "titleLabel": "Title",
      "type": {
        "audio": "Audio",
        "document": "Document",
        "file": "File",
        "image": "Image",
        "video": "Video"
      }
    },
    "forbidden": {
      "content": "After a bit of digging, we found that your account does not have access to this page.",
      "cta": "Go to the home screen",
      "heading": "Access denied"
    },
    "gallery": {
      "fullscreen": "Enlarge image",
      "indexLabel": "Image",
      "indexOf": "of",
      "next": "Next",
      "prev": "Previous"
    },
    "globalHeader": {
      "content": "Content",
      "contentTitle": "Content startpage",
      "courseTitle": "Course startpage",
      "help": "Support",
      "leaveTodo": "Close to-do",
      "logoTitle": "Go to homescreen",
      "nav": {
        "ariaButtonLabel": "Open content menu",
        "button": "Menu",
        "fallbackTitle": "Content",
        "tooltip": "Content menu"
      },
      "productTitle": "Product startpage",
      "returnButton": "You followed a link inside the content. Press here to return.",
      "search": "Search",
      "todoTitle": "Todo startpage",
      "usersNav": {
        "ariaButtonLabel": "Open participants menu",
        "students": "Students",
        "teachers": "Teachers",
        "title": "Participants",
        "tooltip": "Participants menu"
      }
    },
    "globalMenu": {
      "account": "My account",
      "appearance": "Appearance",
      "myDigilar": "My account",
      "notifications": "Notifications",
      "profile": "Account",
      "settings": "Settings"
    },
    "globalSearch": {
      "charsRequiredInfo": "Type a minimum of three characters to search",
      "noHits": "No results.",
      "results": "{count, plural, =0 {No hits} =1 {One hit} other {# hits}}",
      "searchInput": "Enter your search terms",
      "searchSubmit": "Search",
      "searching": "Searching …"
    },
    "globalShareMeta": {
      "information": "Information"
    },
    "help": "Help",
    "helpers": {
      "licenseTime": {
        "days": "{value, plural, =0 {0 days} =1 {1 day} other {# days}}",
        "months": "{value, plural, =0 {0 months} =1 {1 month} other {# months}}",
        "years": "{value, plural, =0 {0 years} =1 {1 year} other {# years}}"
      }
    },
    "hide": "Hide",
    "homescreen": "NOKportalen",
    "index": "Index",
    "interactives": {
      "exercises": "exercises",
      "exercisesCount": "{count}"
    },
    "labels": {
      "archive": "Archive",
      "area": "Area",
      "assignment": "Assignment",
      "book": "Book",
      "chapter": "Chapter",
      "collection": "Collection",
      "content": "Content",
      "exercise": "Exercise",
      "folder": "Folder",
      "interactives": "Interactive",
      "section": "Section",
      "story": "Picture story",
      "study": "Self study",
      "survey": "Survey",
      "workflow": "Workflow"
    },
    "landingPage": {
      "search": {
        "noResults": "Your search on the homescreen had no results.",
        "placeholder": "Look for a course or product"
      }
    },
    "language-select": {
      "language": {
        "en": "English",
        "noSelectedLanguage": "User language setting",
        "sv": "Svenska"
      }
    },
    "languageCodes": {
      "Danska": "Danish",
      "da_DK": "Danish",
      "de_DE": "German",
      "en_GB": "English",
      "es_ES": "Spanish",
      "fr_FR": "French",
      "it_IT": "Italian",
      "nn_NO": "Norwegian",
      "sv_SE": "Swedish"
    },
    "link": {
      "cancel": "Cancel",
      "continue": "Continue",
      "default": "Link",
      "description": "All links are carefully selected and quality reviewed by our authors. They are continually checked, but we can not be held responsible for any changes or errors that occur over time on other websites. Please help us by <a class=\"ui-link ui-link--link--default\" href=\"{contactUrl}\" target=\"_blank\">reporting</a> any problems to us.",
      "title": "You are leaving Natur & Kultur's platform"
    },
    "listen": "Listen",
    "listenToo": "I want to listen too",
    "loading": "Loading …",
    "locationCopiedToClipboard": "The link was added to the clipboard",
    "locationFailedToCopyToClipboard": "The link could not be added to the clipboard",
    "login": {
      "browserTestLink": "Test your browser",
      "connect": {
        "errors": {
          "ECONNREFUSED": "Connection is missing.",
          "invalid_grant": "There is no user with the specified username and password.",
          "unknown_error": "Something went wrong with the linking. Please try again."
        },
        "heading": "Link account",
        "message": "You are logged in at {service, select, NoK {Natur & Kultur} other {{service}} }. Now log in on your account.",
        "submit": "Link accounts"
      },
      "createAccount": "Create an account",
      "createAccountButton": "Crate account",
      "createAccountText": "With an account you can activate and use our digital products. If you are a teacher you can also try our digital products for 30 days, free of charge.",
      "errors": {
        "ECONNREFUSED": "The service cannot be reached. Wait a while and try again.",
        "ECONNRESET": "The service cannot be reached. Wait a while and try again.",
        "ENOTFOUND": "The service cannot be reached. Wait a while and try again.",
        "facebook": "The selected Facebook account is not linked to any account on our platform.",
        "google": "The selected Google account is not linked to any account on our platform.",
        "invalid_grant": "There is no user with the specified username and password.",
        "lti_create_user_failed": "Error code LTI004. User could not be created.",
        "lti_no_email": "Felkod LTI006. Your learning platform did not send information about your e-mail address. Check that your e-mail address is correct and veryfied in your learning platform.",
        "lti_no_email_found": "Error code LTI003. No username found.",
        "lti_no_school_found": "Error code LTI002. No connected school found.",
        "lti_token_error": "Error code LTI001. Invalid account.",
        "lti_validate_error": "Error code LTI005.",
        "microsoft": "The selected Microsoft account is not linked to any account on our platform.",
        "no_password": "Password is missing",
        "no_username": "Username is missing",
        "nok": "The selected Natur & Kultur account is not linked to any account on our platform.",
        "skolfed_no_school": "Your school lacks a school unit code. Contact customer support and provide the error code: L001.",
        "skolon_accessed_refused": "Error code Skolon009. You denied access.",
        "skolon_could_not_create_user": "Error code Skolon007. Could not create an account.",
        "skolon_multiple_accounts": "Error code Skolon008. Invalid account.",
        "skolon_multiple_schools": "Error code Skolon004. Could not find your school.",
        "skolon_no_access_token": "Error code Skolon001. Could not log in with Skolon. Wait a while and try again.",
        "skolon_no_email": "Error code Skolon006. Could not log in with Skolon. Wait a while and try again.",
        "skolon_no_profile": "Error code Skolon005. Could not log in with Skolon. Wait a while and try again.",
        "skolon_no_school": "Error code Skolon002. Skolon did not pass a school.",
        "skolon_no_system_school": "Error code Skolon003. Could not find your school.",
        "user_login": "There is a problem with the login methods in your user account. Contact customer support and provide your username."
      },
      "forgotPassword": "Forgot your password?",
      "header": "Sign in",
      "login": "Sign in",
      "loginWith": "Or login with",
      "password": "Password",
      "passwordChanged": "Your password is changed. Log in with your new password.",
      "passwordPlaceholder": "Enter your password",
      "serviceOwner": "A service from Natur & kultur",
      "support": "Customer support",
      "testMessage": "This is the interface for digiNOK's test environment. The content here is a copy and there may be changes/things missing. If you want to see what is visible to the customers - go to our production environment:",
      "testMessageLink": "app.digilar.se",
      "username": "User name",
      "usernamePlaceholder": "name@domain.com",
      "withFacebook": "Login with Facebook",
      "withGoogle": "Login with Google",
      "withMicrosoft": "Login with Microsoft",
      "withNok": "Login with NOK-konto",
      "withSkolfederation": "Login with Skolfederation",
      "withSkolon": "Login with Skolon"
    },
    "mathHelp": {
      "content": "<p>SHow to write math using the keyboard: <br> <b>Multiplication:</b> Type the character *<br> <b>Division and fractions: </b> Type the character /<br> <b>Fractions in mixed form:</b> Enter a space after the integer 3 2/5<br> <b>Numbers and mathematical characters:</b> Write without spaces 3+5=8 <i>or</i> 4*3 <i>or</i> 6x</p>",
      "title": "Mathematical characters on the keyboard"
    },
    "mediaPlaceholder": {
      "showMedia": "Show media",
      "toolTip": "You have selected the customization Text only"
    },
    "minutesShort": "min",
    "modals": {
      "tooltips": {
        "close": "Close"
      }
    },
    "modelRejection": {
      "noInternetConnection": "The content is not available offline. Connect to the internet and try again.",
      "other": "The content could not be found. Try to reload the page."
    },
    "models": {
      "material": {
        "typeLabel": {
          "articles": "Article",
          "attachments": "Attachment",
          "audios": "Audio",
          "embeds": "Embed",
          "guidances": "Teacher's Manual",
          "images": "Image",
          "interactiveImages": "Interactive image",
          "links": "External Link",
          "videos": "Video",
          "vrImages": "360 Image",
          "words": "Word"
        }
      },
      "validations": {
        "presence": "The field cannot be empty."
      }
    },
    "months": "January,February,March,April,May,June,July,August,September,October,November,December",
    "myProfile": "My account",
    "nameForm": {
      "description": "Enter your name.",
      "firstName": "First name",
      "header": "Your name",
      "lastName": "Last name"
    },
    "nameMissing": "Name missing",
    "nextMonth": "Next Month",
    "notFound": {
      "activateLicenseContent": "The activation code you entered doesn't exist. Check the code that you entered. If the problem persists contact our support at kundsupport@nok.se",
      "activateLicenseHeading": "Activation code doesn't exist",
      "content": "The link you are trying to reach does not exist. This may be because the page was deleted or you followed a link that was incorrect.",
      "cta": "Go to the home screen",
      "gotoLogin": "Go to login page",
      "heading": "The page you are looking for does not exist"
    },
    "notes": {
      "alerts": {
        "noNotes": "<b>You have no notes yet.</b> Here you will find all the notes you have made in your books. You can also make new notes here. If you want, you can add the same note to several places in the books.",
        "noNotesInContent": "You have no notes connected to this content."
      },
      "breadcrumb": {
        "edit": "Edit",
        "new": "New",
        "notes": "Notes"
      },
      "categories": {
        "relatedNotes": "Related notes"
      },
      "filter": "Filter",
      "filterOptions": {
        "all": "All"
      },
      "heading": "Notes",
      "importFromArchive": "From my archive",
      "importModal": {
        "close": "Close",
        "import": "Select"
      },
      "newNote": "New note",
      "notesForm": {
        "cancel": "Cancel",
        "confirmDelete": "The note will be removed.",
        "contentLabel": "Content",
        "delete": "Delete",
        "heading": "Note",
        "removeFromWorkspace": "Unlink",
        "save": "Save",
        "titleLabel": "Title",
        "titlePlaceholder": "Name on note …"
      },
      "search": "Search",
      "searchPlaceholder": "Search notes …"
    },
    "notesForm": {
      "titleInputPlaceholder": "Name of note …"
    },
    "notice": {
      "later": "Show later",
      "noThanks": "Dismiss"
    },
    "onboarding": {
      "done": "Done",
      "next": "Next"
    },
    "overview": {
      "aboutProduct": "About the product",
      "activeSchoolApplication": {
        "cancelApplication": "I am not working on this school",
        "heading": "We are vetting your application to the school {schoolName}",
        "info": "Your application as a teacher at the school is being processed. Our customer service team is working on finalizing the connection. It can take a few days depending on at what time you made your application.",
        "infoTest": "Your account needs to be connected to your school before you can work with your students. Our customer service is working on connecting your account to the school. It may take a few days depending on when you registered. You can still try our products meanwhile.",
        "infoTestTeacher": "Our customer service is working on connecting your account to the school. It may take a few days."
      },
      "archiveCourse": "Archive course",
      "books": {
        "dontShow": "Dont show again",
        "private": {
          "heading": "You have no digital products",
          "info": "To use our digital products, you first need to have purchased a license for one of our products. You can visit <a target=\"_blank\" class=\"ui-link ui-link--default\" href=\"https://nok.se\">nok.se</a> to find all our products. When you have made a purchase of a digital product, they are displayed here on your start page. Extra materials can always be added for free."
        },
        "teacher": {
          "heading": "You have no digital products",
          "info": "Press the button to add extra material and any products your school has purchased. You can also try other products for free for 30 days. Once you have added a product to yourself, you can manage students' access to the product directly here on the home screen, or by creating a course.",
          "skolonHeading": "Your licenses are handled by Skolon",
          "skolonInfo": "All licenses for NOKportalen products are handled from Skolon. Contact your school administrator for assistance."
        }
      },
      "cancelSchoolApplication": "If you no longer wish to be connected to the school, click on the button below. Your account will still be active, but your testlicense will disappear since only teachers are allowed to have them.",
      "cancelSchoolApplicationConfirm": "I am not a teacher on this school",
      "cancelSchoolApplicationConfirmTitle": "Are you a teacher on this school?",
      "chooseBook": "Choose a book",
      "chooseCourse": "Choose a course",
      "confirmRemoveFromHomescreenTitle": "Hello",
      "confirmRemoveTrial": "You wont be able to test this product again, before one year from now",
      "course": {
        "info": "<b>You as a teacher need to create courses.</b> The course connects students and teachers with our digital products - and gives you easier handling of product licenses. Depending on which products you choose, you can get more options such as to create your own planning, communicate with students and follow their progress.",
        "infoButton": "Read our guide about courses"
      },
      "demo": "Trial",
      "demoLicenseExpired": "Trial license used",
      "demoLicenseExpiresToday": "Trial license: {hours} {hours, plural, =1 {hour} other {hours}} left",
      "demoLicenseShort": "Trial license: {days} {days, plural, =1 {day} other {days}} left",
      "expiredLicense": "The license expired {date}",
      "extramaterial": "Extra material",
      "hello": "Hello {name}!",
      "inactiveSchoolApplication": {
        "background": "You previously applied to be connected to a school in order to access common resources or test our digital products.",
        "created": "Application submitted",
        "heading": "We could not connect you to the school {schoolName}",
        "hide": "Hide message",
        "info": "We were unable to verify that your account belongs to the school you provided. Please contact customer support for assistance."
      },
      "license": {
        "buy": "Buy",
        "renew": "Renew"
      },
      "licenseWillExpire": "The license will expire in {days} days",
      "licenseWillExpireToday": "The license will expire today",
      "myCourses": "My courses",
      "noSchool": {
        "connectToSchool": "Connect",
        "heading": "Are you a student or a teacher?",
        "info": "Connect your account to a school in order to access it's products. Or continue using NOKportalen without one.",
        "noThanks": "Use without school",
        "yourUsername": "Your username: "
      },
      "onlyShowing": "Currently showing ",
      "openCourseSettings": "Administer course",
      "openLicenseModal": "{onlyShow, select, true {Show licenses} other {Handle licenses}}",
      "removeFromHomescreen": "Remove",
      "removeFromHomescreenSuccess": "The license can now be reused by someone else in your school",
      "schoolApplication": {
        "private": "You have to be a teacher and connected to a school to be able to test products for free.",
        "student": "Only teachers are able to test products for free."
      },
      "showAllProducts": "Show all products",
      "students": {
        "heading": "There are no students here",
        "info": "To get started with our products, you need to add the school's students through the administration. The school can also get help with this from our customer support. Press the help icon if you want to contact customer support.",
        "infoButton": "Read more",
        "mainButton": "Go to administration"
      },
      "subscription": {
        "cancel": "Cancel subscription",
        "cancelModal": {
          "cancelFailed": "An error occured while cancelling you subscription.",
          "cancelSuccess": "Your subscription has been cancelled.",
          "okButton": "Avsluta prenumeration",
          "text": "If you cancel your subscription, you will be able to use the product until {date}. You can renew the subscription later.",
          "title": "Do you want to cancel your subscription?"
        },
        "ended": "The subscription ended at {date}",
        "pendingPayment": "The subscription will en at {date} because of failed payment",
        "pendingPaymentDescription": "The subscription could not be renewed<br />and will end on {date}",
        "renew": "Renew subscription",
        "willBeRenewed": "The subscription will be renewed {date}"
      }
    },
    "pageTitle": {
      "activateOrder": "Add licenses - NOKPortalen",
      "admin": {
        "books": "Products",
        "courses": "Courses",
        "licenses": "Licenses",
        "students": "Students",
        "teachers": "Teachers"
      },
      "browserTest": "Browser test",
      "choosePassword": "Choose a password",
      "courses": {
        "audit": {
          "comments": "Comments",
          "inbox": "New",
          "index": "Review",
          "log": "Students",
          "overview": "All",
          "todos": "To-do"
        },
        "books": "Content",
        "myResults": "My results",
        "overview": "Overview",
        "posts": "Posts",
        "settings": "Administration",
        "todo": "To-do"
      },
      "createAccount": "Create account",
      "error": "Error",
      "forgotPassword": "Forgot your password?",
      "landingPage": "Welcome",
      "login": "Login",
      "newCourse": "Create course",
      "notFound": "Page not found",
      "onboarding": "Onboarding",
      "profile": {
        "overview": "Start",
        "results": "Log",
        "settings": "Settings",
        "workspace": "Archive"
      },
      "repetition": "Repetition"
    },
    "pagination": {
      "firstPage": "First page",
      "lastPage": "Last page",
      "nextPage": "Next page",
      "prevPage": "Previous page"
    },
    "preview": {
      "previewing": "Previewing"
    },
    "previousMonth": "Previous Month",
    "products": {
      "activate": "Activate",
      "addBooks": "Add products",
      "allStages": "All stages",
      "authors": "Authors:",
      "buyMoreLicenses": "Buy more licenses",
      "containsBooks": "Contains the books:",
      "demoLicenseUntil": "Demo license until {date}",
      "done": "Done",
      "downloadToExcel": "Download an excel-list for the schools licenses (excluding extramaterials)",
      "menu": null,
      "noAvailableLicenses": "There are no available licenses",
      "noResults": "No results",
      "personalLicenseUntil": "Personal license until {date}",
      "schoolBooks": "Your school's products",
      "schoolLicenseUntil": "License until {date}",
      "search": "Search",
      "searchPlaceholder": "Search …",
      "testBooks": "Try for free",
      "try": "Try"
    },
    "profile": {
      "avatar": "Profile picture",
      "changeLanguage": "Choose language",
      "connectToSchool": "Connect to a new school",
      "default": "Account",
      "email": "E-mail address",
      "emailPlaceholder": "name@domain.com",
      "errors": {
        "accountAlreadyConnected": "The account you are trying to connect to is already connected to another account.",
        "invalidEmail": "Invalid e-mail address"
      },
      "firstName": "First name",
      "lastName": "Last name",
      "passwordHint": "Your password must contain at least 8 characters.",
      "schools": "Schools",
      "settings": "Settings",
      "settingsPage": {
        "checkBrowser": "Check your browser",
        "header": "My settings",
        "integrityPolicy": "Privacy policy",
        "licenseAgreement": "License agreement",
        "logoutFromSessionsButton": "Log out of all other devices",
        "sso": {
          "connected": {
            "Facebook": "Linking succeeded. You can now choose to log in with your Facebook account.",
            "Google": "Linking succeeded. You can now choose to log in with your Google account.",
            "Microsoft": "Linking succeeded. You can now choose to log in with your Microsoft account."
          },
          "disconnect": "Remove",
          "disconnectAlertMessage": "You will not longer be able to log in with the service. Are you sure?",
          "heading": "Linked accounts for single sign-on",
          "information": "If you link any of these services, you can use its login details to log in. You choose the login service on our login page.",
          "link": {
            "facebook": "Link your Facebook account",
            "google": "Link your Google account",
            "microsoft": "Link your Microsoft account"
          }
        },
        "userTerms": "User agreement"
      },
      "update": "Update",
      "uploadImage": {
        "default": "Upload profile picture"
      },
      "username": "Username"
    },
    "profileMenu": {
      "nokse": "To nok.se",
      "overview": "Homescreen",
      "settings": "Settings",
      "workspace": "Archive",
      "workspaceMenu": {
        "assignments": "Teacher assignments",
        "files": "Files",
        "notes": "Notes",
        "wordlists": "Wordlists"
      }
    },
    "readInstead": "I want to read instead",
    "resetPassword": {
      "cancel": "Cancel",
      "description": "Enter your e-mail address and press ”Send”. A message with a reset link will then be sent to your e-mail address.",
      "done": {
        "header": "Reset link sent",
        "text": "A recovery link has been sent to your e-mail address. Click the link in the message to choose a new password. If the message does not appear within five minutes, it may have ended up in your spam folder."
      },
      "email": "E-mail",
      "emailPlaceholder": "name@domain.com",
      "header": "Forgot your password?",
      "mailNotFound": "There is no user with the specified username. Make sure you have entered a valid e-mail address (in the format name@domain.com).",
      "resetButton": "Send"
    },
    "results": {
      "entityFailed": "The answer cannot be displayed because you no longer have access to the exercise.",
      "entityMissing": "The answer cannot be displayed because the exercise is no longer available.",
      "fetch": "Fetching …",
      "fullscreen": "Fullscreen mode",
      "myResults": "My results",
      "numStudentsAnswered": "{num} answers",
      "types": {
        "diagnosis": "Diagnoses",
        "standard": "Exercises",
        "teacher_assignments": "Teacher assignments"
      }
    },
    "routes": {
      "activate": {
        "addLicenses": "Add licenses",
        "addLicensesButton": "Add {count, plural, =1 {license} other {licenses}}",
        "addPrivateOrderToPrivateUser": "The licenses will be added to your <strong>private</strong> account, {username}.",
        "addToPrivateUser": "Your account is <strong>private</strong> and you can thus not activate these licenses. To activate them, your account must be connected to the school/organisation that created the order.",
        "addToPrivateUserMultipleLicenses": "When using a private account, only one license per product will be added.",
        "addToSchool": "The licenses will be added to your school, {school}.",
        "addToUser": "The licenses will be added to your account, {username}.",
        "amount": "Amount",
        "chooseSchool": "Choose school to add the licenses to.",
        "customerReference": "Reference",
        "date": "Date",
        "deliveredTitle": {
          "added": "The licenses have been added",
          "schoolName": "The licenses have been added to {schoolName}!",
          "skolon": "The licenses have been added in Skolon!"
        },
        "error": {
          "message": "Your order could not be found. Please check the activation code and if it still can not be found, contact our support.",
          "title": "Order not found"
        },
        "isbn": "ISBN",
        "licenseTime": {
          "expired": "Expired",
          "heading": "License time",
          "months": "{months} {months, plural, =1 {month} other {months}}",
          "oldOrder": "Valid until {expires}"
        },
        "login": "Login",
        "messages": {
          "activated": {
            "added": {
              "code": "Activation code was used",
              "order": "Licenses have been added"
            },
            "at": "at {date}",
            "by": {
              "client": "through {client}",
              "unknown": "by someone else",
              "user": "by {username}"
            },
            "loginDescriptionNokportalen": "You need to be logged in to see additional details.",
            "loginNokportalenLink": "Go to the login page",
            "loginSkolon": "Your licenses are managed in Skolon.",
            "nokportalen": {
              "link": "Go to the startpage",
              "text": "You can find your products on your startpage in NOKportalen."
            },
            "onSchool": "by {schoolName}",
            "otherSchool": "The licenses belongs to a school you can not administrate.",
            "otherUser": "The licenses belongs to someone else.",
            "schoolAdminLicenses": "You can assign yourself, another teacher or students at the school a license in the school administration section.",
            "schoolAdminLink": "Go to the school administration to manage licenses"
          },
          "expired": {
            "message": "The {numberOfLicenses, plural, =1 {license} other {licenses}} can not be activated since {numberOfLicenses, plural, =1 {it was} other {they were}} acquired for more than two years ago.",
            "readMore": "Read more",
            "title": "Order is expired!"
          },
          "privateUser": {
            "message": "Your account must be connected to the school/organisation that ordered the licenses.",
            "readMore": "Read more here",
            "title": "Order is connected to a school"
          },
          "student": {
            "message": "You must be a teacher on your school to activate the order.",
            "title": "Only teachers can activate this order"
          },
          "unpublished": {
            "message": "This order contains products that are not yet published. Please try again after the publish date.",
            "title": "Not published products"
          }
        },
        "notLoggedInBusinessOrderWarning": "If you do not have an account you can forward the e-mail to a colleague that does.",
        "notLoggedInWarning": "You must be logged in to add licenses.",
        "orderInfo": "Order information",
        "orderIsOld": "Since the order was acquired for more than four months ago, the {numberOfLicenses, plural, =1 {license} other {licenses}} will be started directly and be available until the date above.",
        "orderIsOldReadMore": "Read more",
        "orderReference": "Order number",
        "processFailed": "Licenses could not be added.",
        "product": "Product",
        "readMoreLink": "Read more",
        "school": "School",
        "schoolIsConnectedToSkolon": "This school is connected to Skolon and the licenses will be sent there.",
        "sendLicensesToSkolonButton": "Send {count, plural, =1 {license} other {licenses}} to Skolon",
        "unpublishedProduct": "This product will be published the {date} {month}"
      },
      "addProducts": {
        "activateButtonLabel": "Assign",
        "activatedTextLabel": "Assigned",
        "activationCode": "Activation code",
        "addedLicense": "Product added",
        "buyMoreAlert": {
          "admin": {
            "heading": "Do you want to buy more licenses for the school?",
            "paragraph": "Do you want to buy more licenses for a product, or buy new products that your school does not yet use? Go to your retailer (Läromedia, GR or Skolon) or nok.se. You can always try a digital product 30 days for free on the startpage and using <em>Add products</em>. <a href=\"https://support.nok.se/article/4097\" class=\"text-underline\">Read more about how to try our products</a>"
          },
          "general": {
            "heading": "Do you want to buy more licenses for your school?",
            "paragraph": "Do you want to buy more licenses for a product, or buy new products that your school does not yet use? Go to your retailer (Läromedia, GR or Skolon) or nok.se. You can always try a teaching material 30 days for free by pressing the <em>Try books</em> tab. <a href=\"https://support.nok.se/article/4097\" class=\"text-underline\">Read more about how to try our products</a>"
          },
          "student": {
            "heading": "Are you missing product licenses?",
            "paragraph": "If you are missing a digital product it might be that your teacher needs to hand it out to you."
          }
        },
        "daysLeftOnLicenseTextLabel": "{daysLeft, plural, one {One day} other {# days} } left of your license",
        "daysLeftOnTextLabel": "The license expires in {days} days",
        "daysLeftOnTrialTextLabel": "Trial until {date}",
        "demoLicenseOnTextLabel": "The trial license expires in {days} days",
        "done": "Done",
        "expiredTextLabel": "The license expired {date}",
        "extendButtonLabel": "Extend",
        "extramaterialPlaceholder": "Free extra material",
        "extramaterialTextLabel": "Extra material",
        "extramaterialTooltip": "Free extra material",
        "freeTextLabel": "Try for free",
        "freeTooltip": "Try for free for 30 days",
        "freeTooltipUntil": "Try for free until {date}",
        "handledExternally": "Licenses are managed in Skolon",
        "handledInUnknownSystem": "another system",
        "navItems": {
          "products": "Products",
          "tryForFree": "Try for free"
        },
        "noLicenses": "No licenses",
        "noResultAlertMessage": "No books were found.",
        "numberOfLicensesText": "{numberOfUsesLeft, plural, one {1 license} other {# licenses} }",
        "numberOfLicensesTooltip": "Number of licenses to assign",
        "purchase": "Buy",
        "readMoreLinkLabel": "Read more about the product",
        "searchButtonLabel": "Search",
        "searchInputPlaceholder": "Search books",
        "showExtraMaterial": "Show extra material",
        "stageSelectPlaceholder": "All stages",
        "subjectSelectPlaceholder": "All subjects",
        "title": "Add products",
        "trialEndedText": "Trial ended",
        "tryButtonLabel": "Add",
        "unabledToCreateLicense": "Something went wrong when we tried to add the product. Please contact support.",
        "unlimitedPlaceholder": "Unlimited licenses to assign",
        "unlimitedTextLabel": "Unlimited",
        "unlimitedTooltip": "Unlimited licenses to assign",
        "usedTrialLicense": "You have already used your trial for this product and cannot create a new one.",
        "waitingForExtension": "Will be extended"
      },
      "admin": {
        "books": {
          "extraMaterials": "Extra material",
          "giveMeLicense": "Assign license to me",
          "licenseTime": "License time {licenseTime}",
          "licenseTimeUntil": "Licenses are valid until {licenseTime}",
          "manageLicenses": "Manage licenses",
          "noProducts": "No products found",
          "showLicenses": "Show licenses",
          "showingProductsFromOrder": "Displaying products from order ({date})"
        },
        "menu": {
          "productList": {
            "description": "Download an excel file containing the school licenses (including extramaterial)",
            "title": "Download a product list"
          },
          "removeUsers": {
            "description": "Warning! All the students and classes will be removed permanently from the school.",
            "title": "Delete all the school's students and classes"
          },
          "userList": {
            "description": "Download an excel file containing all the school's users, both teachers and students.",
            "title": "Download a user list"
          }
        },
        "search": {
          "books": "The product",
          "courses": "The course",
          "students": "Student or class",
          "teachers": "The teacher"
        }
      },
      "anslut": {
        "actions": {
          "cancel": "Cancel",
          "connect": "Apply"
        },
        "application": {
          "locationPlaceholder": "The location",
          "manualTitle": "Add the school manually",
          "schoolPlaceholder": "The school",
          "search": "Search for the school name, location or unit code",
          "searchPlaceholder": "Search...",
          "title": "Connect to school as a teacher",
          "validation": "Please enter information about your school"
        },
        "connectAs": {
          "applicationDescription": "Make an application to the school of your choice and have it reviewed manually by the Natur & Kultur support team. We will verify that you are a teacher at the school based on your email address.",
          "applicationDisabled": "Temporarily unavailable",
          "applicationEmailError": "We cannot verify that you are a teacher based on your email: {email}",
          "applicationTitle": "Teachers: send in an application",
          "codeDescription": "If you have been provided a school code you can use it to connect to the school. This is a feature that is enabled by teachers under the school administration.",
          "codeTitle": "Student or teacher: use a school code"
        },
        "connectUsing": "Choose how to connect:",
        "info": "<b>Tip:</b> You can ask already connected teachers to add invite <b>{email}</b> under the schools administration.",
        "title": "Connect to school"
      },
      "connectToSchool": {
        "actions": {
          "cancel": "Cancel",
          "connect": "Connect"
        },
        "alreadyConnected": "You are already connected to this school.",
        "cancel": "No, do not connect my account",
        "codeQuestion": "Does your school have a code?",
        "connect": "Yes, connect",
        "description": "If you have been provided a school code you can use it to connect to the school. This is a feature that is enabled by teachers under the school administration.",
        "enterCode": "Enter a code (6 letters or numbers)",
        "expiredCodeStudent": "The code is no longer active. Ask your teacher for a new code.",
        "expiredCodeTeacher": "The code is no longer active. Ask your colleague for a new code.",
        "instruction": "Ask a {role, select, teacher {colleague} other {teacher} } to add <b>{email}</b> to the school through the admin section or contact customer support for assistance.",
        "invalidCode": "The code you entered does not exist.",
        "noCode": "You need to enter a code.",
        "placeholder": "Example: E7J9HA",
        "school": {
          "isTeacher": "I am a teacher",
          "manualInput": "Please enter name of school, city and your phone number",
          "manualInputPlaceholder": "School name, city and your phone number",
          "message": "When we have verified that you are working on the given school, you will be able to use our products with your students.",
          "placeholder": "Search for name, city or school unit code",
          "validation": {
            "school": "Please enter information about your school"
          },
          "yourSchool": "Your school"
        },
        "schoolCode": "The code connects you as a <b>{role}</b> to school <b>{school}</b>",
        "schoolDescription": "Do you want to connect your account <b>{userName}</b> as <b>{role}</b> on <b>{schoolName}</b>?",
        "schoolTitle": "Connect to {schoolName}",
        "student": "student",
        "studentTeacherCode": "You're a student trying to use a code meant for students.",
        "teacher": "teacher",
        "teacherStudentCode": "You're a teacher trying to use a code meant for students.",
        "title": "Connect to school",
        "tooManyAttempts": "You've made too many attempts. You need to wait an hour.",
        "views": {
          "code": "Enter code",
          "school": "Select school"
        },
        "whatCode": "What is a code?"
      },
      "testProducts": {
        "close": "Close",
        "licenseAlreadyAdded": "A test license for this product is not available since you have already tested it.",
        "licenseCanNotBeAdded": "Test license is not available.",
        "licenseTime": {
          "endOfDay": "The test license is valid until the end of the day",
          "oneMonth": "The test license is valid for 30 days"
        },
        "licenseWillExpire": "The test licenses expires in {days} days",
        "licenseWillExpireToday": "The test license expires today",
        "title": "Test license",
        "userGotLicense": "You have been given a test license for:"
      }
    },
    "section": "Section",
    "services": {
      "licenseDispatcher": {
        "connect": {
          "snackbar": {
            "error": "The license could not be activated. Please try again or contact support if the problem persists.",
            "success": "The license was activated."
          }
        },
        "tryProduct": {
          "snackbar": {
            "error": "The trial could not be activated. Please try again or contact support if the problem persists.",
            "success": "The trial was activated."
          }
        }
      }
    },
    "sessions": {
      "browser": "Web browser",
      "operatingSystem": "Operating system",
      "started": "Started"
    },
    "settings": {
      "play": {
        "activate": "Activate reading",
        "alwaysSpeechSynthesis": "Always speech synthesis",
        "behavior": "Behaviour",
        "marking": "Text highlighting",
        "markings": {
          "none": "Off",
          "onlySentences": "Sentences only",
          "onlyWords": "Words only",
          "wordsAndSentences": "Words and sentences"
        },
        "preferActivated": "Always activated",
        "showSpeakerInfo": "Show instructions",
        "speechSpeed": {
          "fast": "Fast",
          "slow": "Slow",
          "standard": "Default",
          "verySlow": "Very slow"
        },
        "speechSpeedDisabled": "The setting does not work for recorded speech on your device, but you can still set the speed of speech synthesis.",
        "speed": "Reading speed",
        "stickWithText": "Autoscroll text",
        "voice": "Voice"
      },
      "reading": {
        "appearanceBehavior": "Appearance and behavior",
        "behavior": "Behaviour",
        "browse": "Browse",
        "defaultFont": "Default",
        "font": "Font",
        "fontsize": "Text size",
        "fontsizes": {
          "large": "Larger",
          "medium": "Default"
        },
        "lineHeight": "Line height",
        "lineHeights": {
          "large": "Larger",
          "medium": "Default"
        },
        "marking": "Markings in text",
        "markings": {
          "colored": "Completely colored",
          "discreet": "Discreet",
          "underlined": "Underlined"
        },
        "onlyImage": "Images only",
        "onlyText": "Text only",
        "readingRuler": "Reading ruler",
        "scroll": "Scroll",
        "tools": "Accessibility",
        "translationLink": "Translate"
      },
      "tab": {
        "presentation": "Appearance",
        "reading": "Listen"
      }
    },
    "shareLinkModal": {
      "title": "Share"
    },
    "showWholeText": "View full text",
    "signup": {
      "alreadyLoggedIn": "You are already logged in. If you are a teacher, you can try all our digital products by <a href=\"/\" target=\"_blank\">going to the Home screen</a> and clicking <em>Add products</em>.",
      "cancel": "Cancel",
      "description": "When you have filled out the form you will receive an e-mail with a link that you follow to create your account.",
      "done": {
        "header": "Account created",
        "nomail": "Did you not receive an activation link?",
        "text": "An activation link has been sent to your e-mail address. Click the link in the message to complete the process. If the message does not appear within five minutes, it may have ended up in your spam folder.",
        "tryagain": "Try again"
      },
      "email": "E-mail address",
      "emailPlaceholder": "Your e-mail address",
      "errors": {
        "accountTypeRequired": "No account type is selected.",
        "emailRequired": "No e-mail address is entered.",
        "invalidEmailAddress": "The e-mail address is invalid. Enter a correct e-mail address (in the format name@domain.com) and try again.",
        "invalidPhoneNumber": "The phone number is not valid.",
        "nameRequired": "No name is entered.",
        "phoneNumberRequired": "No phone number is entered.",
        "schoolUnitCodeRequired": "No school is selected.",
        "usernameExists": "The e-mail address is already registered. Cancel and click ”Forgot your password?” if you have forgotten your password."
      },
      "header": "Create an account",
      "municipality": "Municipality",
      "municipalityPlaceholder": "Select municipality",
      "name": "Name",
      "namePlaceholder": "Your name",
      "phonenumber": "Phone number (optional)",
      "phonenumberPlaceholder": "Your phone number",
      "private": {
        "accountType": "Private",
        "accountTypeDescription": "With a private account you can activate an use digital products for personal use.",
        "accountTypeInfo": "Your account can be connected to your school as teacher or student for you to get access to the schools resources."
      },
      "school": "School",
      "schoolPlaceholder": "Select school",
      "send": "Create account",
      "terms": "I have read and agree to the <a href=\"https://www.nok.se/juridisk-information/villkor/licensavtal/\" class=\"ui-link ui-link--link--default ui-link--medium\" target=\"_BLANK\">terms and conditions</a>.",
      "test": {
        "accountType": "Test",
        "accountTypeDescription": "With a test account you can try our digital products for free.",
        "accountTypeInfo": "Your account must be connected to your school for you to gain access to the schools digital products and teacher guidance."
      },
      "unknownError": "The account could not be created due to an temporary error. Please try again later.",
      "validationError": "The account could not be created. Correct your input below and try again."
    },
    "sourceCopiedToClipboard": "The source was added to the clipboard",
    "sourceFailedToCopyToClipboard": "The source could not be added to the clipboard",
    "spaces": {
      "content": "Content",
      "glossaries": "Wordlists",
      "workspace": "Assignments"
    },
    "speaker": {
      "noAutoplayAction": "Press here to try again",
      "noAutoplayContent": "For some reason, your browser did not want to start playback.",
      "noAutoplayHeading": "Playback could not be started"
    },
    "speakerInfo": {
      "click": "Press <b> Listen </b> above the text to turn the reading function on or off. Press a piece of text to start or pause the reading.",
      "clickHeading": "You can listen to all text",
      "confirm": "Okay, I understand",
      "customize": "You can customize the listening experience just as you want it, for example changing pace and voice. Press <b>Customize</b> at the top to select your settings. There you can also find this information again.",
      "customizeHeading": "Customize the listening experience",
      "heading": "Listen to content",
      "keyboard": "You can control playback with your keyboard. Use the following commands: <ul class=\"nt1\"><li><b>Shift and spacebar: Start and stop</b></li><li><b>Shift and arrow up: Previous paragraph</b></li><li><b>Shift and arrow down: Next paragraph</b></li></ul>",
      "keyboardHeading": "Control with the keyboard"
    },
    "speakerToggleButtonTooltip": "Reading is not available for the current language.",
    "start": "Start",
    "student": "Student",
    "tableOfContents": "Table of contents",
    "teacher": "Teacher",
    "teacherAssignment": {
      "breadcrumb": {
        "assignments": "Assignments",
        "edit": "Edit",
        "new": "New"
      },
      "confirmDelete": "The assignment will be removed.",
      "edit": {
        "answeringTemplate": "Answer template",
        "cancel": "Cancel",
        "delete": "Delete",
        "publish": "Publish",
        "question": "Assignment",
        "saveAsDraft": "Save",
        "switchToDraft": "Unpublish",
        "title": "Title",
        "titlePlaceholder": "Add title",
        "update": "Update",
        "visibleForStudents": "Visible for students"
      },
      "header": {
        "importFromArchive": "From my archive",
        "newAssignment": "New assignment"
      },
      "importModal": {
        "close": "Close",
        "import": "Select"
      },
      "overview": {
        "draft": "Draft",
        "filter": "Filter",
        "newAssignment": "New assignment",
        "noAssignments": "<b>You have no teacher assignments yet.</b> Here you will find all assignments that you as a teacher have created for different parts of your books. You can also create new teacher assignments here. If you want, you can reuse assignments and link them to multiple places in your books or in several courses for different student groups.",
        "noTitle": "(title missing)",
        "search": "Search",
        "searchPlaceholder": "Search …"
      }
    },
    "teacherGuide": {
      "text": "<h1 class=\\\"text-primary\\\">We will help you!</h1><p>If you need help, we are always close at hand. In our customer center you can search for answers to frequently asked questions. There are also guides that teach you how to get the most out of our digital products.</p><p>You open the customer center by pressing <i>Support</i> at the top of the menu. You will automatically receive suggestions for support articles that fit the page you are on. Teachers can also chat with customer support. When you are not logged in to the platform you will find the customer center at <a href=\\\"https://support.nok.se\\\" target=\\\"_blank\\\">support.nok.se</a>.</p> <p>If you have any questions - do not hesitate to contact us! <br/>Phone: <a href=\\\"tel:+4684538700\\\">+46 (0)8-453 87 00</a> <br/> E-mail: <a href=\\\"mailto:kundsupport@nok.se\\\">kundsupport@nok.se</p>",
      "title": "Get started"
    },
    "templates": {
      "area": "Area",
      "book": "Book",
      "chapter": "Chapter",
      "concepts": "Concept list",
      "content": "Content",
      "diagnosis": "Diagnosis",
      "diagnosis_part": "Diagnosis part",
      "flow": "Assignment flow",
      "folder": "Folder",
      "glossaries": "Glossary list",
      "grid": "Grid",
      "list": "List",
      "practice": "Exercises",
      "quiz": "Level training",
      "standard": "Exercises",
      "story": "Picture story",
      "workflow": "Workflow"
    },
    "terms": {
      "approve": "I accept the terms of use",
      "header": "Terms of use"
    },
    "todo": {
      "stepType": {
        "assignments": "Assignments",
        "collection": "Collection",
        "content": "Content",
        "diagnosis": "Diagnosis"
      }
    },
    "todoShare": {
      "modalTitle": "Share To-do",
      "shareTodo": "Share To-do"
    },
    "topMessage": {
      "close": "Close message"
    },
    "training": {
      "continue": "Continue",
      "heading": "Level training",
      "info": "Level training has multiple levels. To move on to the next level, you must answer correctly on {answers} exercises.",
      "restart": "Restart",
      "start": "Start",
      "stepDone": {
        "heading": "Congratulations! You have completed your training!"
      },
      "stepUp": {
        "heading": "Awesome! You finished level {level}",
        "info": "Level training has multiple levels. Move on to the next level."
      },
      "wellDone": "Well done!"
    },
    "types": {
      "archives": "Archive",
      "areas": "Area",
      "assignments": "Assignment",
      "books": "Book",
      "collections": "Collection",
      "contents": "Content",
      "exercises": "Exercise",
      "folders": "Folder",
      "glossaries": "Word list",
      "interactives": "Interactive",
      "sections": "Section",
      "studies": "Self study"
    },
    "uncategorized": "Uncategorized",
    "updateNotification": {
      "buttonLabel": "Update now",
      "later": "Later",
      "message": "A new update is available.",
      "title": "New update"
    },
    "upload": {
      "default": "Upload",
      "file": "Upload file",
      "image": "Upload image"
    },
    "uploadProfileImage": "Upload picture",
    "userSelect": {
      "cancel": "Cancel",
      "done": "Done",
      "noAddedUsers": "No students selected",
      "noUsersMessage": "No users",
      "searchHeading": "Search",
      "searchPlaceholder": "Search users",
      "selectUser": "Select users",
      "userEmailHeading": "E-mail",
      "userNameHeading": "Name"
    },
    "version": "VERSION {version}",
    "videoPlayer": {
      "advertisement": "Ad",
      "all": "All",
      "buffered": "Buffered",
      "captions": "Captions",
      "currentTime": "Current time",
      "disableCaptions": "Disable captions",
      "disabled": "Disabled",
      "duration": "Duration",
      "enableCaptions": "Enable captions",
      "enabled": "Enabled",
      "end": "End",
      "enterFullscreen": "Enter fullscreen",
      "exitFullscreen": "Exit fullscreen",
      "fastForward": "Forward {seektime}s",
      "frameTitle": "Player for {title}",
      "loop": "Loop",
      "menuBack": "Go back to previous menu",
      "mute": "Mute",
      "normal": "Normal",
      "pause": "Pause",
      "play": "Play",
      "played": "Played",
      "quality": "Quality",
      "qualityBadge": {
        "1080": "HD",
        "1440": "HD",
        "2160": "4K",
        "480": "SD",
        "576": "SD",
        "720": "HD"
      },
      "reset": "Reset",
      "restart": "Restart",
      "rewind": "Rewind {seektime}s",
      "seek": "Seek",
      "seekLabel": "{currentTime} of {duration}",
      "settings": "Settings",
      "speed": "Speed",
      "start": "Start",
      "unmute": "Unmute",
      "volume": "Volume"
    },
    "weekdays": "Sunday,Monday,Tuesday,Wednesday,Thursday,Friday,Saturday",
    "weekdaysShort": "Sun,Mon,Tue,Wed,Thu,Fri,Sat",
    "wordExplanation": {
      "addPlaceholder": "Add to …",
      "examples": "Examples",
      "explanation": "Explanation",
      "listName": "Words from {title}",
      "savedTo": "Saved to: {list}",
      "translation": "Translation",
      "wordNotSavedMessage": "The word could not be saved.",
      "wordSavedMessage": "The word has been saved in the word list."
    },
    "wordQuiz": {
      "answer": "Answer",
      "cancel": "Cancel",
      "explanation": "Explanation",
      "inputPlaceholder": "Write answer …",
      "next": "Next",
      "noWordsMessage": {
        "concepts": "<p>You can’t use this word list as practice since it doesn’t contain words with explanations. Add explanations by editing each word separately.</p><p>You can also add new words to the list or save highlighted words from our texts.</p>",
        "glossaries": "<p>You can’t use this word list as practice since it doesn’t contain words with translations. Add translations by editing each word separately.</p><p>You can also add new words to the list or save highlighted words from our texts.</p>"
      },
      "practiceOnWords": "Practice",
      "replaceWord": "(word)",
      "restart": "Restart",
      "summary": "You had {result} of {total} correct answers.",
      "translation": "Translation",
      "word": "Word",
      "wrongAnswer": "Oops! The correct answer is:"
    },
    "wordlists": {
      "addNewWord": "Add word",
      "addToMyWordlists": "Save a copy",
      "addedToMyWordlistsFailed": "The list could not be saved to the archive",
      "addedToMyWordlistsSuccess": "The list was saved to the archive",
      "alerts": {
        "noWordlists": "<b>You have no word lists yet.</b> Here you will find all the word lists you have created in your books. You can also create new word lists here. If you want, you can add the same word list to several places in your books.",
        "noWordlistsInContent": "You have no word lists connected to this content.",
        "noWordsinWordlist": "No words."
      },
      "breadcrumb": {
        "edit": "Edit",
        "new": "New",
        "wordlists": "Wordlists"
      },
      "cancel": "Cancel",
      "categories": {
        "relatedWordlists": "Related word lists"
      },
      "create": "Create",
      "draft": "Draft",
      "edit": "Edit",
      "editWord": "Edit word",
      "filter": "Filter",
      "filterOptions": {
        "all": "All"
      },
      "heading": "Wordlists",
      "importFromArchive": "From my archive",
      "importModal": {
        "close": "Close",
        "import": "Select"
      },
      "newWordHeading": "New word",
      "newWordlistButton": "New wordlist",
      "newWordlistHeading": "New wordlist",
      "remove": "Remove",
      "removeFromWorkspace": "Unlink",
      "save": "Save",
      "searchLabel": "Search",
      "searchPlaceholder": "Search …",
      "share": "Share",
      "startQuiz": "Practice",
      "wordForm": {
        "cancel": "Cancel",
        "confirmDelete": "The word will be removed.",
        "create": "Create",
        "delete": "Delete",
        "examplesLabel": "Examples",
        "examplesPlaceholder": "",
        "explanationLabel": "Explanation",
        "explanationPlaceholder": "",
        "save": "Save",
        "translationLabel": "Translation",
        "translationPlaceholder": "",
        "wordClassLabel": "Word class",
        "wordClassPlaceholder": "",
        "wordConstructionLabel": "Word construction",
        "wordConstructionPlaceholder": "",
        "wordError": "Enter a word.",
        "wordLabel": "Word",
        "wordPlaceholder": ""
      },
      "wordlistForm": {
        "cancel": "Cancel",
        "confirmDelete": "The word list will be removed.",
        "languages": {
          "english": "English",
          "french": "French",
          "german": "German",
          "italian": "Italian",
          "spanish": "Spanish",
          "swedish": "Swedish"
        },
        "publish": "Publish",
        "publishTooltip": "Share this word list with your students",
        "remove": "Delete",
        "save": "Save",
        "saveAsDraft": "Save",
        "switchToDraft": "Unpublish",
        "typeConcept": "Terms",
        "typeConceptExplanation": "Write explanations of words in the same language in which the words are written.",
        "typeGlossary": "Vocabulary",
        "typeGlossaryExplanation": "Translate words from another language.",
        "update": "Update",
        "visibleForStudents": "Visible to students",
        "wordlistLabel": "Title",
        "wordlistLanguageLabel": "Language",
        "wordlistLanguagePlaceholder": "Choose language …",
        "wordlistPlaceholder": "Type word list title",
        "wordlistTypeLabel": "Type of word list"
      }
    },
    "workspace": {
      "assignments": {
        "noAssignments": "<p><strong>There are no assignments here.</strong> The reason might be:</p><ul><li>No assignments have been created in this position.</li><li>A teacher has chosen to hide the assignments.</li></ul>{isTeacher, select, true {<p>As a teacher you can add your own assignments here.</p>} other {}}"
      },
      "error": {
        "assignments": {
          "buttonText": "Continue",
          "heading": "The exercise has been removed"
        },
        "notes": {
          "buttonText": "Continue",
          "heading": "The note has been removed"
        },
        "wordlists": {
          "buttonText": "Continue",
          "heading": "The wordlist has been removed"
        }
      },
      "menu": {
        "exercises": "Assignments",
        "notes": "Notes",
        "wordlists": "Wordlists"
      },
      "workspace": "Workspace"
    }
  }], ["sv", {
    "account": {
      "accountSettings": "Kontoinställningar",
      "administration": "Administration",
      "books": "Produkter",
      "changeSchool": "Byt skola",
      "classes": "Klasser",
      "connectToSchool": "Anslut till en ny skola",
      "groups": "Grupper",
      "licenses": "Licenser",
      "logout": "Logga ut",
      "school": "Min skola",
      "students": "Elever",
      "teachers": "Lärare"
    },
    "admin": {
      "CSVFileTooLarge": "Filen får inte innehålla fler än 1000 rader.",
      "activate": "Aktivera",
      "active": "Aktiv",
      "addBooks": "Välj en eller flera produkter",
      "addButton": "Bjud in {role}",
      "addClass": "Skapa ny klass",
      "addClassText": "Skapa klasser för att sedan kunna dela upp eleverna klassvis. En elev kan bara tillhöra en klass. Klasser förenklar när du ska skapa kurser och tilldela licenser.",
      "addRows": "Lägg till rader",
      "addStudents": "Lägg till elever",
      "addStudentsPasswordError": {
        "minLength": "Lösenordet är för kort",
        "minLengthSub": "Ange ett lösenord med minst 8 tecken",
        "required": "Lösenord saknas",
        "requiredSub": "Du måste ange ett lösenord för att kunna skapa konto"
      },
      "addStudentsPrint": {
        "addedRows": "{rows, plural, =1 {# konto har skapats och kan nu användas av din elev} other {# konton har skapats och kan nu användas av dina elever}}",
        "closeButton": "Stäng",
        "confirm": "Har du sparat elevernas lösenord? Vill du stänga fönstret?",
        "confirmButton": "Stäng fönstret",
        "printButton": "Skriv ut eller spara som PDF",
        "printText": "Eleverna kan nu logga in på konto.nok.se med följande användarnamn och lösenord.",
        "sub": "Eleverna har nu skapats, det är viktigt att du sparar elevernas lösenord. Vi föreslår att du skriver ut listan eller väljer att spara ned utskiften som en PDF",
        "title": "Eleverna har skapats"
      },
      "addStudentsRow": {
        "clearRow": "Rensa rad",
        "removeRow": "Ta bort rad"
      },
      "addStudentsUsernameError": {
        "exists": "Användarnamnet är upptaget",
        "existsSchool": "Användarnamnet är upptaget",
        "existsSchoolSub": "Det finns redan en användare på er skola med detta användarnamnet",
        "existsSub": "Användarnamnet finns redan på en annan skola. Hör av dig till supporten om du vet att denna användare behöver läggas till på er skola också.",
        "inputTwice": "Användarnamnet är angett flera gånger",
        "inputTwiceSub": "Du kan bara ange användarnamnet en gång",
        "minLength": "Användarnamnet är för kort",
        "minLengthSub": "Ange ett användarnamn med minst 6 tecken",
        "required": "Användarnamn saknas",
        "requiredSub": "Du måste ange ett användarnamn för att kunna skapa konto"
      },
      "addTeachers": "Lägg till lärare",
      "addUsers": {
        "multipleUsersFailed": "{count} användare kunde inte läggas till",
        "multipleUsersSucceeded": "{count} användare lades till",
        "userFailed": "Användaren {user} kunde inte läggas till",
        "userSucceeded": "Användaren {user} lades till"
      },
      "addUsersFailed": "Användarna kunde inte läggas till",
      "addedClass": "Skapade klass {name}",
      "address": "Faktureringsadress",
      "allClasses": "Alla klasser",
      "amount": "Antal",
      "archiveConfirm": "Kursen kommer att arkiveras och flyttas till administration.",
      "archiveConfirmAction": "Arkivera kurs",
      "archiveCourse": "Arkivera",
      "archivedCourses": "Arkiverade kurser",
      "books": "Produkter",
      "bought": "Köpta",
      "buyBooks": "Köp fler produkter",
      "calendarYear": "Kalenderår",
      "cancel": "Avbryt",
      "checkingEmails": "Kontrollerar e-postadresserna",
      "class": "Klass",
      "classNameExists": "Klassnamnet du angav finns redan på skolan",
      "classNameLabel": "Klassnamn",
      "classNameMissing": "Elever utan klass",
      "classNamePlaceholder": "Skriv in klassens namn",
      "classNoUsers": "Klassen har inga elever",
      "classNumUsers": "{count, plural, =0 {Inga elever} =1 {En elev} other {# elever}}",
      "classTooltip": "Här kan du ange en klass för eleven.<br />Det kan även göras vid ett senare tillfälle.",
      "classes": "Klasser",
      "code": "Kod",
      "codeUsesText": "{uses} {role} har använt koden",
      "codeUsesTextAlternative": "{code} har använts av {uses}",
      "codeValidUntil": "Giltig till {date}",
      "confirmAdd": "Lägg till användare",
      "confirmImport": "Importera",
      "confirmOrder": "Bekräfta beställning",
      "continue": "Fortsätt",
      "cost": "Kostnad",
      "courseCreatedAt": "Skapad",
      "courses": "Kurser",
      "createAccounts": "Skapa konton",
      "createAccountsClass": "Klass",
      "createAccountsExplanation": "<p class=\"my1\">Fyll i tabellen med så många elevkonton du vill skapa. Varje elev behöver ett <b>användarnamn</b> och <b>lösenord</b>. Du kan också välja eller skapa en <b>klass</b> för eleverna. När du skapat kontona får du en lista att skriva ut eller spara.</p>",
      "createAccountsFirstName": "Förnamn",
      "createAccountsLastName": "Efternamn",
      "createAccountsPassWord": "Lösenord *",
      "createAccountsUserName": "Användarnamn *",
      "createClass": "Skapa klass",
      "createClassButton": "Lägg till",
      "createStudentAccountsWithUserNamesTitle": "Skapa elevkonton med användarnamn",
      "createStudentsWithUsernameContent": "För elever som saknar e-postadress.",
      "createStudentsWithUsernameTitle": "Med användarnamn",
      "csvImportFail": "CSV-importen misslyckades. Var vänlig försök igen.",
      "csvInstruction": "Dra rätt rubrik till rätt fält",
      "csvInstructionHeader": "Välj vad respektive fält motsvarar",
      "customerService": "Kundsupport",
      "demos": "Demo",
      "discount": "Rabatt",
      "eMail": "E-post",
      "emails": "E-postadresser",
      "emailsInfo": "Fyll i e-postadresser. Separera med komma, mellanslag eller ny rad.",
      "emptyClassName": "Du måste ange ett klassnamn",
      "enterAddresses": "Skriv eller klistra in en eller flera e-postadresser.",
      "enterMessage": "Ange eventuella adressändringar här",
      "enterPassword": "Ange lösenord",
      "expandLabel": "Nuvarande period: {start_date} till {end_date}",
      "expandLicense": "Köp fler licenser",
      "exportUsersToExcel": "Ladda ner en lista med alla elever och lärare (Excel)",
      "extendLabel": "Nästa period: {start_date} till {end_date}",
      "extendLicense": "Köp ny period",
      "externalLicensesMessage": "<p class=\"m0\">{provider, select, null {Din skolas licenshantering har avaktiverats. Kontakta vår <a class=\"text-color-default text-underline\" href=\"https://support.nok.se\" target=\"_blank\">kundsupport</a> angående köp av produkter.} other {Din skolas licenshantering har avaktiverats eftersom skolans licenser hanteras externt genom en integration med <strong>{provider}</strong>. För att köpa produkter ska du kontakta den person på skolan som ansvarar för integrationen.}}</p>",
      "externalUsersMessage": "<p class=\"m0\">{provider, select, null {Din skolas användarhantering har avaktiverats. Kontakta vår <a class=\"text-color-default text-underline\" href=\"https://support.nok.se\" target=\"_blank\">kundsupport</a> om du behöver göra ändringar i dina klasser eller hantera elever eller lärare.} other {Din skolas användarhantering har avaktiverats eftersom skolans användare administreras externt genom en integration med <strong>{provider}</strong>. Konton för lärare och elever skapas automatiskt när användarna loggar in genom integrationen. Om du behöver göra ändringar i dina klasser eller hantera konton för elever eller lärare ska du vända dig till den person på skolan som ansvarar för integrationen.}}</p>",
      "findingEmails": "Letar upp e-postadresser",
      "firstName": "Förnamn",
      "firstNameTooltip": "Lämnar du förnamn tomt får eleven<br />själv fylla i det vid första inloggningen.",
      "generate": "Generera",
      "generatePopup": {
        "addAllToSameClass": "Lägg alla i samma klass",
        "addToClassHelpText": "Du kan ändra klass för alla elever i<br />tabellen. Välj en klass som redan finns<br />på skolan eller skapa en helt ny klass.",
        "addToExistingClassLabel": "Befintlig klass",
        "addToNewClassLabel": "Ny klass",
        "changeClassForEveryone": "Ändra klass för alla",
        "enterEightChars": "Ange minst 8 tecken",
        "enterFiveChars": "Ange minst 5 tecken",
        "enterPassword": "Ange lösenord",
        "enterPasswordHelpText": "Alla användare kommer att få samma<br/>lösenord.",
        "enterPasswordPlaceholder": "Ange t.ex nyckelpiga",
        "generateNewPasswords": "Generera nya lösenord",
        "generatePassword": "Generera lösenord",
        "generatePasswordHelpText": "Om du väljer att föreslå lösenord ersätts alla<br />befintliga lösenord i tabellen<br />med nya lösenord.",
        "helpTextAddUsers": "<strong>Exempel på användarnamn från prefix</strong><p><span class=\"text-muted\">maskros1, maskros2, maskros3...</p>",
        "noClassLabel": "Utan klass",
        "numOfUsersLabel": "Antal konton att generera",
        "passwordExamples": "<p><strong>Exempel på lösenord<br /></strong>W3@OxA7£</p>",
        "passwordPlaceholder": "Minst 8 tecken",
        "prefixTooShort": "Prefixet är för kort, ange minst 5 tecken",
        "prefixUsed": "{prefix} används sedan tidigare",
        "templateForUserNamesLabel": "Prefix för användarnamn",
        "tooManyUsers": "Du kan maximalt skapa {number} användare åt gången",
        "useSameForAllLabel": "Använd samma för alla",
        "usernamePlaceholder": "Minst 6 tecken"
      },
      "handleLicenses": "Hantera licenser",
      "importCSVChooseFile": "Välj CSV-fil",
      "importCSVContent": "Ladda upp en CSV-fil för att lägga till flera elever till skolan.",
      "importCSVContentTeachers": "Ladda upp en CSV-fil för att lägga till flera lärare till skolan.",
      "importCSVDescriptionStudents": "<b>E-post</b> är det enda fältet som är obligatoriskt. Om <b>namn</b> saknas får eleven själv fylla i det första gången. Eleven får en aktiveringslänk, skickat till e-postadressen, som hen måste klicka på för att komma in första gången. <b>Klass</b> kan du välja eller byta i efterhand.",
      "importCSVDescriptionTeachers": "<b>E-post</b> är det enda fältet som är obligatoriskt. Om <b>namn</b> saknas får läraren själv fylla i det första gången. Läraren får en aktiveringslänk, skickat till e-postadressen, som hen måste klicka på för att komma in första gången.",
      "importCSVDownloadExample": "Ladda ned ett exempel",
      "importCSVModalTitle": "Importera {role} med e-post",
      "importCSVNoFile": "Välj en CSV-fil först",
      "importCSVNoMoreProperties": "Inga fler fält att dra",
      "importCSVTitle": "Importera {role} från CSV-fil",
      "importCSVValidating": "validerar",
      "importCSVValidatorAnd": "och",
      "importCSVValidatorCanNotBeImported": "och kan därför inte importeras här",
      "importCSVValidatorInvalidEmailContent": "E-posten är felaktig och kontot kan inte skapas.",
      "importCSVValidatorInvalidEmailDescription": "Kontrollera att e-posten är korrekt angiven i din CSV-fil och ladda upp på nytt.",
      "importCSVValidatorInvalidEmailTitle": "E-posten är felaktig",
      "importCSVValidatorInvalidEmailValidationMessage": "{count, plural, =1 {1 e-postadress är inte giltig} other {# e-postadresser är inte giltiga}}",
      "importCSVValidatorStudentContent": "Det finns redan ett elevkonto för denna e-post.",
      "importCSVValidatorStudentDescription": "Ta bort e-posten från CSV-filen och ladda upp på nytt.",
      "importCSVValidatorStudentTitle": "E-posten tillhör en elev",
      "importCSVValidatorStudentValidationMessage": "{count, plural, =1 {1 e-postadress tillhör en elev} other {# e-postadresser tillhör elever}}",
      "importCSVValidatorTeacherContent": "Det finns redan ett lärarkonto för denna e-post.",
      "importCSVValidatorTeacherDescription": "Ta bort e-posten från CSV-filen och ladda upp på nytt.",
      "importCSVValidatorTeacherTitle": "E-posten tillhör en lärare",
      "importCSVValidatorTeacherValidationMessage": "{count, plural, =1 {1 e-postadress tillhör en lärare} other {# e-postadresser tillhör lärare}}",
      "inactivate": "Inaktivera",
      "inactive": "Inaktiv",
      "inactiveUser": "Användaren kommer att tas bort {date} p.g.a. inaktivitet. Detta kommer inte att ske om användaren loggar in eller blir tilldelad en licens innan dess",
      "inactiveUserList": "Användaren kommer att tas bort p.g.a inaktivitet",
      "invalidCSV": "Filen kunde inte tolkas.",
      "invalidEmails": "{numUsers, plural, =1 {1 e-postadress är felaktig} other {# e-postadresser är felaktiga}}",
      "invalidEmailsSub": "{numUsers, plural, =1 {Kontrollera så att e-postadressen är korrekt} other {Kontrollera så att e-postadresserna är korrekt}}",
      "invite": "Bjud in {role}",
      "inviteDescription": "En aktiveringslänk skickas till {roleName, select, students {elevernas} other {lärarnas} } e-post och de fyller själva i namn och lösenord vid första inloggning.",
      "inviteRole": "Bjud in {role}",
      "inviteWithCodeContent": "Kopiera en länk eller kod och dela till dina {role, select, students {elever} other {lärarkollegor} } på valfritt sätt.",
      "inviteWithCodeTitle": "Med länk eller skolkod",
      "inviteWithEmailContent": "Fyll i {role, select, students {elevernas} other {lärarnas} } e-postadresser för att skicka en inbjudan.",
      "inviteWithEmailTitle": "Via e-post",
      "invoiceSentTo": "Fakturan skickas till {username}",
      "lastName": "Efternamn",
      "lastNameTooltip": "Lämnar du efternamn tomt får eleven<br />själv fylla i det vid första inloggningen.",
      "licenseForNumUsers": "För {count} användare",
      "licenseFrom": "Från: {date}",
      "licenseOverview": {
        "email": "E-postadress",
        "heading": "Licensöversikt",
        "name": "Namn",
        "noSearchResults": "<em>Hittade inga användare för <strong>{search}</strong></em>",
        "role": "Roll",
        "searchPlaceholder": "Sök användare",
        "student": "Elev",
        "teacher": "Lärare"
      },
      "licensePeriod": "Licensperiod",
      "licenseStatus": "Status",
      "licenseTimePeriod": "Period: {active_from} till {active_until}",
      "licenseUntil": "Till: {date}",
      "licenses": "Licenser",
      "message": "Meddelande",
      "moveStudentsToClass": "Flytta elever hit",
      "moveUsers": {
        "allGroups": "Alla klasser",
        "cancel": "Avbryt",
        "chooseStudents": "Välj elever att flytta till {class_name}",
        "chosen": "{num_users} valda elever",
        "eMail": "E-post",
        "groupNoUsers": "Klassen har inga elever",
        "groupNumUsers": "{count, plural, =0 {Inga elever} =1 {En elev} other {# elever}}",
        "groups": "Klasser",
        "move": "Flytta",
        "name": "Namn",
        "noStudents": "Inga elever",
        "numStudents": "{count, plural, one {En elev } other {{count} elever }}{hasGroups, select, true { i {countGroups, plural, one {en grupp} other {{countGroups} grupper}}} other {}}",
        "search": "Sök",
        "searchStudents": "Sök elever",
        "snack": "{num_users, plural, =1 {1 elev har flyttats till {class_name}} other {{num_users} elever har flyttats till {class_name}}}"
      },
      "mySchool": "Min skola",
      "name": "Namn",
      "neverLogin": "Aldrig",
      "newClass": "Ny klass",
      "noBooks": "Hittade inga produktlicenser",
      "noClassSearchResults": "Sökningen matchar inga klasser",
      "noCourses": "Inga kurser hittades",
      "noProducts": "Du har inte lagt till några produkter",
      "noSchoolLicenses": "Skolan har inga licenser.",
      "noStudents": "Hittade inga elever eller klasser",
      "noTeachers": "Hittade inga lärare",
      "numProducts": "{products} produkter",
      "numStudents": "{count, plural, one {En elev } other {{count} elever }}{hasGroups, select, true { i {countGroups, plural, one {en grupp} other {{countGroups} grupper}}} other {}}",
      "numTeachers": "{count} lärare",
      "openCourse": "Öppna kursen",
      "orderConfirmFailed": "Din beställning kunde inte levereras.",
      "orderConfirmSuccess": "Din beställning har levererats och en orderbekräftelse har skickats till din e-postadress.",
      "orderHistory": "Historik",
      "orderTime": "Datum",
      "orderedItems": "{count, plural, =1 {Du har lagt till en produkt i en beställning} other {Du har lagt till # produkter i en beställning}}",
      "orderer": "Beställare",
      "periodLabel": "Period: {start_date} till {end_date}",
      "personal": "Personliga",
      "productPrice": "{license, select, null {} other {{license} }}à {amount} SEK",
      "purchaseInfo": "<p>För att erhålla de priser och villkor som din kommun upphandlat, måste beställningar läggas hos den återförsäljare som kommunen har ramavtal med; <a href=\"https://www.laromedia.se/\" target=\"_blank\">LäroMedia</a> eller <a href=\"https://www.goteborgsregionen.se/laromedel\" target=\"_blank\">GR (Göteborgsregionen)</a>.</p><p>När du lägger din beställning här skickas fakturan från Natur & Kultur till din e-postadress.</p>",
      "reference": "Fakturareferens",
      "removeAllStudents": "Ta bort alla elever",
      "removeAllStudentsWarning": "Varning. Alla elever och klasser kommer att tas bort från skolan. Detta går inte att ångra.",
      "removeClass": "Ta bort klass",
      "removeClassConfirm": "Ta bort klassen och alla dess elever från skolan?",
      "removeCourseWarning": "Ta bort kursen?",
      "removeOrder": "Ta bort beställningen",
      "removeStudent": "Ta bort elev",
      "removeStudentsAndClasses": "Ta bort alla elever och klasser på skolan",
      "removeStudentsConfirmExplanation1": "Skriv",
      "removeStudentsConfirmExplanation2": "i fältet nedan för att bekräfta.",
      "removeStudentsConfirmText": "Ta bort alla elever på skolan",
      "removeUser": "Ta bort användaren",
      "removeUserWarning": "Användaren kommer tas bort från skolan",
      "removeUsers": "Ta bort alla användare",
      "removeUsersFailed": "{count, plural, =1 {Användaren kunde inte tas bort} other {Användarna kunde inte tas bort}}",
      "removeUsersSucceeded": "{count, plural, =1 {Användaren togs bort} other {Användarna togs bort}}",
      "renameClass": "Ändra namn",
      "renewCode": "Skapa ny kod",
      "resetPassword": "Återställ lösenord",
      "restoreCourse": "Återställ kurs",
      "saveClassName": "Spara",
      "saveNewClass": "Spara",
      "savePassword": "Spara lösenord",
      "schoolLicense": "Skollicens",
      "schoolYear": "Läsår",
      "search": "Sök",
      "searchBooks": "Sök produkter",
      "searchClass": "Sök klass",
      "searchCourses": "Sök kurser",
      "searchStudents": "Sök elever eller klass",
      "searchTeachers": "Sök lärare",
      "selectBooks": "Välj produkt",
      "selectClass": "Välj klass",
      "setClassForAll": "Ange klass för alla",
      "setPassword": "Välj lösenord",
      "shared": "Delade",
      "show": "Visa",
      "showArchivedCourses": "Visa arkiverade kurser",
      "showCodeHistory": "Visa historik",
      "showMore": "Visa fler",
      "students": "Elever",
      "studentsAlreadyPresent": "{numUsers, plural, =1 {1 e-postadress tillhör en elev} other {# e-postadresser tillhör elever}}",
      "studentsAlreadyPresentSub": "{numUsers, plural, =1 {Kan inte läggas till som lärare} other {Kan inte läggas till som lärare}}",
      "studentsCanBeAdded": "{numUsers, plural, =1 {1 elevkonto kan skapas} other {# elevkonton kan skapas}}",
      "studentsCanBeAddedSub": "E-posterna är korrekta och kan läggas till som elever på skolan",
      "sum": "{amount} SEK",
      "teachers": "Lärare",
      "teachersAlreadyPresent": "{numUsers, plural, =1 {1 e-postadress tillhör en lärare} other {# e-postadresser tillhör lärare}}",
      "teachersAlreadyPresentSub": "{numUsers, plural, =1 {Kan inte läggas till som elev} other {Kan inte läggas till som elever}}",
      "teachersCanBeAdded": "{numUsers, plural, =1 {1 lärarkonto kan skapas} other {# lärarkonton kan skapas}}",
      "teachersCanBeAddedSub": "E-posterna är korrekta och kan läggas till som lärare på skolan",
      "terms": "Köpvillkor",
      "timePeriod": "Tidsperiod",
      "totalSum": "Summa",
      "unlimited": "Obegränsad",
      "userLastLoginHeading": "Inloggad",
      "users": "Användare",
      "usersCanBeAddedSub": "{numUsers, plural, =1 {Användarnamnet är unikt och giltigt} other {Användarnamnen är unika och giltiga}}",
      "vatInfo": "Alla priser anges exklusive moms.",
      "viewOrder": "Granska beställningen",
      "viewUser": "Visa användaren",
      "viewingOrder": "Granskar beställning",
      "withoutClass": "Utan klass"
    },
    "answerComments": {
      "commentButton": "Kommentera",
      "commentPlaceholder": "Skriv en kommentar",
      "events": {
        "heading": "Historik",
        "rejectedUserCommented": "<em>Borttagen användare</em> skrev",
        "rejectedUserCorrect": "<em>Borttagen användare</em> fick rätt på version {version}",
        "rejectedUserStartedVersion": "<em>Borttagen användare</em> påbörjade en ny version",
        "rejectedUserSubmitted": "<em>Borttagen användare</em> lämnade in version {version}",
        "rejectedUserWrong": "<em>Borttagen användare</em> fick fel på version {version}",
        "someoneCommented": "{name} skrev",
        "someoneCorrect": "{name} fick rätt på version {version}",
        "someoneStartedVersion": "{name} påbörjade en ny version",
        "someoneSubmitted": "{name} lämnade in version {version}",
        "someoneWrong": "{name} fick fel på version {version}",
        "youCommented": "Du skrev",
        "youCorrect": "Du fick rätt på version {version}",
        "youNoted": "Du noterade",
        "youStartedVersion": "Du påbörjade en ny version",
        "youSubmitted": "Du lämnade in version {version}",
        "youWrong": "Du fick fel på version {version}"
      },
      "noComments": "Inga kommentarer",
      "noteButton": "Notera",
      "notePlaceholder": "Skriv en notering",
      "notesDisclaimer": "Det är endast du som kan se dina noteringar",
      "remove": "Ta bort",
      "tabs": {
        "comment": "Kommentera",
        "note": "Egen notering"
      }
    },
    "answerLog": {
      "commentFilter": {
        "all": "Visa alla",
        "commented": "Visa kommenterade"
      },
      "diagnosis": {
        "aborted": "Försöket avbröts",
        "active": "Försöket är aktivt",
        "attempt": "Försök {attempt}",
        "noResults": "Inga resultat"
      },
      "entityFilter": {
        "allOption": "Alla",
        "areaLabel": "Arbetsområde",
        "areaPlaceholder": "Välj innehåll först",
        "assignmentSearchLabel": "Sök",
        "assignmentSearchPlaceholder": "Sök uppgift",
        "bookLabel": "Innehåll",
        "contentLabel": "Innehåll",
        "contentPlaceholder": "Välj arbetsområde först"
      },
      "filterShowing": "Visar:",
      "filterString": "{numStatuses, plural, =0 {Inga statusar} =1 {{lastStatus}} =7 {Alla statusar} other {{otherStatuses} och {lastStatus}}} {commentStatus, select, commented {med kommentarer} other {}} i {selectedContent, select, null {allt material} other {{selectedContent}}} {assignmentSearch, select, null {} other {där uppgiftens titel innehåller {assignmentSearch}}}",
      "markAllAsRead": "Markera alla i urvalet som granskade",
      "standard": {
        "allAssignments": "Alla uppgifter",
        "autoCorrected": "Autorättade",
        "filters": {
          "audited": "Granskade",
          "correct": "Rätt svar",
          "inbox": "Inlämnade*",
          "started": "Påbörjade",
          "teacherCorrect": "Mål uppnått",
          "teacherWrong": "Mål ej uppnått",
          "turnedIn": "Inlämnade",
          "wrong": "Fel svar"
        },
        "hideFilters": "Dölj filter",
        "noResults": "Inga resultat",
        "showFilters": "Visa filter",
        "teacherCorrected": "Lärarrättade"
      },
      "teacherAssignment": {
        "noResults": "Inga resultat"
      },
      "unknownDate": "Okänt datum"
    },
    "archives": {
      "allCategories": "Alla kategorier",
      "category": "Kategori",
      "orderBy": "Sortering",
      "random": "Slumpa",
      "search": {
        "noResultsOn": "Din sökning gav inga resultat. Om du vill söka i allt innehåll kan du använda den allmänna sökfunktionen.",
        "placeholder": "Sök i titlarna på sidan …",
        "relatedSearchResults": "Relaterade sökresultat",
        "searchResults": "Sökresultat",
        "searching": "Söker …",
        "showingResults": "Visar {results} av {total} resultat."
      },
      "showAllInFolder": "Visa alla i {folder}",
      "sort": {
        "date_asc": "Senaste först",
        "date_desc": "Äldsta först",
        "inverse": "Omvänd",
        "name_asc": "Titel A-Ö",
        "name_desc": "Titel Ö-A",
        "random": "Slumpa",
        "standard": "Standard"
      }
    },
    "area": "Arbetsområde",
    "areas": {
      "search": {
        "label": "Sökresultat",
        "noResultsOn": "Din sökning gav inga resultat. Om du vill söka i allt innehåll kan du använda den allmänna sökfunktionen.",
        "placeholder": "Sök i titlarna på sidan …",
        "results": "Visar {hits} resultat.",
        "searching": "Söker …"
      },
      "view": {
        "repeat": "Repetera"
      }
    },
    "assignments": {
      "edit": "Redigera",
      "editor": {
        "solution": "Lösningsförslag"
      },
      "heading": "Uppgifter",
      "missingAnswer": {
        "info": "Svaret kan inte visas. Uppgiften har besvarats vid något tidigare tillfälle. Svaret finns tyvärr inte kvar men resultatet finns sparat. Om du vill kan du besvara uppgiften på nytt.",
        "startOver": "Gör uppgiften på nytt"
      },
      "removeFromWorkspace": "Koppla bort",
      "step": "Uppgift {number} av {total}"
    },
    "browserTest": {
      "cancel": "Tillbaka",
      "cookiesDisabled": "Cookies är inaktiverat",
      "cookiesEnabled": "Cookies är aktiverat",
      "description": "Om alla fält blir gröna kommer din webbläsare att fungera med plattformen.",
      "header": "Webbläsartest",
      "invalidBrowser": "Din webbläsare ({browser}) stöds inte.",
      "invalidBrowserVersion": "Din version av {browser} (version {currentVersion}) stöds inte. Den lägsta versionen som stöds är version {minimumVersion}."
    },
    "calendar": {
      "lastWeek": "Senaste veckan",
      "lastWeekAtTime": "I {dayName}s kl. {time}",
      "lastWeekAtTimeLower": "i {dayName}s kl. {time}",
      "today": "Idag",
      "todayAtTime": "Idag kl. {time}",
      "todayAtTimeLower": "idag kl. {time}",
      "yesterday": "Igår",
      "yesterdayAtTime": "Igår kl. {time}",
      "yesterdayAtTimeLower": "igår kl. {time}"
    },
    "category": "Kategori",
    "choosePassword": {
      "apiError": "Något gick fel",
      "changePassword": "Byt lösenord",
      "changePasswordButton": "Byt lösenord",
      "confirmPassword": "Skriv lösenordet en gång till",
      "confirmPasswordPlaceholder": "Skriv ditt nya lösenord igen",
      "currentPassword": "Nuvarande lösenord",
      "currentPasswordPlaceholder": "Skriv ditt nuvarande lösenord",
      "description": "Välj ett lösenord för ditt konto. Det måste vara minst 8 tecken.",
      "errors": {
        "noTokenPosted": "Lösenordet gick inte att byta. Testa att trycka på länken i mailet igen eller gå till ”Glömt lösenordet” igen.",
        "other": "Något gick fel. Gå tillbaka till inloggningen och prova igen genom att trycka på ”Glömt lösenordet?”.",
        "passwordToShort": "Lösenordet måste vara minst 8 tecken.",
        "passwordsNotMatch": "Lösenorden matchade inte. Prova igen.",
        "sessionInvalid": "Sessionen har löpt ut. Gå tillbaka till inloggningen och börja om genom att trycka på ”Glömt lösenordet?”.",
        "wrongPassword": "Felaktigt lösenord"
      },
      "header": "Välj lösenord",
      "password": "Skriv ett nytt lösenord",
      "passwordChanged": "Lösenordet är nu ändrat",
      "passwordPlaceholder": "Skriv ditt nya lösenord",
      "success": "Nu kan du logga in med ditt nya lösenord. Tryck på \"Logga in\" för att fortsätta.",
      "token_already_used": "Länken du följde är inte längre aktiv då ett konto redan har skapats.",
      "token_expired": "Länken du följde är inte längre aktiv.",
      "token_expired_button": "Hämta en ny länk",
      "token_expired_required": "En ny länk har skickats. Följ instruktionerna i e-postmeddelandet. Om du inte får något e-postmeddelande, kontrollera din skräppostmapp.",
      "token_expired_required_failed": "En ny länk kunde inte skickas. Försök igen senare."
    },
    "codeCopiedToClipboard": "Koden har kopierats till urklipp",
    "codeFailedToCopyToClipboard": "Koden kunde inte kopieras till urklipp",
    "collection": {
      "audioToolTip": "Tryck här för att lyssna",
      "backTooltip": "Tillbaka till uppgifter",
      "sidebarLabel": "Uppgifter"
    },
    "collectionAudit": {
      "assignments": "Uppgifter",
      "audited": "Granskad",
      "compare": "Jämför",
      "edit": "Ändra",
      "nextStudent": "Nästa elev",
      "numAnswers": "Antal svar",
      "studentListLabel": "Elever",
      "students": "Elever",
      "teacherCorrect": "Mål uppnått",
      "teacherWrong": "Mål ej uppnått",
      "version": "Version {version}",
      "versions": "Versioner"
    },
    "components": {
      "activationCodeModal": {
        "activateButtonLabel": "Aktivera",
        "cancelButtonLabel": "Avbryt",
        "infoText": "Har du en aktiveringskod? Fyll i den här för att få tillgång till produkten.",
        "inputErrorMessage": "Felaktig aktiveringskod. Kontrollera att du skrivit rätt.",
        "inputLabel": "Aktiveringskod",
        "inputPlaceholder": "Exempel: N-NFEX-EB64-8BDA",
        "title": "Aktivera produkt"
      },
      "admin": {
        "schoolCode": {
          "activate": "Aktivera",
          "codeCopied": "Koden är kopierad",
          "copy": "Kopiera länk",
          "copyUrlTitle": "Kopiera inbjudningslänk:",
          "done": "Klar",
          "linkCopied": "Länken är kopierad!",
          "menu": {
            "disableCodeTitle": "Inaktivera",
            "disableDescription": "Om du väljer att inaktivera funktionen kan ni inte längre bjuda in {role, select, student {elever} other {kollegor} } via skolkod eller länk.",
            "historyDescription": "Se vilka som har använt koder och hur ofta de har använts.",
            "historyTitle": "Se historik",
            "renewCodeDescription": "När du förnyar koden så skapas en ny länk och skolkod och den gamla blir ogiltig.",
            "renewCodeTitle": "Förnya"
          },
          "missingCode": "Inbjudningslänken och skolkoden är inte aktiv.",
          "options": "Hantera",
          "shareDescription": "Bjud in {role, select, student {elever} other {kollegor} } genom att skicka dem en länk eller kod. Med länken kommer de enkelt att kunna koppla sig till skolan - har de inget konto så kommer de smidigt kunna skapa ett. Se till att funktionen är aktiverad, eller aktivera den, för att komma igång.",
          "shareTitle": "Kopiera inbjudningslänk:",
          "showQr": "Visa QR-kod",
          "title": "Bjud in {role, select, student {elever} other {lärare} } med länk eller skolkod",
          "useCode": "Inbjudningslänk och skolkod:"
        }
      },
      "availableLicensesModal": {
        "ableToAssignDemoAndRecyclableLicenses": "Du kan fortfarande tilldela testlicenser och återanvändbara licenser till dina elever.",
        "ableToAssignDemoLicenses": "Du kan fortfarande tilldela testlicenser till dina elever.",
        "allUsers": "Alla användare ({num} st)",
        "assign": "Tilldela",
        "assignAllUsers": "Tilldela dessa en licens",
        "assignFreeLicense": "Tilldela testlicens",
        "assignLicense": "Tilldela ny licens",
        "assignStartedLicense": "Tilldela startad licens",
        "chooseLicense": "Välj licens du vill tilldela",
        "chooseLicenses": "Välj bland licenser:",
        "classes": "Klasser",
        "close": "Avbryt",
        "confirmRecycleLicenses": "Tilldela {total, plural, =1 {licens} other {licenser}}",
        "courses": "Kurser",
        "done": "Klar",
        "expired": "Gick ut {date}",
        "expires": "Går ut {date}",
        "extend": "Förläng",
        "extended": "Kommer förlängas",
        "externalExpired": "Extern licens gick ut {date}",
        "externalExpires": "Extern licens går ut {date}",
        "externalLicenses": "Externa licenser hanteras hos leverantören",
        "externalTotalLicenses": "<b>{number}</b> användare har licens",
        "freeExpires": "Testlicens går ut {date}",
        "freeExtraMaterial": "Gratis extramaterial",
        "freeTooltipHeading": "Prova gratis i 30 dagar",
        "freeTooltipMessage": "Med en testlicens kan ni prova produkten i 30 dagar. Som lärare kan man prova själv eller tillsammans med sina elever genom att tilldela dessa en licens.",
        "freeTooltipUntilHeading": "Prova gratis fram till {date}",
        "freeTooltipUntilMessage": "Med en testlicens kan ni prova produkten fram till {date}. Som lärare kan man prova själv eller tillsammans med sina elever genom att tilldela dessa en licens.",
        "freeUsed": "Testlicens förbrukad",
        "freeValidThru": "Giltig 1 mån",
        "freeValidUntil": "Giltig till {date}",
        "groups": "Grupper",
        "handledExternally": "Skolans licenser hanteras i Skolon",
        "handledInUnknownSystem": "annat system",
        "hideUserGroups": "Dölj grupper",
        "license": "Licens",
        "licenseInfoModal": {
          "buyLicenses": "Köp licenser",
          "buyLicensesInfo": "Vill du köpa fler licenser till {title}? Besök din återförsäljare eller <a href=\"https://www.nok.se/redirect/products/{isbn}\" target=\"_blank\" rel=\"noopener noreferrer\" class=\"text-color-default text-ui\">nok.se</a>.",
          "header": "Skolans licenser",
          "licenses": "Licenser",
          "licensesInfo": "Din skola äger <b>{total}</b> {total, plural, =1 {licens} other {licenser}}, <b>{assigned}</b> av dessa är tilldelade och <b>{unassigned}</b> är {unassigned, plural, =1 {ledig} other {lediga}}.",
          "licensesInfoNoLicenses": "Din skola äger <b>0</b> licenser.",
          "licensesInfoOnlyTeachers": "Licenserna kan endast tilldelas till lärare.",
          "readMoreLink": "Läs mer om licenser på vår supportsida",
          "recyclable": {
            "info1": "Av de <strong>{assignableLicenses}</strong> lediga licenserna är <strong>{unstarted}</strong> nya och <strong>{started}</strong> startade.",
            "info2": "Licenser som är äldre än 4 månader startas automatiskt.",
            "readMore": "Läs mer"
          },
          "testLicenses": "Testlicenser",
          "testLicensesInfo": "Med en testlicens kan ni prova produkten i 30 dagar. Man kan prova en produkt en gång per år. Prova gärna produkten tillsammans med dina elever genom att tilldela dem testlicenser.",
          "testLicensesInfoUntil": "Med en testlicens kan ni prova produkten fram till {until}. Man kan prova en produkt en gång per år. Prova gärna produkten tillsammans med dina elever genom att tilldela dem testlicenser.",
          "usedLicenses": "Din skola använder nu <b>{demo}</b> {demo, plural, =1 {testlicens} other {testlicenser}}"
        },
        "licenseTime": "Licens är giltiga i {licenseTime} från start.",
        "licenseTimeTestAgreement": "Det finns ett obegränsat antal testlicenser som gäller till {agreementEndDate}.",
        "missingLicense": "Saknar licens",
        "mustBeStartedBefore": "Måste startas innan {date}",
        "nameAndNum": "{name} ({num} st)",
        "noClasses": "Inga klasser",
        "noCourses": "Inga kurser",
        "noGroups": "Inga grupper",
        "noLicenses": "Licenser saknas",
        "noUsers": "Hittade inga användare",
        "notEnoughLicenses": "Din skola har inte tillräckligt med licenser för att tilldela alla i kursen en licens.",
        "notForStudents": "Ej för elever",
        "numberOfRecyclable": "{number_of_licenses} st",
        "numberOfUsersWithoutLicenses": "användare i",
        "ok": "Ok",
        "personalExpired": "Personlig licens gick ut {date}",
        "personalExpires": "Personlig licens går ut {date}",
        "personalValidThru": "Personlig licens giltig till {date}",
        "ready": "Klar att starta",
        "recyclableActiveUntil": "Giltig till {date}",
        "recycle": "Gör återanvändbar",
        "recycleLicense": "Återanvänd startad licens",
        "recycleLicenses": "Återanvänd startad licens ({number})",
        "recycleLicensesNotComplete": "Du kommer tilldela <b>{available_licenses} st</b> licenser giltiga till {date}. Du behöver ytterligare <b>{remaining_licenses} st</b>.",
        "recycleLicensesToGroup": "Du kommer tilldela <b>{number_of_licenses} st</b> licenser giltiga till {date}. Alla kommer få en licens.",
        "recycleLicensesToSingleUser": "Du kommer tilldela en licens, giltig till {date}.",
        "recycleLicensesWithRemainder": "Du kommer tilldela <b>{available_licenses} st</b> licenser giltiga till {date}. Du behöver ytterligare <b>{remaining_licenses} st</b>. Efter att du har tilldelat dessa licenser kan du välja på nytt, tills alla har fått en licens.",
        "remove": "Dra tillbaka",
        "removeExtension": "Ångra förlängning",
        "removeLicense": "Dra tillbaka licens",
        "removeLicensesInfo1": "behåller sin startade licens men blir av med den andra licensen som skulle användas till att förlänga.",
        "removeLicensesInfo2": "Licensen är inte startad och kommer att kunna tilldelas på nytt.",
        "removeLicensesInfo3": "Licensen är startad men kan återanvändas av en annan användare på skolan giltighetstiden ut.",
        "removeLicensesInfo4": "Återanvändbar fram till:",
        "searchUsers": "Sök användare",
        "shortMore": "m. fl.",
        "showUserGroups": "Visa grupper",
        "started": "Startad",
        "student": "Elev",
        "teacher": "Lärare",
        "totalAssignedLicenses": "<b>{number}</b> {number, plural, =1 {tilldelad licens} other {tilldelade licenser}}",
        "totalFreeLicenses": "<b>{number}</b> {number, plural, =1 {licens tilldelad} other {licenser tilldelade}}",
        "totalLicenses": "<b>{unassigned} av {total}</b> {unassigned, plural, =1 {licens} other {licenser}} kan tilldelas",
        "unlimitedLicenses": "Det finns obegränsat antal licenser. Licenserna är giltiga till {licenseTime}.",
        "usedDemoLicenses": "{numberOfUsedDemoLicenses} användare har förbrukat sin testlicens i {title} och kommer inte att få en ny testlicens.",
        "userWillLoseLicense": "kommer bli av med sin licens.",
        "validFromStart": "Giltig {licenseTime} från start",
        "validFromStartUntil": "Giltig till {licenseTime}",
        "validThru": "Giltig till {date}",
        "withoutLicenses": "saknar licens."
      },
      "avatar-picker": {
        "avatar": "Avatar",
        "chooseAvatar": "Välj avatar",
        "search": "Sök bland emojis"
      },
      "contextSelect": {
        "selectCourseContext": {
          "heading": "Välj kurs",
          "info": "Det här innehållet ingår i flera kurser som du är med i. Välj en kurs för att fortsätta."
        }
      },
      "coursePostList": {
        "stateNavItems": {
          "all": "Alla",
          "published": "Publicerade"
        }
      },
      "courseProductCard": {
        "courseAdministration": "Administrera kurs",
        "new": "Ny"
      },
      "customContentDetailModal": {
        "cancelButtonLabel": "Avbryt",
        "numberOfTriesInputLabel": "Antal försök",
        "numberOfTriesInputPlaceholder": "Obegränsat",
        "saveButtonLabel": "Spara",
        "teacherMaterial": "Lärarmaterial",
        "title": "Anpassa: {entityTitle}"
      },
      "doneModal": {
        "doneButtonLabel": "Klar"
      },
      "fileChooseModal": {
        "additionCollatorEmptyMessage": "<b>Du har inga filer än.</b> Här kommer du hitta alla bilder, filmer, ljudfiler och dokument som du har bifogat till olika uppgifter i dina produkter. Du kan också ladda upp nya filer här. Du kan återanvända filer och bifoga dem till flera uppgifter i dina produkter.",
        "additionCollatorNoResultMessage": "Inga filer hittades.",
        "title": "Filer"
      },
      "fileUpload": {
        "performButtonLabel": "Ladda upp fil ..."
      },
      "fileWarning": {
        "missingFiles": "{num, plural, one {En bifogad fil} other {# bifogade filer}} kunde inte hittas."
      },
      "footnoteList": {
        "headingLabel": "Fotnoter"
      },
      "imageCardCollection": {
        "course": "Kurs",
        "students": "{students} {students, plural, =1 {elev} other {elever}}",
        "teachers": "{teachers} lärare"
      },
      "ltiInfoLink": {
        "infoText": "För att använda produkten i en kurs i {provider}, kopiera länken nedan och följ instruktionerna.",
        "infoTextReadMore": "Läs mer",
        "title": "Lägg till i {provider}"
      },
      "noticeManager": {
        "maintenanceMessage": {
          "buttonText": "Okej, tack"
        }
      },
      "postItem": {
        "targetedToSelectedBadgeLabel": "Riktat till utvalda"
      },
      "productCollator": {
        "noResultAlertMessage": "Inga produkter hittades.",
        "searchButtonLabel": "Sök",
        "searchInputPlaceholder": "Sök produkt eller extramaterial",
        "showExtraMaterial": "Visa extramaterial",
        "stageSelectPlaceholder": "Alla åldrar",
        "subjectSelectPlaceholder": "Alla kategorier"
      },
      "productPicker": {
        "item": {
          "extramaterialTextLabel": "Extramaterial",
          "extramaterialTooltip": "Gratis extramaterial",
          "numberOfLicensesText": "{numberOfLicensesLeft, plural, one {1 licens} other {# licenser} }",
          "numberOfLicensesTooltip": "Antalet licenser att tilldela",
          "pickButtonLabel": "Lägg till",
          "trialTextLabel": "Prova gratis",
          "trialTooltip": "Prova gratis i 30 dagar",
          "trialTooltipUntil": "Prova gratis till {date}",
          "unableToPickButtonLabel": {
            "invalidType": "Det går inte att blanda Digilär-produkter med andra produkter i samma kurs för tillfället. Detta är under utveckling.",
            "teachersOnly": "Produkten är endast för lärare och kan därför inte läggas till i kursen"
          },
          "unlimitedTextLabel": "Obegränsad",
          "unlimitedTooltip": "Obegränsat antal licenser att tilldela",
          "unpickButtonLabel": "Ta bort"
        }
      },
      "productPickerModal": {
        "abortButtonLabel": "Avbryt",
        "activationCode": "Aktiveringskod",
        "handledExternally": "Skolans licenser hanteras i Skolon",
        "handledInUnknownSystem": "annat system",
        "navItems": {
          "products": "Produkter",
          "tryForFree": "Prova gratis"
        },
        "saveButtonLabel": "Spara",
        "title": "Välj produkter"
      },
      "productQuerier": {
        "errorAlertMessage": "Produkterna kunde inte hämtas.",
        "extraCheckboxLabel": "Visa extramaterial",
        "noResultAlertMessage": "Inga produkter hittades.",
        "searchButtonLabel": "Sök",
        "searchInputPlaceholder": "Sök produkt eller extramaterial",
        "stageSelectPlaceholder": "Alla åldrar",
        "subjectSelectPlaceholder": "Alla kategorier"
      },
      "productRoot": {
        "productInformation": "Produktinformation"
      },
      "productsCoursesOverview": {
        "filters": {
          "ordering": {
            "all": "Kurser överst",
            "courses": "Kurser överst",
            "label": "Visar:",
            "products": "Produkter överst"
          },
          "sorting": {
            "date": "Senast",
            "label": "Sortering:",
            "title": "A - Ö"
          }
        },
        "openProductsPopup": {
          "buttonText": "Lägg till produkt",
          "popup": {
            "activationCode": {
              "description": "Använd din aktiveringskod för att aktivera produkt.",
              "title": "Med aktiveringskod"
            },
            "products": {
              "description": "Välj bland inköpta produkter och 100-tals gratis extramaterial.",
              "title": "Produkter eller extramaterial"
            },
            "productsPrivate": {
              "description": "Välj bland 100-tals gratis extramaterial.",
              "title": "Extramaterial"
            },
            "tryFree": {
              "description": "Prova produkter kostnadsfritt i 30 dagar.",
              "title": "Prova gratis"
            }
          }
        },
        "subtitles": {
          "courses": "Kurser",
          "products": "Produkter"
        }
      },
      "productsOverview": {
        "filter": {
          "all": "Allt",
          "courses": "Kurser",
          "products": "Produkter"
        },
        "order": {
          "date": "Senast",
          "title": "Bokstavsordning"
        }
      },
      "profileSettingsAvatar": {
        "changeAvatar": "Välj avatar"
      },
      "profileSettingsName": {
        "missingFirstName": "Du måste ange ett förnamn"
      },
      "shareLinkModal": {
        "copy": "Kopiera",
        "copyLink": "Kopiera länk",
        "message": {
          "body": "{title}",
          "title": "{title}"
        },
        "shareTo": "Dela till",
        "title": "Dela innehåll"
      },
      "tbRender": {
        "assignmentLink": {
          "excludedAlertMessage": "Läraren har tagit bort uppgiften"
        },
        "attachment": {
          "download": "Ladda ner"
        },
        "image": {
          "fullscreenTooltip": "Visa i helskärmsläge"
        }
      },
      "userExternalLinkDialog": {
        "cancelButtonLabel": "Avbryt",
        "continueButtonLabel": "Fortsätt",
        "message": "Du kommer att förflyttas till följande adress. Vill du fortsätta?",
        "title": "Du lämnar nu Natur & Kulturs plattform"
      },
      "userModal": {
        "change": "Ändra",
        "choosePassword": "Välj lösenord",
        "class": "Klass",
        "courses": "Kurser",
        "coursesTab": {
          "noCourses": "Användaren ingår inte i några kurser"
        },
        "enterPassword": "Ange lösenord",
        "externalLicenses": "Användarens licenser kan inte redigeras eftersom din skola hanterar licenser hos tredje part.",
        "externalUsers": "Användaren kan inte redigeras eftersom din skola hanterar användardata hos tredje part.",
        "firstName": "Förnamn",
        "general": "Allmänt",
        "lastName": "Efternamn",
        "licenses": "Licenser",
        "licensesTab": {
          "handleLicenses": "Hantera licenser",
          "license": "Licens",
          "noLicenses": "Användaren har inga licenser.",
          "product": "Produkt",
          "showLicenses": "Visa licenser"
        },
        "noDigilarLogin": "Användarens inloggningsuppgifter kan inte ändras här eftersom de hanteras genom en integration.",
        "password": "Lösenord",
        "passwordResetFailed": "En återställningslänk kunde inte skickas. Försök igen senare.",
        "passwordResetSuccess": "En återställningslänk har skickats.",
        "passwordSetFailed": "Lösenordet kunde inte sparas. Försök igen senare.",
        "passwordSetSuccess": "Lösenordet har sparats.",
        "passwordWarning": "Lösenordet måste vara minst 8 tecken långt",
        "resetPassword": "Glömt lösenord?",
        "resetPasswordButton": "Återställ lösenord",
        "resetPasswordMessage": "Skickar en länk som låter användaren välja ett nytt lösenord till användarens e-postadress.",
        "restore": "Återställ",
        "save": "Spara",
        "saveFailed": "Användaren kunde inte sparas",
        "saveSuccess": "Användaren sparades",
        "showPassword": "Visa lösenord",
        "userCanNotSetPassword": "Användarens lösenord kan inte ändras.",
        "userSaved": "Användaren sparades",
        "viewCourses": "Visa kurser",
        "viewLicenses": "Visa licenser",
        "viewPassword": "Återställ lösenord",
        "viewStudent": "Elevinställningar",
        "viewUser": "Visa användaren"
      },
      "waitFor": {
        "errorAlertMessage": "Datan kunde inte laddas.",
        "retryButtonLabel": "Försök igen"
      }
    },
    "compton": {
      "alert": {
        "dismissButtonLabel": "Avfärda"
      },
      "audio": {
        "airPlayLabel": "AirPlay",
        "errorMessage": "Ljudfilen kunde inte laddas.",
        "loadingLabel": "Laddar …",
        "pauseLabel": "Pausa",
        "playLabel": "Spela upp",
        "settingsLabel": "Inställningar"
      },
      "await": {
        "errorMessage": "Datan kunde inte laddas.",
        "loaderLabel": "Laddar ...",
        "retryButtonLabel": "Försök igen"
      },
      "confirm": {
        "cancelLabel": "Avbryt",
        "okLabel": "OK"
      },
      "copyrightList": {
        "item": {
          "detailLabel": "Ⓒ {copyright}",
          "typeLabel": "{type, select, audios {Ljud} images {Bild} videos {Video} interactiveImages {Interaktiv bild} other {Material} }",
          "typeWithIndexLabel": "{type, select, audios {Ljud} images {Bild} videos {Video} interactiveImages {Interaktiv bild} other {Material} } i avsnitt {index}"
        }
      },
      "copyrightPopup": {
        "headingLabel": "Copyright"
      },
      "copyrightPopupButton": {
        "label": "Copyright"
      },
      "datepicker": {
        "months": "Januari,Februari,Mars,April,Maj,Juni,Juli,Augusti,September,Oktober,November,December",
        "nextMonth": "Nästa månad",
        "previousMonth": "Föregående månad",
        "weekdays": "Söndag,Måndag,Tisdag,Onsdag,Torsdag,Fredag,Lördag",
        "weekdaysShort": "Sön,Mån,Tis,Ons,Tor,Fre,Lör"
      },
      "dialog": {
        "header": {
          "closeButtonLabel": "Stäng"
        }
      },
      "dismissibleTooltip": {
        "dismissButtonLabel": "Avfärda"
      },
      "humanSize": {
        "units": {
          "byte": "{size, plural, one {byte} other {bytes} }",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "tb": "TB"
        }
      },
      "image": {
        "errorMessage": "Bilden kunde inte laddas."
      },
      "imageViewer": {
        "overlay": {
          "closeButtonLabel": "Stäng",
          "nextButtonLabel": "Nästa bild",
          "previousButtonLabel": "Föregående bild"
        }
      },
      "input": {
        "clearButtonLabel": "Rensa"
      },
      "interactiveImage": {
        "close": "Stäng",
        "errorMessage": "Bilden kunde inte laddas.",
        "fullscreen": "Fullskärm",
        "legendsMenuButtonLabel": "Färgförklaring",
        "menu": {
          "copyrightHeadingLabel": "Copyright",
          "legendsHeadingLabel": "Färgförklaring"
        },
        "nextStepButtonLabel": "Nästa steg",
        "previousStepButtonLabel": "Föregående steg",
        "showMarkerTip": "Klicka på punkten",
        "showStepTip": "Gå vidare till nästa steg",
        "showZoomTip": "Zooma i bilden",
        "stepProgressLabel": "Steg {step} av {totalSteps}",
        "zoomInButtonLabel": "Zooma in",
        "zoomOutButtonLabel": "Zooma ut"
      },
      "pagination": {
        "firstLabel": "Första",
        "lastLabel": "Sista",
        "nextLabel": "Nästa",
        "page": "Sida",
        "prevLabel": "Föregående",
        "totalLabel": "av {count}"
      },
      "select": {
        "label": "{count} alternativ valda"
      },
      "snackbar": {
        "snack": {
          "dismissButtonLabel": "Avfärda"
        }
      },
      "tag": {
        "addNewButtonLabel": "Lägg till",
        "emptyMessage": "Lägg till taggar",
        "removeButtonLabel": "Ta bort"
      },
      "upload": {
        "multipleLabel": "Ladda upp {format, select, image {bilder} video {videos} audio {ljudfiler} subtitle {undertexter} other {filer} } … ",
        "singleLabel": "Ladda upp {format, select, image {bild} video {video} audio {ljudfil} subtitle {undertext} other {fil} } … "
      },
      "uploadProgressModal": {
        "abortButtonLabel": "Avbryt",
        "message": "{uploadedSize} av {totalSize}",
        "title": "Laddar upp ..."
      },
      "video": {
        "subtitleLabel": {
          "en": "Engelska",
          "sv": "Svenska"
        }
      },
      "vrImage": {
        "button": "Visa 360-bild",
        "loading": "Laddar ..."
      }
    },
    "content": {
      "chooseTextVersion": "Textversion",
      "edition": "Version",
      "editionTooltip": "Textversion",
      "level": "Nivå",
      "levelTooltip": "Svårighetsgrad",
      "noContent": "Innehåll saknas",
      "typeTooltip": "Typ av innehåll"
    },
    "contentInfo": {
      "about": "Information",
      "author": "Författare",
      "by": "av",
      "content": "Text",
      "copyLink": "Kopiera länk",
      "copyReference": "Kopiera källhänvisning",
      "copyToClipboard": "Kopiera",
      "copyright": "Copyright",
      "description": "Beskrivning",
      "dialect": "Dialekt",
      "goTo": "Gå till",
      "guidance": "Lärarhandledning",
      "includedInProducts": "{num, plural, =0 {Materialet ingår inte i några produkter.} =1 {Materialet ingår i produkten:} other {Materialet ingår i produkterna:}}",
      "isbn": "(ISBN) {id}",
      "language": "Språk",
      "lastUpdated": "Senast uppdaterad",
      "level": "Nivå",
      "numComponents": "Antal stycken",
      "numSections": "Antal avsnitt",
      "numWords": "Antal ord",
      "overview": "Allmänt",
      "product": {
        "articleNumber": "Artikelnummer",
        "authors": "Författare",
        "firstReleased": "Första utgåva",
        "title": "Titel"
      },
      "productInformation": "Produktinformation",
      "readingTime": "Uppskattad lästid",
      "readingTimeMin": "{time} min",
      "sourceDigilar": "Använda det här innehållet som källa",
      "sourceReference": "Källhänvisning",
      "sourceString": "{url} (tillgänglig: {datetime})",
      "textVersion": "Text{num, select, 1 {} other {: {version}}}",
      "textVersions": "Textversioner",
      "type": "Innehållstyp"
    },
    "continue": "Fortsätt",
    "courses": {
      "activeUntil": "Aktiv till: {date}",
      "activities": {
        "showMore": "Visa fler"
      },
      "adaptCourse": {
        "changeImageButtonText": "Byt bild",
        "chooseImageButtonText": "Välj bild",
        "chooseLanguage": "Välj språk",
        "coverImage": "Omslagsbild",
        "generalLabel": "Generella",
        "hideDiagnosis": "Lås diagnoser",
        "hideDiagnosisSubLabel": "Lås upp en diagnos genom att dela ut den genom ett uppdrag",
        "hideKey": "Dölj facit & lösningsförslag",
        "hideLabel": "Facit och diagnoser i kursen",
        "languageLabel": "Språk"
      },
      "addBook": "Lägg till",
      "addProduct": "Lägg till",
      "allGroups": "Alla klasser",
      "analyze": "Analysera",
      "archive": "Arkivera",
      "archiveConfirm": "Kursen kommer att arkiveras och döljas för deltagarna. En arkiverad kurs kan återställas från skolans administration.",
      "archiveConfirmAction": "Arkivera kurs",
      "archiveCourse": "Arkivera kursen",
      "archiveInfo": "Om kursen arkiveras är den inte längre synlig för deltagarna.",
      "audit": "Uppföljning",
      "auditComments": "Granska kommentarer",
      "auditExercises": "Granska uppgifter",
      "auditPage": {
        "allStudents": "Alla elever i kursen",
        "allStudentsInTodo": "Alla elever i uppdraget",
        "attempts": "{num} försök",
        "breadcrumb": {
          "comments": "Kommentarer",
          "inbox": "Nytt",
          "index": "Översikt",
          "log": "Elever",
          "overview": "Allt innehåll",
          "todos": "Uppdrag"
        },
        "commentedOn": "{userString} kommenterade {dateString}",
        "comments": "Kommentarer",
        "compare": "Jämför",
        "diagnosisResultTypes": {
          "attempt": "Försök {attempt}",
          "best": "Det bästa försöket",
          "first": "Det första försöket",
          "latest": "Det senaste försöket"
        },
        "inbox": "Nytt",
        "inboxNote": "* Notera att du här endast får in svar till inlämningsuppgifter och ej till diagnoser, självrättande övningar och instuderingsfrågor.",
        "index": {
          "comments": {
            "buttonText": "Granska kommentarer",
            "description": "Här sammanställs alla kommentarer till uppgifter i kronologisk ordning. Du kan välja att se kommentarer för hela elevgruppen samtidigt eller för en enskild elev.",
            "title": "Kommentarer till uppgifter"
          },
          "inbox": {
            "buttonText": "Granska nytt",
            "description": "Här samlas nya uppgiftssvar som eleverna har lämnat in. Listan uppdateras när nya svar lämnas in och när du granskar. Du kan välja att granska hela elevgruppen i kursen eller en enskild elev.",
            "title": "Inkorg för inlämningsuppgifter"
          },
          "log": {
            "buttonText": "Granska enskild elev",
            "description": "Här kan du se vad en enskild elev i kursen har arbetat med. Elevens aktivitet och resultat visas i kronologisk ordning.",
            "title": "Följ en enskild elev"
          },
          "notification": "{num, plural, =1 {1 ny} other {{num} nya}}",
          "overview": {
            "buttonText": "Granska allt",
            "description": "Här kan du se elevernas svar och resultat på samtliga uppgifter som ingår i kursen. Svaren är ordnade utifrån innehållets struktur. Du kan välja att granska hela elevgruppen i kursen eller en enskild elev.",
            "title": "Alla uppgifter och övningar"
          },
          "todos": {
            "auditButton": "Uppföljning",
            "buttonText": "Granska uppdrag",
            "description": "Här kan du se elevernas svar och resultat på alla uppgifter som ingår i ett enskilt uppdrag. Du kan välja att granska hela elevgruppen i uppdraget eller en enskild elev.",
            "disabledText": "Det finns inga uppdrag att granska",
            "noAudits": "Det finns inga övningar här.",
            "title": "Resultat i uppdrag"
          }
        },
        "loading": "Hämtar svar",
        "loadingDiagnosis": "Hämtar försök",
        "log": "Elever",
        "noAnswers": "Inga svar",
        "noAttempts": "Inga försök",
        "noComments": "Hittade inga kommentarer.",
        "noDiagnosis": "Titeln innehåller inga diagnoser.",
        "noStudentSelected": "Ingen elev har valts.",
        "noStudents": "Det finns inga elever i kursen",
        "noTeacherAssignments": "Titeln innehåller inga läraruppgifter.",
        "numAnswered": "{count} av {total}",
        "overview": "Allt",
        "selectStudent": "Välj elev",
        "you": "Du"
      },
      "books": "Innehåll",
      "booksPage": {
        "booksWarning": "Din kurs innehåller {numBooks} titlar. Det kan vara svårt att få en överblick över materialet och dina elevers resultat med så många. Kursen kommer ändå att fungera, men överväg att dölja vissa titlar inuti kursen (du kan när som helst välja att visa dem igen). Ett annat alternativt är att dela upp titlarna i flera kurser, till exempel en kurs per ämne.",
        "heading": "Innehåll"
      },
      "booksWarning": "Din kurs innehåller {numBooks} titlar. Det kan vara svårt att få en överblick över materialet och dina elevers resultat med så många. Kursen kommer ändå att fungera, men överväg att dölja vissa titlar inuti kursen (du kan när som helst välja att visa dem igen). Ett annat alternativt är att dela upp titlarna i flera kurser, till exempel en kurs per ämne.",
      "calendar": "Kalender",
      "cancel": "Avbryt",
      "changeNameButtonText": "Ändra namn",
      "chooseBooks": "Välj produkter ({numBooks, plural, =0 {0 valda} =1 {1 vald} other {# valda}})",
      "chooseGroups": "Välj klasser",
      "chooseStudents": "Välj elever",
      "chooseStudentsWithNumSelected": "Välj elever ({numSelected, plural, =0 {0 valda} =1 {1 vald} other {# valda}})",
      "chooseTeachers": "Välj lärare",
      "chooseTeachersWithNumSelected": "Välj lärare ({numSelected, plural, =0 {0 valda} =1 {1 vald} other {# valda}})",
      "continueWhereIwas": "Fortsätt",
      "courseAdministration": "Administrera kurs",
      "courseBooks": "Kursens innehåll",
      "courseCard": {
        "scheduledBadge": "Planerad till {startDate}"
      },
      "courseName": "Kursens namn",
      "courseNamePlaceholder": "Ange ett namn på kursen",
      "courseNoUsers": "Kursen har inga elever just nu. Du kan lägga till elever i Administrera kurs.",
      "courseSettings": "Administrera kurs",
      "courseUpdated": "Kursinställningar uppdaterade",
      "cover": "Omslagsbild",
      "coverImage": "Omslagsbild",
      "createCourse": "Skapa kurs",
      "createMessage": "En kurs ger dig som lärare möjlighet att arbeta tillsammans med eleverna i valda produkter. I många av våra produkter finns det möjlighet att skapa en egen planering, kommunicera med eleverna och följa deras framsteg.",
      "dates": "Kursperiod",
      "demoLicense": "Test",
      "done": "Klar",
      "eMail": "E-post",
      "editingCourse": "Administrera kurs",
      "endDate": "Slutdatum",
      "finished": "Klar",
      "generatedCourseName": "{productTitle} med {userName}",
      "groupNoUsers": "Klassen har inga elever",
      "groupNumUsers": "{count, plural, =0 {Inga elever} =1 {En elev} other {# elever}}",
      "groups": "Klasser",
      "handleLicenses": "Hantera licenser",
      "hasNotLoggedIn": "Har inte loggat in",
      "hideBook": "Dölj titeln för dina elever",
      "history": "Historik",
      "invalidImage": "Bilden kunde inte läsas in. Antingen var filen inte en bild eller så var bilden för stor.",
      "keepAlive": "Behåll kursen efter slutdatumet",
      "latestVisited": "Senast besökta",
      "latestVisitedEmpty": "Du har inte besökt något innehåll än.",
      "licenseExhausted": {
        "action": "Gå till nok.se",
        "message": "Din skola har slut på licenser i <strong>{product}</strong>. Det finns <strong>{num, plural, =1 {en} other {{num}}}</strong> användare i den här kursen som inte har tillgång till den här produkten just nu. För att lösa problemet kan du köpa fler licenser på <em>nok.se</em>.",
        "target": "https://nok.se"
      },
      "licenseExpired": {
        "action": "Gå till nok.se",
        "message": "Licensen i <strong>{product}</strong> har gått ut. Du kan köpa nya licenser på <em>nok.se</em>.",
        "target": "https://nok.se"
      },
      "licenseNotRenewed": {
        "action": "Byt licens",
        "message": "Den aktuella licensen i <strong>{product}</strong> har gått ut men det finns en ny licens. Vill du använda den nya licensen i den här kursen?"
      },
      "licenseWarning": {
        "buttonAction": "{canAssignAll, select, true {Tilldela dessa en licens} other {Hantera licenser}}",
        "heading": "{productTitle, select, null {} other {{productTitle}: }}{canAssignAll, select, true {Några användare saknar licens} other {Det finns inte tillräckligt med licenser att tilldela}}",
        "linkAction": "{canAssignAll, select, true {Hantera licenser} other {Administrera kurs}}",
        "message": "{canAssignAll, select, true {Tilldela dessa en licens direkt eller använd \"Hantera licenser\" för att se vilka användare som saknar licens för läromedlet.} other {Alla användare i kursen kommer inte att kunna tilldelas en licens. Din skola behöver köpa fler licenser för {productTitle}.}}",
        "userMissingLicense": {
          "action": "Hantera licenser",
          "heading": "{productTitle}:",
          "message": "Du saknar licens för läromedlet",
          "studentMessage": "Du saknar licens för läromedlet. Meddela din lärare.",
          "teacherMessage": "Du saknar licens för läromedlet."
        }
      },
      "loadingImage": "Läser in bild …",
      "menu": {
        "pages": "Kurssidor",
        "start": "Kursens startsida",
        "students": "Elever"
      },
      "missingBooks": "Kursen saknar innehåll. Gå till Administrera kurs och välj vilka titlar du vill jobba med i kursen.",
      "missingLicenses": "Du kan inte använda kursen eftersom du saknar {count, plural, =1 {en licens} other {licenser}} i:",
      "missingLicensesButtonLabel": "Saknas licenser",
      "missingLicensesDate": "Licensen gick ut {date}",
      "missingLicensesMessage": "För att fortsätta behöver du {count, plural, =1 {en licens} other {licenser}} för:",
      "myResults": "Mina resultat",
      "name": "Namn",
      "nameError": "Skriv ett namn på kursen",
      "noAddedBooks": "Du har inte valt någon produkt",
      "noBooks": "Hittade inga produkter",
      "noCourses": "Kom igång – skapa kurser och tilldela licenser!",
      "noLicenseWarningsAlert": "Din kurs har fler än 80 användare. Då visas inga varningar om någon saknar en licens. Klicka på “Hantera licenser” för att se om alla har licens.",
      "noProducts": "Hittade inga produkter",
      "noSelectedStudents": "Du har inte valt några elever",
      "noSelectedTeachers": "Du har inte valt några lärare. Vi rekommenderar att en kurs har minst en lärare.",
      "noStudents": "Hittade inga elever",
      "noStudentsMessage": {
        "external": {
          "heading": "Inga elever har loggat in än",
          "info": "Din skola har en integration med {provider}. Eleverna visas här när de loggar in genom integrationen."
        },
        "internal": {
          "button": "Lägg till elever",
          "heading": "Din skola har inte lagt till några elever",
          "info": "För att använda kurser tillsammans med dina elever måste du först lägga till eleverna på din skola. Detta gör du enkelt genom administrationen."
        }
      },
      "noTeachers": "Hittade inga lärare11111",
      "noUsersMessage": "Det finns inga {roleName, select, students {elever} other {lärare} } som matchar din sökning.",
      "numStudents": "{count, plural, one {En elev } other {{count} elever }}{hasGroups, select, true { i {countGroups, plural, one {en grupp} other {{countGroups} grupper}}} other {}}",
      "numTeachers": "{count} lärare",
      "overview": "Översikt",
      "overviewPage": {
        "activities": "Aktiviteter",
        "addActivity": "Lägg till",
        "addPost": "Inlägg",
        "addTodo": "Uppdrag",
        "books": "Innehåll",
        "dontShowMessageAgainButton": "Visa inte detta meddelande igen",
        "feed": "Översikt",
        "hideBooksForStudentsButton": "Dölj titlar på översikten",
        "noActivities": "Det finns inga aktiviteter i flödet",
        "readMoreAboutCoursesButton": "Läs mer om hur du använder kurser",
        "showBooksForStudentsButton": "Visa titlar på översikten",
        "welcomeContent": "<p class=\"paragraph\">Det här är kursens översikt som du delar med dina elever. Här kan du bygga upp en egen planering och dela ut innehåll. I kursens meny finns dina verktyg:</p><ul class=\"unordered-list\"><li>Kursens titlar i sin helhet finns tillgängliga under Innehåll och här på översikten.</li><li>Du kan aktivera dina elever, dela ut innehåll varvat med eget material och kommunicera genom Inlägg och Uppdrag. </li><li>Du kan se elevernas svar på uppgifter och själv kommentera under Uppföljning.</li><li>Via Administrera kurs kan du göra ytterligare inställningar till kursen. </li></ul>",
        "welcomeHeading": "Information om din kurs:",
        "welcomeProducts": "Innehåll"
      },
      "personalLicense": "Personlig",
      "personalLicenseUntil": "Personlig licens till {date}",
      "plan": "Planera",
      "postItem": {
        "addHeading": "Nytt inlägg",
        "draft": "Utkast",
        "editHeading": "Redigera inlägg",
        "editPost": "Redigera inlägg",
        "form": {
          "addInternalLink": "Lägg till internlänk",
          "cancel": "Avbryt",
          "confirmDelete": "Är du säker på att du vill ta bort det här inlägget?",
          "content": "Innehåll",
          "linkPreviews": "Förhandsvisning av länkar",
          "missingReceivers": "{num, plural, =1 {En} other {#}} mottagare för ditt inlägg har tagits bort.",
          "more": "Fler alternativ",
          "options": "Alternativ",
          "publish": "Publicera",
          "remove": "Ta bort",
          "removeInternalLink": "Ta bort",
          "saveAsDraft": "Spara",
          "scheduleFrom": "Visas från",
          "scheduler": "Schemalägg",
          "snackbarDeletedMessage": "Inlägget är borttaget",
          "snackbarSaveDraftMessage": "Inlägget har sparats. Eftersom det är ett utkast visas det inte på översikten. För att redigera inlägget går du till fliken Inlägg.",
          "snackbarSaveMessage": "Inlägget har sparats.",
          "snackbarSaveScheduledMessage": "Inlägget har sparats. Eftersom det är schemalagt visas det inte på översikten. För att redigera inlägget går du till fliken Inlägg.",
          "switchToDraft": "Avpublicera",
          "targetStudents": "Rikta till enskilda elever",
          "title": "Titel",
          "update": "Uppdatera"
        },
        "missingEntities": "En eller flera länkar har tagits bort eftersom den sida som de länkade till har avpublicerats i samband med revidering.",
        "scheduled": "Schemalagd"
      },
      "postPage": {
        "createPost": "Lägg till inlägg",
        "ctaContent": "Med inlägg kan du snabbt meddela dina elever om aktuella händelser, föra diskussioner och ge länktips.",
        "ctaHeading": "Nå ut till dina elever",
        "heading": "Inlägg",
        "noPosts": "Det finns inga inlägg i den här kursen än.",
        "noPublishedPosts": "Det finns inga publicerade inlägg i den här kursen än."
      },
      "posts": "Inlägg",
      "remainingInfo": {
        "remaining": "{remaining} kvar",
        "unlimited": "Obegränsad"
      },
      "removeBook": "Dölj",
      "removeGroupConfirm": "Vill du ta bort gruppen från kursen?",
      "removeProduct": "Ta bort",
      "removeProductConfirm": "Vill du ta bort läromedlet från kursen?",
      "removeStudentConfirm": "Vill du ta bort eleven från kursen?",
      "removeTeacherConfirm": "Vill du ta bort läraren från kursen?",
      "restore": "Återställ",
      "restoreCourse": "Återställ kursen",
      "restoreInfo": "Kursen är arkiverad och därför inte synlig för medlemmarna.",
      "save": "Spara",
      "schoolLicense": "Skollicens",
      "schoolLicenseUntil": "Skollicens till {date}",
      "search": "Sök",
      "searchHint": {
        "add": "Lägg till {roleName, select, students {elever} other {lärare} }",
        "addToSchool": "Lägg till {roleName, select, students {elever} other {lärare} } på skolan",
        "description": "Hittar du inte en {roleName, select, students {elev} other {lärare} }, som du vill ha i din kurs, beror det troligtvis på att {roleName, select, students {eleven} other {läraren} } inte är tillagd på skolan.",
        "descriptionExtra": "Du kan lägga till {roleName, select, students {elever} other {lärare} } genom att gå till skolans administration.",
        "externalProvider": "{roleName, select, students {Eleverna} other {Lärarna} } synkas från <b>{provider}</b>. Hittar du inte en {roleName, select, students {elev} other {lärare} } här, beror det troligtvis på att {roleName, select, students {eleven} other {läraren} } inte synkats än.",
        "helper": {
          "missingUser": "Saknas {roleName, select, students {elev} other {lärare} }?"
        },
        "lookingFor": "Hittar du inte en viss {roleName, select, students {elev} other {lärare} }?"
      },
      "searchProduct": "Sök produkt",
      "searchStudentOrClass": "Elev eller klass",
      "searchStudentOrGroup": "Elev eller grupp",
      "searchTeachers": "Sök lärare",
      "selectedStudentsGroupName": "Enskilda elever",
      "settings": "Inställningar",
      "settingsPage": {
        "heading": "Inställningar"
      },
      "shortcuts": {
        "addShortcut": "Lägg till genväg",
        "deleteShortcut": "Ta bort",
        "label": "Genvägar",
        "noShortcuts": "Inga genvägar tillagda"
      },
      "showBook": "Visa titeln för dina elever",
      "showLicenses": "Visa licenser",
      "showLog": "Visa elevlogg",
      "startDate": "Startdatum",
      "studentSelectTab": {
        "classes": "Klasser",
        "groups": "Grupper",
        "noUserGroupsMatchingSearch": "Inga grupper hittades.",
        "searchGroups": "Sök grupper",
        "searchGroupsPlaceholder": "Ange gruppnamn"
      },
      "students": "Elever",
      "studentsWarning": "Din kurs har {numStudents} elever. Därför kan det bli svårt att överblicka allas resultat i uppföljningsfunktionen. Kursen kommer ändå att fungera, men överväg att dela upp eleverna i flera kurser, till exempel en kurs per undervisningsgrupp.",
      "tagLine": {
        "andStudents": "{num, plural, =1 {1 elev} other {# elever}}",
        "showMore": "Visa fler"
      },
      "teachers": "Lärare",
      "toCoursePage": "Gå till kurs",
      "todo": "Uppdrag",
      "todoForm": {
        "addDescription": "Lägg till beskrivning",
        "addStep": "Lägg till steg",
        "cancel": "Avbryt",
        "delete": "Ta bort",
        "description": "Beskrivning",
        "errors": {
          "noName": "Namn saknas",
          "noSteps": "Du måste skapa minst ett steg."
        },
        "heading": "Uppdrag",
        "hideSolutions": "Dölj facit",
        "legacyAlertMessage": "Detta är ett uppdrag av en äldre typ som skapats med vår tidigare uppdragsbyggare. Du kan inte skapa nya uppdrag av den här typen men du kan fortfarande redigera dina gamla uppdrag.",
        "more": "Fler alternativ",
        "name": "Namn",
        "namePlaceholder": "Ange namn på uppdraget",
        "noSteps": "Inga steg tillagda",
        "publish": "Publicera",
        "removeDescription": "Dölj beskrivning",
        "saveAsDraft": "Spara",
        "schedule": "Schemalägg",
        "scheduleFrom": "Visas från",
        "snackbarCreatedMessage": "Ett uppdrag är skapat",
        "snackbarDeletedMessage": "Uppdraget är borttaget",
        "snackbarUpdatedMessage": "Uppdraget är uppdaterat",
        "stepForm": {
          "cancel": "Avbryt",
          "chooseBook": "Välj innehåll",
          "delete": "Ta bort",
          "diagnosisNumTries": "Ändra totalt antal försök",
          "errors": {
            "noSelected": "Du måste välja ett innehåll"
          },
          "name": "Namn",
          "placeholder": "Ange namn på steget",
          "save": "Spara"
        },
        "stepTable": {
          "name": "Namn",
          "type": "Typ"
        },
        "steps": "Steg",
        "switchToDraft": "Avpublicera",
        "targetStudents": "Rikta till enskilda elever",
        "update": "Uppdatera"
      },
      "todoItem": {
        "addHeading": "Nytt uppdrag",
        "draft": "Utkast",
        "edit": "Redigera uppdrag",
        "editHeading": "Redigera uppdrag",
        "scheduled": "Schemalagd",
        "start": "Öppna"
      },
      "todoPage": {
        "createTodo": "Lägg till uppdrag",
        "ctaContent": "Med uppdrag väljer du ut innehåll och delar ut det till dina elever.",
        "ctaHeading": "Aktivera dina elever",
        "heading": "Gamla uppdrag",
        "noTodos": "Det finns inga uppdrag än i den här kursen."
      },
      "unsavedWarning": "Dina ändringar kommer inte sparas om du lämnar sidan.",
      "uploadImage": "Ladda upp bild",
      "userMissingLicense": "Saknar licens",
      "userMissingLicenses": "Saknar {count, plural, =1 {licens} other {licenser}}",
      "usersMissingLicenses": {
        "action": "Aktivera licenser",
        "message": "Det finns <strong>{num, plural, =1 {en} other {{num}}}</strong> användare i den här kursen som inte har tillgång till <strong>{product}</strong>."
      },
      "usesLicensesCheck": {
        "heading": "Automatisk licenstilldelning",
        "label": "Använd automatisk licenstilldelning",
        "message": "Denna inställning gör att alla elever och lärare som läggs till i den här kursen automatiskt får tillgång till skolans licenser för de produkter som är valda.",
        "warning": "Skolans licenser kommer inte att förbrukas. För att eleverna ska komma åt kursen behöver licenser tilldelas manuellt eller köpas av användarna själva."
      },
      "youAreMissingLicenses": "Du saknar {count, plural, =1 {licens} other {licenser}}"
    },
    "cover": "Omslag",
    "customize": "Anpassa",
    "deletedUser": "Borttagen användare",
    "detroit": {
      "canvasPresentor": {
        "pickColor": "Välj färg",
        "pickPresetColor": "Anpassad",
        "presetColors": "Färg",
        "removeObject": "Ta bort objekt",
        "restartCanvas": "Börja om",
        "restartConfirm": "Vill du börja om?",
        "saved": "Sparad",
        "saving": "Sparar"
      },
      "confirm": {
        "cancelLabel": "Avbryt",
        "okLabel": "OK"
      }
    },
    "diagnosis": {
      "aborted": "Avbrutet",
      "attempt": {
        "attemptNumber": "Försök {attempt}",
        "showMyAnswers": "Visa mina svar"
      },
      "attemptsLeft": "{count} försök kvar av totalt {total}",
      "audit": {
        "attemptCompleted": "Genomfört försök",
        "done": "Klar",
        "goBack": "Tillbaka"
      },
      "confirmTurnInMessage": "Det här avslutar diagnosen och lämnar in resultatet till din lärare.",
      "confirmTurnInTitle": "Vill du avsluta diagnosen?",
      "continue": "Fortsätt",
      "done": {
        "close": "Stäng diagnos",
        "controll": "Kontrollera svar",
        "goodJob": "Bra jobbat!",
        "goodTry": "Bra försök!",
        "results": "Ditt resultat",
        "text": "Bra jobbat, diagnosen är nu inlämnad!",
        "textResult": "Du hade {correct} rätt av {total} möjliga"
      },
      "end": "Avsluta diagnos",
      "exercises": "övningar",
      "latestResult": "Senaste resultat",
      "minutes": "minuter",
      "noResult": "Inget resultat",
      "onTimeUp": "Om tiden går ut avslutas diagnosen.",
      "pointsRequired": "Om du får mindre än {required} rätt behöver du öva mer.",
      "result": "{score} av {scoreMax} rätt",
      "savingResult": "Sparar ditt resultat",
      "showResults": "Visa försök",
      "start": "Starta diagnosen",
      "startInfo": "När diagnosen startats kan den inte pausas.",
      "teacherAttemptsInfo": "Lärare kan alltid starta en diagnos."
    },
    "entityBrowser": {
      "collapse": "Stäng",
      "expand": "Öppna",
      "import": {
        "cancel": "Avbryt",
        "done": "Klar",
        "multipleSelected": "{number} valda.",
        "oneSelected": "1 vald."
      },
      "moreInfo": "Information"
    },
    "entityImport": {
      "title": "Välj innehåll"
    },
    "entityMeta": {
      "menu": {
        "aboutContent": "Information",
        "copyLink": "Kopiera djuplänk",
        "share": "Dela"
      }
    },
    "error": {
      "content": "Något gick fel när vi försökte hämta data från servern. Försök igen om en liten stund.",
      "heading": "Innehållet kunde inte hämtas",
      "toHomescreen": "Gå till startsidan",
      "tryAgain": "Försök igen"
    },
    "exercise": {
      "answer": "Svar",
      "button": {
        "correct": "Svara",
        "done": "Klar",
        "hideKeyTooltip": "Dölj facit",
        "hideSolutionTooltip": "Dölj lösningsförslag",
        "keyTooltip": "Visa facit",
        "keyboardTooltip": "Visa tangentbord",
        "next": "Nästa",
        "resetTooltip": "Rensa svar",
        "solutionTooltip": "Visa lösningsförslag",
        "submit": "Lämna in"
      },
      "check": {
        "incompleteMessage": "Du måste markera att du är klar."
      },
      "choice": {
        "hint": "<strong>Tips!</strong> Du har inte bockat för alla svarsalternativ som är rätt.",
        "incompleteMessage": "Välj ett svarsalternativ.",
        "incompleteMessageMultiple": "Välj ett eller flera svarsalternativ."
      },
      "commentTooltip": "Kommentarer",
      "comments": "Kommentarer",
      "editableMatrix": {
        "incompleteMessage": "Svar saknas."
      },
      "editor": {
        "incompleteMessage": "Skriv ett svar."
      },
      "fullscreenTooltip": "Fullskärmsläge",
      "goBack": "Uppgifter",
      "goToExercise": "Gå till uppgiften",
      "hideDescription": "Dölj beskrivning",
      "imageDragEmpty": "Inga fler bilder att dra.",
      "index": "Innehåll",
      "instruction": "Instruktion",
      "level": "Nivå {level}",
      "levelTooltip": "Svårighetsgrad",
      "media": {
        "access": "Du har inte gett tillgång till din mikrofon",
        "incomplete": "Du måste spela in ett ljud för att lämna in.",
        "noRecording": "Inget ljud inspelat",
        "processing": "Bearbetar",
        "readMore": "Läs mer",
        "record": "Spela in ljud",
        "recordingFinished": "Spela upp ljud",
        "removeConfirm": "Är du säker? Detta raderar ljudet som du har spelat in.",
        "removeSoundFile": "Ta om",
        "stop": "Stop"
      },
      "missingContenteditor": "<p class=\"text-secondary\">Något gick fel!</p> Det gick inte att ladda övningen.",
      "noAnswers": "Inget inlämnat svar finns till uppgiften",
      "numberLine": {
        "hint": "<strong>Tips!</strong> Du har inte fyllt i alla korrekta svar"
      },
      "placeChoices": {
        "hint": "<strong>Tips!</strong> Du har inte bockat för alla svarsalternativ som är rätt.",
        "incompleteMessage": "Välj ett svarsalternativ.",
        "incompleteMessageMultiple": "Välj ett eller flera svarsalternativ."
      },
      "placeImages": {
        "hint": "Tips!",
        "hintMessage": "Det finns bilder kvar som du behöver placera ut på rätt ställe.",
        "imagesEmpty": "Inga fler bilder att placera ut",
        "triedDrag": "Tryck på en punkt i bilden för att placera bilden."
      },
      "placeWords": {
        "hint": "Tips!",
        "hintMessage": "Det finns ord kvar som du behöver placera ut på rätt ställe.",
        "triedDrag": "Tryck på en punkt i bilden för att placera ordet.",
        "wordsEmpty": "Inga fler ord att placera ut"
      },
      "reply": {
        "hint": "Tips!"
      },
      "reset": {
        "confirm": "Är du säker på att du vill rensa ditt svar?"
      },
      "showDescription": "Visa beskrivning",
      "sort": {
        "hint": "<strong>Tips!</strong> Det finns svarsalternativ kvar som du behöver dra till rätt ställe."
      },
      "status": {
        "audited": "Granskad",
        "correct": "Rätt",
        "editing": "Redigerar …",
        "incomplete": "Svar saknas",
        "notStarted": "Inte påbörjad",
        "saving": "Sparar …",
        "started": "Påbörjad",
        "teacherCorrect": "Mål uppnått",
        "teacherWrong": "Mål ej uppnått",
        "turnedIn": "Inlämnad",
        "wrong": "Fel"
      },
      "textGap": {
        "incompleteMessage": "Svar saknas."
      },
      "textGapDragEmpty": "Inga fler ord att dra.",
      "version": {
        "current": "Aktuell version",
        "header": "Versioner",
        "label": "Version {version}"
      }
    },
    "externalLinkModal": {
      "cancelButtonLabel": "Avbryt",
      "continueButtonLabel": "Fortsätt",
      "message": "Alla länkar är noga utvalda och kvalitetsgranskade av våra författare. De kontrolleras kontinuerligt, men vi kan inte ansvara för de eventuella förändringar eller fel som över tid uppstår på andra hemsidor. Hjälp oss gärna genom att rapportera eventuella problem till oss genom vårt <a class=\"ui-link ui-link--link--default\" href=\"{contactUrl}\" target=\"_blank\">kontaktformulär</a>.",
      "title": "Du lämnar nu Natur & Kulturs plattform"
    },
    "filePicker": {
      "attach": "Bifoga"
    },
    "files": {
      "alerts": {
        "noFiles": "<b>Du har inga filer än.</b> Här kommer du hitta alla bilder, filmer, ljudfiler och dokument som du har bifogat till olika uppgifter i dina produkter eller inlägg i kurser. Du kan också ladda upp nya filer här. Du kan återanvända filer och bifoga dem till flera uppgifter i dina produkter."
      },
      "breadcrumb": {
        "edit": "Redigera",
        "files": "Filer",
        "new": "Ny"
      },
      "confirmDelete": "Filen kommer tas bort.",
      "details": {
        "aboutFile": "Om filen",
        "deleteFile": "Radera",
        "download": "Ladda ner",
        "uploaded": "Uppladdad:"
      },
      "filesForm": {
        "cancel": "Avbryt",
        "delete": "Ta bort",
        "save": "Spara",
        "titlePlaceholder": "Namn på fil …"
      },
      "filter": "Filtrera",
      "filterOnFiletype": "Filtrera på typ",
      "filterOptions": {
        "all": "Alla"
      },
      "filterTypeOptions": {
        "all": "Alla",
        "audios": "Ljudfiler",
        "documents": "Dokument",
        "images": "Bilder",
        "videos": "Video"
      },
      "heading": "Filer",
      "importFromDevice": "Importera från enhet",
      "newFile": "Ladda upp ny",
      "preview": "Förhandsvisning",
      "recordVideo": "Spela in video",
      "save": "Spara",
      "search": "Sök",
      "searchOptions": "Sökalternativ",
      "searchPlaceholder": "Sök filer …",
      "sort": "Sortera",
      "sortOptions": {
        "fileNameAZ": "Filnamn A-Ö",
        "fileNameZA": "Filnamn Ö-A",
        "latest": "Senaste",
        "oldest": "Äldsta"
      },
      "takePicture": "Ta foto",
      "titleLabel": "Titel",
      "type": {
        "audio": "Ljudfil",
        "document": "Dokument",
        "file": "Fil",
        "image": "Bild",
        "video": "Video"
      }
    },
    "forbidden": {
      "content": "Efter lite grävande har vi upptäckt att ditt konto saknar rättigheter för denna sida.",
      "cta": "Gå till startsidan",
      "heading": "Rättigheter saknas"
    },
    "gallery": {
      "fullscreen": "Förstora bild",
      "indexLabel": "Bild",
      "indexOf": "av",
      "next": "Nästa bild",
      "prev": "Föregående bild"
    },
    "globalHeader": {
      "content": "Innehåll",
      "contentTitle": "Innehållets startsida",
      "courseTitle": "Kursens startsida",
      "help": "Support",
      "leaveTodo": "Avsluta uppdrag",
      "logoTitle": "Gå till startsidan",
      "nav": {
        "ariaButtonLabel": "Öppna innehållsmenyn",
        "button": "Meny",
        "fallbackTitle": "Innehåll",
        "tooltip": "Innehållsmeny"
      },
      "productTitle": "Produktens startsida",
      "returnButton": "Du följde en länk i innehållet. Här kan du ta dig tillbaka.",
      "search": "Sök",
      "todoTitle": "Uppdragets startsida",
      "usersNav": {
        "ariaButtonLabel": "Öppna deltagarmenyn",
        "students": "Elever",
        "teachers": "Lärare",
        "title": "Deltagare",
        "tooltip": "Deltagarmeny"
      }
    },
    "globalMenu": {
      "account": "Mitt konto",
      "appearance": "Utseende",
      "myDigilar": "Mitt konto",
      "notifications": "Notiser",
      "profile": "Konto",
      "settings": "Inställningar"
    },
    "globalSearch": {
      "charsRequiredInfo": "För att söka behöver du skriva minst tre tecken",
      "noHits": "Inga resultat.",
      "results": "{count, plural, =0 {Inga träffar} =1 {En träff} other {# träffar}}",
      "searchInput": "Skriv din sökfråga här",
      "searchSubmit": "Sök",
      "searching": "Söker …"
    },
    "globalShareMeta": {
      "information": "Information"
    },
    "help": "Hjälp",
    "helpers": {
      "licenseTime": {
        "days": "{value, plural, =0 {0 dagar} =1 {1 dag} other {# dagar}}",
        "months": "{value, plural, =0 {0 månader} =1 {1 månad} other {# månader}}",
        "years": "{value} år"
      }
    },
    "hide": "Dölj",
    "homescreen": "NOKportalen",
    "index": "Innehåll",
    "interactives": {
      "exercises": "uppgifter",
      "exercisesCount": "{count}"
    },
    "labels": {
      "archive": "Arkiv",
      "area": "Arbetsområde",
      "assignment": "Arbetsuppgift",
      "book": "Bok",
      "chapter": "Kapitel",
      "collection": "Uppgiftsgrupp",
      "content": "Innehåll",
      "exercise": "Övning",
      "folder": "Mapp",
      "interactives": "Uppgifter",
      "section": "Avsnitt",
      "story": "Bildberättelse",
      "study": "Instudering",
      "survey": "Reflektion",
      "workflow": "Arbetsflöde"
    },
    "landingPage": {
      "search": {
        "noResults": "Du har ingen produkt eller kurs som matchar din sökning.",
        "placeholder": "Sök kurs eller produkt"
      }
    },
    "language-select": {
      "language": {
        "en": "English",
        "noSelectedLanguage": "Användarens inställning",
        "sv": "Svenska"
      }
    },
    "languageCodes": {
      "Danska": "Danska",
      "da_DK": "Danska",
      "de_DE": "Tyska",
      "en_GB": "Engelska",
      "es_ES": "Spanska",
      "fr_FR": "Franska",
      "it_IT": "Italienska",
      "nn_NO": "Norska",
      "sv_SE": "Svenska"
    },
    "link": {
      "cancel": "Avbryt",
      "continue": "Fortsätt",
      "default": "Länk",
      "description": "Alla länkar är noga utvalda och kvalitetsgranskade av våra författare. De kontrolleras kontinuerligt, men vi kan inte ansvara för de eventuella förändringar eller fel som över tid uppstår på andra hemsidor. Hjälp oss gärna genom att rapportera eventuella problem till oss genom vårt <a class=\"ui-link ui-link--link--default\" href=\"{contactUrl}\" target=\"_blank\">kontaktformulär</a>.",
      "title": "Du lämnar nu Natur & Kulturs plattform"
    },
    "listen": "Lyssna",
    "listenToo": "Jag vill lyssna också",
    "loading": "Laddar …",
    "locationCopiedToClipboard": "Länken lades till i urklipp",
    "locationFailedToCopyToClipboard": "Länken kunde inte läggas till i urklipp",
    "login": {
      "browserTestLink": "Testa din webbläsare",
      "connect": {
        "errors": {
          "ECONNREFUSED": "Uppkoppling saknas.",
          "invalid_grant": "Det finns inte någon användare med det angivna användarnamnet och lösenordet.",
          "unknown_error": "Något gick fel med kopplingen. Försök igen eller kontakta kundsupport."
        },
        "heading": "Länka konto",
        "message": "Du är inloggad på {service, select, NoK {Natur & Kultur} other {{service}} }. Logga nu in med ditt användarnamn och lösenord i plattformen för att länka samman dina konton.",
        "submit": "Länka konton"
      },
      "createAccount": "Skapa konto",
      "createAccountButton": "Skapa konto",
      "createAccountText": "Med ett konto kan du aktivera och använda våra produkter. Om du är lärare kan du dessutom prova våra produkter helt kostnadsfritt i 30 dagar.",
      "errors": {
        "ECONNREFUSED": "Tjänsten går inte att nå. Vänta en stund och försök igen.",
        "ECONNRESET": "Tjänsten går inte att nå. Vänta en stund och försök igen.",
        "ENOTFOUND": "Tjänsten går inte att nå. Vänta en stund och försök igen.",
        "facebook": "Det valda Facebook-kontot är inte länkat till något konto i vår plattform.",
        "google": "Det valda Google-kontot är inte länkat till något konto i vår plattform.",
        "invalid_grant": "Det finns inte någon användare med det angivna användarnamnet och lösenordet.",
        "lti_create_user_failed": "Felkod LTI004. Användare kunde inte skapas.",
        "lti_no_email": "Felkod LTI006. Din lärplattform skickade ingen information om din e-post. Kontrollera att din e-postadress är korrekt och verifierad i din lärplattform.",
        "lti_no_email_found": "Felkod LTI003. Inget användarnamn skickades.",
        "lti_no_school_found": "Felkod LTI002. Ingen ansluten skola hittades.",
        "lti_token_error": "Felkod LTI001. Ogiltigt konto.",
        "lti_validate_error": "Felkod LTI005.",
        "microsoft": "Det valda Microsoft-kontot är inte länkat till något konto i vår plattform.",
        "no_password": "Lösenord saknas",
        "no_username": "Användarnamn saknas",
        "nok": "Det valda Natur & Kultur-kontot är inte länkat till något konto i vår plattform.",
        "skolfed_no_school": "Din skola saknar skolenhetskod. Kontakta kundsupport och uppge felkod: L001.",
        "skolon_accessed_refused": "Felkod Skolon009. Du nekade åtkomst.",
        "skolon_could_not_create_user": "Felkod Skolon007. Kunde inte skapa ett konto.",
        "skolon_multiple_accounts": "Felkod Skolon008. Ogiltigt konto.",
        "skolon_multiple_schools": "Felkod Skolon004. Kunde inte hitta din skola.",
        "skolon_no_access_token": "Felkod Skolon001. Kunde inte logga in genom Skolon. Vänta en stund och försök igen.",
        "skolon_no_email": "Felkod Skolon006. Kunde inte logga in genom Skolon. Vänta en stund och försök igen.",
        "skolon_no_profile": "Felkod Skolon005. Kunde inte logga in genom Skolon. Vänta en stund och försök igen.",
        "skolon_no_school": "Felkod Skolon002. Skolon skickade ingen information om din skola.",
        "skolon_no_system_school": "Felkod Skolon003. Kunde inte hitta din skola.",
        "user_login": "Det finns ett problem med inloggningsmetoderna på ditt användarkonto. Kontakta kundsupport och uppge ditt användarnamn."
      },
      "forgotPassword": "Glömt lösenordet?",
      "header": "Logga in",
      "login": "Logga in",
      "loginWith": "Eller logga in med",
      "password": "Lösenord",
      "passwordChanged": "Ditt lösenord är ändrat. Logga in igen med ditt nya lösenord.",
      "passwordPlaceholder": "Ange ditt lösenord",
      "serviceOwner": "En tjänst från Natur och kultur",
      "support": "Kundsupport",
      "testMessage": "Det här är gränssnittet för digiNOK-plattformens testmiljö. Innehållet här är en kopia och det kan finnas ändringar/saker som saknas. Om du vill se det som syns för kund – gå till vår skarpa miljö: ",
      "testMessageLink": "app.digilar.se",
      "username": "Användarnamn",
      "usernamePlaceholder": "namn@epost.se",
      "withFacebook": "Logga in med Facebook",
      "withGoogle": "Logga in med Google",
      "withMicrosoft": "Logga in med Microsoft",
      "withNok": "Logga in med NOK-konto",
      "withSkolfederation": "Logga in med Skolfederation",
      "withSkolon": "Logga in med Skolon"
    },
    "mathHelp": {
      "content": "<p>Så här skriver du matematik med tangentbordet:<br> <b>Multiplikation:</b> Skriv tecknet *<br> <b>Division och bråk:</b> Skriv tecknet /<br> <b>Bråk i blandad form:</b> Skriv mellanrum efter heltalet 3 2/5<br> <b>Tal och matematiska tecken:</b> Skriv utan mellanrum 3+5=8 <i>eller</i> 4*3 <i>eller</i> 6x</p>",
      "title": "Matematik med tangentbordet"
    },
    "mediaPlaceholder": {
      "showMedia": "Visa media",
      "toolTip": "Du har valt anpassningen Endast text"
    },
    "minutesShort": "min",
    "modals": {
      "tooltips": {
        "close": "Stäng"
      }
    },
    "modelRejection": {
      "noInternetConnection": "Innehållet är inte tillgängligt offline. Anslut till internet och försök igen.",
      "other": "Innehållet kunde inte hämtas. Försök igen om en liten stund."
    },
    "models": {
      "material": {
        "typeLabel": {
          "articles": "Artikel",
          "attachments": "Fil",
          "audios": "Ljudfil",
          "embeds": "Inbäddat material",
          "guidances": "Lärarhandledning",
          "images": "Bild",
          "interactiveImages": "Interaktiv bild",
          "links": "Extern länk",
          "videos": "Film",
          "vrImages": "360-bild",
          "words": "Ord"
        }
      },
      "validations": {
        "presence": "Fältet kan inte vara tomt."
      }
    },
    "months": "Januari,Februari,Mars,April,Maj,Juni,Juli,Augusti,September,Oktober,November,December",
    "myProfile": "Mitt konto",
    "nameForm": {
      "description": "Fyll i ditt namn.",
      "firstName": "Förnamn",
      "header": "Ditt namn",
      "lastName": "Efternamn"
    },
    "nameMissing": "Namn saknas",
    "nextMonth": "Nästa månad",
    "notFound": {
      "activateLicenseContent": "Aktiveringskoden du har angett finns inte. Kontrollera att du skrivit rätt. Om problemet kvarstår kontakta vår support på kundsupport@nok.se",
      "activateLicenseHeading": "Aktiveringskoden finns inte",
      "content": "Länken du försöker nå finns inte hos oss. Detta kan bero på att sidan är borttagen eller att du har följt en länk som är felaktig.",
      "cta": "Gå till startsidan",
      "gotoLogin": "Logga in",
      "heading": "Sidan du letar efter finns inte"
    },
    "notes": {
      "alerts": {
        "noNotes": "<b>Du har inga anteckningar än.</b> Här kommer du hitta alla anteckningar som du har gjort i dina produkter. Du kan också göra nya anteckningar här. Om du vill kan du lägga till samma anteckning till flera ställen i dina produkter.",
        "noNotesInContent": "Du har inga aktuella anteckningar till detta innehåll."
      },
      "breadcrumb": {
        "edit": "Redigera",
        "new": "Ny",
        "notes": "Anteckningar"
      },
      "categories": {
        "relatedNotes": "Relaterade anteckningar"
      },
      "filter": "Filtrera",
      "filterOptions": {
        "all": "Alla"
      },
      "heading": "Anteckningar",
      "importFromArchive": "Från mitt arkiv",
      "importModal": {
        "close": "Stäng",
        "import": "Välj"
      },
      "newNote": "Ny anteckning",
      "notesForm": {
        "cancel": "Avbryt",
        "confirmDelete": "Anteckningen kommer att tas bort.",
        "contentLabel": "Innehåll",
        "delete": "Ta bort",
        "heading": "Anteckning",
        "removeFromWorkspace": "Koppla bort",
        "save": "Spara",
        "titleLabel": "Titel",
        "titlePlaceholder": "Ange titel på anteckning"
      },
      "search": "Sök",
      "searchPlaceholder": "Sök anteckningar …"
    },
    "notesForm": {
      "titleInputPlaceholder": "Anteckningens namn …"
    },
    "notice": {
      "later": "Visa senare",
      "noThanks": "Avfärda"
    },
    "onboarding": {
      "done": "Klar",
      "next": "Nästa"
    },
    "overview": {
      "aboutProduct": "Om produkten",
      "activeSchoolApplication": {
        "cancelApplication": "Avbryt registrering",
        "heading": "Vi granskar din ansökan till skolan {schoolName}",
        "info": "Din ansökan om att koppla dig som lärare till skolan är nu under granskning. Vårt supportteam arbetar för att slutföra kopplingen så snart som möjligt. Detta kan ta några dagar beroende på när du ansökte.",
        "infoTest": "Du måste ha ett lärarkonto som är kopplat till skolan för att du ska kunna testa med dina elever. Vårt supportteam arbetar med att koppla ditt konto till skolan. Det kan dröja några dagar beroende på när du registrerade dig. Under tiden kan du prova produkterna själv.",
        "infoTestTeacher": "Ditt konto behöver kopplas till din skola för att du ska kunna testa med dina elever. Vårt supportteam arbetar med att sätta upp er skola i vårt system. Det kan dröja upp till 24 timmar beroende på när du ansökte om att prova med dina elever. Under tiden kan du prova materialet som vanligt."
      },
      "archiveCourse": "Arkivera kursen",
      "books": {
        "dontShow": "Visa inte igen",
        "private": {
          "heading": "Du har inga digitala produkter",
          "info": "För att använda våra digitala produkter behöver du först ha köpt en licens för någon av våra produkter. Du kan besöka <a target=\"_blank\" class=\"ui-link ui-link--default\" href=\"https://nok.se\">nok.se</a> för att utforska alla våra produkter. När du har genomfört ett köp av en digital produkt samlas dessa här på din startsida. Extramaterial som är gratis går alltid att lägga till."
        },
        "teacher": {
          "heading": "Börja med att lägga till en produkt",
          "info": "Lägg till extramaterial eller produkter som din skola har köpt. Du kan också prova produkter kostnadsfritt i 30 dagar. När du har lagt till en produkt till dig själv kan du hantera elevernas tillgång till produkten direkt här på startsidan eller genom att skapa en kurs.",
          "skolonHeading": "Dina licenser hanteras hos Skolon",
          "skolonInfo": "All hantering av licenser för NOKportalens läromedel och produkter sker genom Skolon. Kontakta din skoladministratör på Skolon för att förnya, lägga till eller ändra licenser. Hos Skolon kan du även lägga till extramaterial och prova gratis."
        }
      },
      "cancelSchoolApplication": "Om du inte längre vill koppla ditt konto till skolan klicka på knappen nedan. Ditt konto kommer finnas kvar men dina testlicenser förvinner eftersom endast lärare kan testa.",
      "cancelSchoolApplicationConfirm": "Jag är inte lärare på skolan",
      "cancelSchoolApplicationConfirmTitle": "Är du lärare på skolan?",
      "chooseBook": "Välj en bok",
      "chooseCourse": "Välj en kurs att arbeta med",
      "confirmRemoveFromHomescreenTitle": "Hej",
      "confirmRemoveTrial": "Du kommer inte kunna testa denna produkt igen, förrän om ett år",
      "course": {
        "info": "<b>Du som är lärare behöver skapa kurser.</b> Kursen kopplar samman elever och lärare med våra digitala produkter – och ger dig en enklare hantering av produktlicenser. Beroende på vilka produkter du lägger till kan kursen även ge dig möjligheter att skapa en helt egen planering, kommunicera med eleverna och följa deras framsteg.",
        "infoButton": "Läs vår guide för kurser"
      },
      "demo": "Testlicens",
      "demoLicenseExpired": "Testlicens förbrukad",
      "demoLicenseExpiresToday": "Testlicens: {hours} {hours, plural, =1 {timme} other {timmar}} kvar",
      "demoLicenseShort": "Testlicens: {days} {days, plural, =1 {dag} other {dagar}} kvar",
      "expiredLicense": "Licensen gick ut {date}",
      "extramaterial": "Extramaterial",
      "hello": "Hej {name}!",
      "inactiveSchoolApplication": {
        "background": "Du har ansökt om att bli ansluten till en skola för att komma åt gemensamma resurser eller prova digitala läromedel.",
        "created": "Ansökan gjordes",
        "heading": "Vi kunde inte koppla ditt konto till skolan {schoolName}",
        "hide": "Dölj meddelandet",
        "info": "Vi kunde inte verifiera att du jobbar på den skola du har angett. Vänligen kontakta oss på kundsupport så hjälper vi dig."
      },
      "license": {
        "buy": "Köp",
        "renew": "Förnya"
      },
      "licenseWillExpire": "Licensen går ut om {days} dagar",
      "licenseWillExpireToday": "Licensen går ut idag",
      "myCourses": "Mina kurser",
      "noSchool": {
        "connectToSchool": "Anslut",
        "heading": "Vill du ansluta till en skola?",
        "info": "Anslut ditt konto till din skola för att få tillgång till skolans licenser. Det går även bra att använda NOKportalen utan att ansluta till en skola.",
        "noThanks": "Använd utan skola",
        "yourUsername": "Ditt användarnamn: "
      },
      "onlyShowing": "Just nu visas bara ",
      "openCourseSettings": "Administrera kurs",
      "openLicenseModal": "{onlyShow, select, true {Visa licenser} other {Hantera licenser}}",
      "removeFromHomescreen": "Ta bort",
      "removeFromHomescreenSuccess": "Licensen kan nu återanvändas av någon annan på skolan.",
      "schoolApplication": {
        "private": "Du måste vara lärare och ansluten till en skola för att prova produkter gratis.",
        "student": "Du måste vara lärare och ansluten till en skola för att prova produkter gratis."
      },
      "showAllProducts": "Visa alla produkter",
      "students": {
        "heading": "Din skola har inga elever",
        "info": "För att komma igång behöver ni lägga till skolans elever via administrationen. Skolan kan även få hjälp med detta av vår kundsupport. Tryck på hjälpsymbolen för att få kontakt med kundsupporten.",
        "infoButton": "Läs mer",
        "mainButton": "Gå till administration"
      },
      "subscription": {
        "cancel": "Avsluta abonnemanget",
        "cancelModal": {
          "cancelFailed": "Ett fel uppstod när vi försökte avbryta dit abonnemang.",
          "cancelSuccess": "Ditt abonnemang har avslutats.",
          "okButton": "Avsluta abonnemanget",
          "text": "Om du avslutar abonnemanget kan du använda produkten till och med {date}. Du kan återuppta abonnemanget senare om du vill.",
          "title": "Vill du avsluta abonnemanget?"
        },
        "ended": "Abonnemanget avslutades {date}",
        "isCancelled": "Abonnemanget avslutas {date}",
        "pendingPayment": "Abonnemanget avslutas {date} pga misslyckad betalning",
        "pendingPaymentDescription": "Abonnemanget kunde inte förnyas<br />och därför avslutas det {date}",
        "renew": "Förnya abonnemanget",
        "willBeRenewed": "Abonnemanget förnyas {date}"
      }
    },
    "pageTitle": {
      "activateOrder": "Lägg till licenser - NOKportalen",
      "admin": {
        "books": "Produkter",
        "courses": "Kurser",
        "licenses": "Licenser",
        "students": "Elever",
        "teachers": "Lärare"
      },
      "browserTest": "Webbläsartest",
      "choosePassword": "Välj lösenord",
      "courses": {
        "audit": {
          "comments": "Kommentarer",
          "inbox": "Nytt",
          "index": "Uppföljning",
          "log": "Elever",
          "overview": "Allt",
          "todos": "Uppdrag"
        },
        "books": "Innehåll",
        "myResults": "Mina resultat",
        "overview": "Översikt",
        "posts": "Inlägg",
        "settings": "Administrera kurs",
        "todo": "Uppdrag"
      },
      "createAccount": "Skapa konto",
      "error": "Fel",
      "forgotPassword": "Glömt lösenordet?",
      "landingPage": "Logga in till dina digitala läromedel från Natur & Kultur",
      "login": "Logga in",
      "newCourse": "Skapa kurs",
      "notFound": "Sidan kunde inte hittas",
      "onboarding": "Onboarding",
      "profile": {
        "overview": "Start",
        "results": "Logg",
        "settings": "Inställningar",
        "workspace": "Arkiv"
      },
      "repetition": "Repetition"
    },
    "pagination": {
      "firstPage": "Första sidan",
      "lastPage": "Sista sidan",
      "nextPage": "Nästa sida",
      "prevPage": "Föregående sida"
    },
    "preview": {
      "previewing": "Förhandsgranskning"
    },
    "previousMonth": "Föregående månad",
    "products": {
      "activate": "Aktivera",
      "addBooks": "Lägg till produkter",
      "allStages": "Alla stadier",
      "authors": "Författare:",
      "buyMoreLicenses": "Köp fler licenser",
      "containsBooks": "Innehåller titlarna:",
      "demoLicenseUntil": "Testlicens till {date}",
      "done": "Klar",
      "downloadToExcel": "Ladda ner excellista med skolans licenser (exkl extramaterial)",
      "menu": {
        "pages": "Produktsidor"
      },
      "noAvailableLicenses": "Det finns inga lediga licenser",
      "noResults": "Inga träffar",
      "personalLicenseUntil": "Personlig licens till {date}",
      "schoolBooks": "Din skolas produkter",
      "schoolLicenseUntil": "Skollicens till {date}",
      "search": "Sök",
      "searchPlaceholder": "Sök …",
      "testBooks": "Prova gratis",
      "try": "Prova gratis"
    },
    "profile": {
      "avatar": "Profilbild",
      "changeLanguage": "Välj språk",
      "connectToSchool": "Anslut till skola",
      "default": "Konto",
      "email": "E-postadress",
      "emailPlaceholder": "namn@epost.se",
      "errors": {
        "accountAlreadyConnected": "Kontot du försökte länka är redan kopplat till ett annat konto i plattformen.",
        "invalidEmail": "Ogiltig e-post-adress"
      },
      "firstName": "Förnamn",
      "lastName": "Efternamn",
      "passwordHint": "Ditt lösenord måste innehålla minst 8 tecken.",
      "schools": "Skolor",
      "settings": "Inställningar",
      "settingsPage": {
        "checkBrowser": "Kontrollera din webbläsare",
        "header": "Mina inställningar",
        "integrityPolicy": "Integritetspolicy",
        "licenseAgreement": "Licensavtal",
        "logoutFromSessionsButton": "Logga ut från alla andra enheter",
        "sso": {
          "connected": {
            "Facebook": "Länkning lyckades. Från och med nu kan du välja att logga in med ditt Facebook-konto.",
            "Google": "Länkning lyckades. Från och med nu kan du välja att logga in med ditt Google-konto.",
            "Microsoft": "Länkning lyckades. Från och med nu kan du välja att logga in med ditt Microsoft-konto."
          },
          "disconnect": "Ta bort",
          "disconnectAlertMessage": "Du kommer inte längre kunna logga in med tjänsten. Vill du fortsätta?",
          "heading": "Länkade konton för inloggning",
          "information": "Om du länkar någon av dessa tjänster kan du använda dess inloggningsuppgifter för att logga in. Du väljer att logga in med tjänsten på vår inloggningssida.",
          "link": {
            "facebook": "Länka ditt Facebook-konto",
            "google": "Länka ditt Google-konto",
            "microsoft": "Länka ditt Microsoft-konto"
          }
        },
        "userTerms": "Användarvillkor"
      },
      "update": "Uppdatera",
      "uploadImage": {
        "default": "Ladda upp profilbild"
      },
      "username": "Användarnamn"
    },
    "profileMenu": {
      "nokse": "Till nok.se",
      "overview": "Start",
      "settings": "Inställningar",
      "workspace": "Arkiv",
      "workspaceMenu": {
        "assignments": "Läraruppgifter",
        "files": "Filer",
        "notes": "Anteckningar",
        "wordlists": "Ordlistor"
      }
    },
    "readInstead": "Jag vill bara läsa",
    "resetPassword": {
      "cancel": "Avbryt",
      "description": "Om du har glömt ditt lösenord kan du ange din e-postadress för att få en återställningslänk skickad till din e-post.",
      "done": {
        "header": "Återställningslänk skickad",
        "text": "En återställningslänk har skickats till din e‑postadress. Tryck på länken i meddelandet för att välja ett nytt lösenord. Om meddelandet inte dyker upp inom fem minuter så kan det ha hamnat i skräpposten."
      },
      "email": "Din e-postadress",
      "emailPlaceholder": "namn@epost.se",
      "header": "Glömt lösenordet?",
      "mailNotFound": "Det finns inte någon användare med det angivna användarnamnet. Kontrollera att du har fyllt i en giltig e-postadress (i formatet namn@domän.se).",
      "resetButton": "Skicka"
    },
    "results": {
      "entityFailed": "Svaret kan inte visas eftersom du inte längre har tillgång till uppgiften.",
      "entityMissing": "Svaret kan inte visas eftersom uppgiften inte längre är tillgänglig.",
      "fetch": "Hämtar …",
      "fullscreen": "Fullskärmsläge",
      "myResults": "Min resultatlogg",
      "numStudentsAnswered": "{num} svar",
      "types": {
        "diagnosis": "Diagnoser",
        "standard": "Uppgifter",
        "teacher_assignments": "Läraruppgifter"
      }
    },
    "routes": {
      "activate": {
        "addLicenses": "Lägg till licenser",
        "addLicensesButton": "Lägg till {count, plural, =1 {licens} other {licenser}}",
        "addPrivateOrderToPrivateUser": "Licenserna läggs på ditt <strong>privata</strong> konto, {username}.",
        "addToPrivateUser": "Ditt konto är <strong>privat</strong> och du kan därför inte lägga till dessa licenser. För att lägga licenser på din skola/organisation måste ditt konto först kopplas dit.",
        "addToPrivateUserMultipleLicenses": "Med ett privat konto lägger du endast till en licens per produkt.",
        "addToSchool": "Licenserna läggs på din skola, {school}.",
        "addToUser": "Licenserna läggs på ditt konto, {username}.",
        "amount": "Antal",
        "chooseSchool": "Välj skola att lägga licenserna på.",
        "customerReference": "Referens",
        "date": "Datum",
        "deliveredTitle": {
          "added": "Licenser tillagda",
          "schoolName": "Licenserna tillagda på {schoolName}",
          "skolon": "Licenserna tillagda i Skolon!"
        },
        "error": {
          "message": "Din order kan inte hittas. Vänligen kontrollera aktiveringskoden eller kontakta kundtjänst.",
          "title": "Ordern kan inte hittas"
        },
        "isbn": "ISBN",
        "licenseTime": {
          "expired": "{expired, plural, =1 {Utgången} other {Utgångna}}",
          "heading": "Licenstid",
          "months": "{months} {months, plural, =1 {månad} other {månader}}",
          "oldOrder": "Giltig till {expires}"
        },
        "login": "Logga in",
        "messages": {
          "activated": {
            "added": {
              "code": "Aktiveringskod använd",
              "order": "Licenserna har lagts till"
            },
            "at": "den {date}",
            "by": {
              "client": "genom {client}",
              "unknown": "av någon annan",
              "user": "av {username}"
            },
            "loginDescriptionNokportalen": "För att få veta mer behöver du vara inloggad.",
            "loginNokportalenLink": "Gå till inloggningen",
            "loginSkolon": "Era licenser hanteras i Skolon.",
            "nokportalen": {
              "link": "Gå till startsidan",
              "text": "I NOKportalen hittar du dina produkter på din startsida."
            },
            "onSchool": "på {schoolName}",
            "otherSchool": "Licenserna hör till en skola som ni inte kan administrera.",
            "otherUser": "Licenserna tillhör någon annan.",
            "schoolAdminLicenses": "På skolans administration kan du nu tilldela dig en licens, du kan också tilldela licenser till andra lärare och elever på skolan.",
            "schoolAdminLink": "Gå till skolans administration och hantera licenser"
          },
          "expired": {
            "message": "{numberOfLicenses, plural, =1 {Licensen} other {Licenserna}}  kan inte aktiveras  då {numberOfLicenses, plural, =1 {den} other {de}} köptes för mer än 16 månader sedan.",
            "readMore": "Läs mer här",
            "title": "Ordern är utgången!"
          },
          "privateUser": {
            "message": "Ditt konto måste kopplas till skolan/organisationen som beställt licenserna.",
            "readMore": "Läs mer här",
            "title": "Ordern är kopplad till en skola"
          },
          "student": {
            "message": "Du måste vara lärare på din skola för att kunna aktivera ordern.",
            "title": "Endast lärare kan aktivera ordern"
          },
          "unpublished": {
            "message": "Denna order innehåller produkter som ännu inte är publicerade. Återkom efter publiceringsdatumet.",
            "title": "Ej utgivna produkter"
          }
        },
        "notLoggedInBusinessOrderWarning": "Har du inget konto kan du vidarebefordra leveransmejlet till en kollega som har konto.",
        "notLoggedInWarning": "Du måste vara inloggad för att lägga till licenser.",
        "orderInfo": "Orderinformation",
        "orderIsOld": "Eftersom köpet gjordes för mer än fyra månader sedan startas {numberOfLicenses, plural, =1 {licensen} other {licenserna}} direkt och gäller fram till datumet ovan.",
        "orderIsOldReadMore": "Läs mer",
        "orderReference": "Ordernummer",
        "processFailed": "Licenserna kunde inte läggas till.",
        "product": "Produkt",
        "readMoreLink": "Läs mer här",
        "school": "Skola",
        "schoolIsConnectedToSkolon": "Denna skola är ansluten till Skolon och därför kommer dessa licenser att läggas in där när du trycker på knappen nedan.",
        "sendLicensesToSkolonButton": "Lägg {count, plural, =1 {licens} other {licenser}} i Skolon",
        "unpublishedProduct": "Denna produkt publiceras den {date} {month}, återkom därefter för att lägga till dessa licenser."
      },
      "addProducts": {
        "activateButtonLabel": "Lägg till",
        "activatedTextLabel": "Tilldelad",
        "activationCode": "Aktiveringskod",
        "addedLicense": "Produkten tillagd",
        "buyMoreAlert": {
          "admin": {
            "heading": "Vill du köpa fler produktlicenser till skolan?",
            "paragraph": "Vill du köpa fler licenser till någon produkt eller nya produkter som din skola ännu inte använder? Besök din återförsäljare (Läromedia, GR eller Skolon) eller nok.se. Du kan alltid prova en produkt gratis i 30 dagar via <em>Lägg till produkt</em> på startsidan. <a href=\"https://support.nok.se/article/4097\" class=\"text-underline\">Läs här om hur du provar våra produkter</a>"
          },
          "general": {
            "heading": "Vill du köpa fler produkter till skolan?",
            "paragraph": "Vill du köpa fler licenser till någon produkt eller nya produkter som din skola ännu inte använder? Besök din återförsäljare (Läromedia, GR eller Skolon) eller nok.se. Du kan alltid prova en produkt gratis i 30 dagar via fliken <em>Prova gratis</em>. <a href=\"https://support.nok.se/article/4097\" class=\"text-underline\">Läs här om hur du provar våra produkter</a>"
          },
          "student": {
            "heading": "Saknar du någon produkt?",
            "paragraph": "Om någon produkt saknas kan det vara så att din lärare måste dela ut en licens till dig."
          }
        },
        "daysLeftOnTextLabel": "Licensen går ut om {days} dagar",
        "daysLeftOnTrialTextLabel": "Testlicens går ut {date}",
        "demoLicenseOnTextLabel": "Testlicensen går ut om {days} dagar",
        "done": "Klar",
        "expiredTextLabel": "Licensen gick ut {date}",
        "extendButtonLabel": "Förläng",
        "extramaterialTextLabel": "Extramaterial",
        "extramaterialTooltip": "Gratis extramaterial",
        "freeTextLabel": "Prova gratis",
        "freeTooltip": "Prova gratis i 30 dagar",
        "freeTooltipUntil": "Prova gratis till {date}",
        "handledExternally": "Skolans licenser hanteras i Skolon",
        "handledInUnknownSystem": "annat system",
        "navItems": {
          "products": "Produkter",
          "tryForFree": "Prova gratis"
        },
        "noLicenses": "Inga licenser",
        "noResultAlertMessage": "Inga produkter hittades.",
        "numberOfLicensesText": "{numberOfUsesLeft, plural, one {1 licens} other {# licenser} }",
        "numberOfLicensesTooltip": "Antalet licenser att tilldela",
        "purchase": "Köp",
        "readMoreLinkLabel": "Läs mer om produkten",
        "searchButtonLabel": "Sök",
        "searchInputPlaceholder": "Sök produkt eller extramaterial",
        "showExtraMaterial": "Visa extramaterial",
        "stageSelectPlaceholder": "Alla åldrar",
        "subjectSelectPlaceholder": "Alla kategorier",
        "title": "Lägg till produkt eller extramaterial",
        "trialEndedText": "Testlicens förbrukad",
        "tryButtonLabel": "Lägg till",
        "unabledToCreateLicense": "Något gick fel när vi försökte lägga till produkten. Kontakta support.",
        "unlimitedTextLabel": "Obegränsad",
        "unlimitedTooltip": "Obegränsat antal licenser att tilldela",
        "usedTrialLicense": "Testlicens ej tillgänglig då du redan provat produkten.",
        "waitingForExtension": "Licensen väntar på förlängning"
      },
      "admin": {
        "books": {
          "extraMaterials": "Extramaterial",
          "giveMeLicense": "Tilldela mig licens",
          "licenseTime": "Licenser är giltiga i {licenseTime} efter start",
          "licenseTimeUntil": "Licenser är giltiga till {licenseTime}",
          "manageLicenses": "Hantera licenser",
          "noProducts": "Hittade inga produkter",
          "showLicenses": "Visa licenser",
          "showingProductsFromOrder": "Visar produkter från order ({date})"
        },
        "menu": {
          "productList": {
            "description": "Ladda ner excellista med skolans licenser (exklusive extramaterial).",
            "title": "Ladda ner produktlista"
          },
          "removeUsers": {
            "description": "Observera att alla elever och klasser kommer att permanent raderas från skolan.",
            "title": "Radera alla elever och klasser på skolan"
          },
          "userList": {
            "description": "Ladda ner excellista med alla användare på skolan, både lärare och elever.",
            "title": "Ladda ner användarlista"
          }
        },
        "search": {
          "books": "Produktens titel",
          "courses": "Kursens namn",
          "students": "Elev eller klass",
          "teachers": "Lärarens namn"
        }
      },
      "anslut": {
        "actions": {
          "cancel": "Avbryt",
          "connect": "Ansök"
        },
        "application": {
          "locationPlaceholder": "Ort",
          "manualTitle": "Lägg till skolan manuellt",
          "schoolPlaceholder": "Skolans namn",
          "search": "Sök efter skolans namn, ort eller skolenhetskod",
          "searchPlaceholder": "Sök...",
          "title": "Anslut till skola som lärare",
          "validation": "Du måste fylla i uppgifter om din skola"
        },
        "connectAs": {
          "applicationDescription": "Välj din skola och skicka in en ansökan. Supportteamet på Natur & Kultur granskar din ansökan manuellt och verifierar att du är lärare på skolan med hjälp av din mejladress.",
          "applicationDisabled": "Tillfälligt avstängt",
          "applicationEmailError": "Vi kan inte verifiera att du är lärare med din e-postadress: {email}",
          "applicationTitle": "Lärare: skicka en ansökan",
          "codeDescription": "Om du har fått en skolkod, kan du använda den för att ansluta dig till skolan. Lärare på skolan behöver aktivera denna funktion under administration.",
          "codeTitle": "Elev eller lärare: använd skolkod"
        },
        "connectUsing": "Välj hur du vill ansluta:",
        "info": "<b>Tips:</b> Du kan be anslutna lärare att lägga till <b>{email}</b> på skolan under skolans administration.",
        "title": "Anslut till skola"
      },
      "connectToSchool": {
        "actions": {
          "cancel": "Avbryt",
          "connect": "Anslut"
        },
        "alreadyConnected": "Du är redan ansluten till denna skola.",
        "cancel": "Nej, anslut mig inte",
        "codeQuestion": "Har din skola skolkod?",
        "connect": "Ja, anslut",
        "description": "Om du har fått en skolkod, kan du använda den för att ansluta dig till skolan. Lärare på skolan behöver aktivera denna funktion under administration.",
        "enterCode": "Ange skolkod (6 bokstäver eller siffror)",
        "expiredCodeStudent": "Koden är inte längre aktiv. Be din lärare om en ny.",
        "expiredCodeTeacher": "Koden är inte längre aktiv. Be din kollega om en ny.",
        "instruction": "Be en {role, select, teacher {kollega} other {lärare} } att lägga till <b>{email}</b> på skolan under skolans administration eller kontakta vår kundsupport för hjälp.",
        "invalidCode": "Skolkoden du angav finns inte.",
        "noCode": "Du måste ange en kod.",
        "placeholder": "Exempel på kod: E7J9HA",
        "school": {
          "isTeacher": "Jag är lärare",
          "manualInput": "Fyll i skolans namn, ort och ditt telefonnummer",
          "manualInputPlaceholder": "Skolans namn, ort och ditt telefonnummer",
          "message": "När vi har verifierat att du arbetar på angiven skola kan du använda produkter tillsammans med dina elever.",
          "placeholder": "Sök efter skolans namn, ort eller skolenhetskod",
          "validation": {
            "school": "Du måste fylla i uppgifter om din skola"
          },
          "yourSchool": "Din skola"
        },
        "schoolCode": "Koden ansluter ditt konto som <b>{role}</b> till <b>{school}</b>",
        "schoolDescription": "Vill du ansluta ditt konto <b>{userName}</b><br />som <b>{role}</b> till <b>{schoolName}</b>?",
        "schoolTitle": "Anslut till {schoolName}",
        "student": "elev",
        "studentTeacherCode": "Du är elev och försöker använda en kod som är avsedd för lärare.",
        "teacher": "lärare",
        "teacherStudentCode": "Du är lärare och försöker använda en kod som är avsedd för elever.",
        "title": "Anslut till skola",
        "tooManyAttempts": "Du har gjort för många försök. Du måste vänta en timme.",
        "views": {
          "code": "Ange skolkod",
          "school": "Välj skola"
        },
        "whatCode": "Vad är en skolkod?"
      },
      "testProducts": {
        "close": "Stäng",
        "licenseAlreadyAdded": "Testlicens ej tillgänglig då du redan provat produkten.",
        "licenseCanNotBeAdded": "Testlicens ej tillgänglig.",
        "licenseTime": {
          "endOfDay": "Testlicensen gäller till dagens slut",
          "oneMonth": "Testlicensen gäller i 30 dagar"
        },
        "licenseWillExpire": "Testlicensen går ut om {days} dagar",
        "licenseWillExpireToday": "Testlicensen går ut idag",
        "title": "Prova gratis",
        "userGotLicense": "Du har fått en testlicens för:"
      }
    },
    "section": "Avsnitt",
    "services": {
      "licenseDispatcher": {
        "connect": {
          "snackbar": {
            "error": "Licensen kunde inte aktiverats. Försök igen eller kontakta kundservice om problemet kvarstår.",
            "success": "Licensen har aktiverats."
          }
        },
        "tryProduct": {
          "snackbar": {
            "error": "Provperoiden kunde inte aktiverats. Försök igen eller kontakta kundservice om problemet kvarstår.",
            "success": "Provperoiden har aktiverats"
          }
        }
      }
    },
    "sessions": {
      "browser": "Webbläsare",
      "operatingSystem": "Operativsystem",
      "started": "Startades"
    },
    "settings": {
      "play": {
        "activate": "Aktivera uppläsning",
        "alwaysSpeechSynthesis": "Alltid talsyntes",
        "behavior": "Beteende",
        "marking": "Markering för talsyntes",
        "markings": {
          "none": "Ingen",
          "onlySentences": "Enbart meningar",
          "onlyWords": "Enbart ord",
          "wordsAndSentences": "Ord och meningar"
        },
        "preferActivated": "Alltid aktiverad",
        "showSpeakerInfo": "Visa instruktioner",
        "speechSpeed": {
          "fast": "Snabb",
          "slow": "Långsam",
          "standard": "Förval",
          "verySlow": "Mycket långsam"
        },
        "speechSpeedDisabled": "Inställningen fungerar inte för inläst tal på din enhet, men du kan fortfarande ställa in hastighet för talsyntes.",
        "speed": "Rösthastighet",
        "stickWithText": "Textmedföljning",
        "voice": "Röst för talsyntes"
      },
      "reading": {
        "appearanceBehavior": "Utseende och beteende",
        "behavior": "Beteende",
        "browse": "Bläddra",
        "defaultFont": "Förval",
        "font": "Typsnitt",
        "fontsize": "Textstorlek",
        "fontsizes": {
          "large": "Större",
          "medium": "Förval"
        },
        "lineHeight": "Radavstånd",
        "lineHeights": {
          "large": "Större",
          "medium": "Förval"
        },
        "marking": "Färgmarkeringar i text",
        "markings": {
          "colored": "Helfärgade",
          "discreet": "Diskreta",
          "underlined": "Understrukna"
        },
        "onlyImage": "Endast bild",
        "onlyText": "Endast text",
        "readingRuler": "Läslinjal",
        "scroll": "Skrolla",
        "tools": "Hjälpmedel",
        "translationLink": "Översätt"
      },
      "tab": {
        "presentation": "Presentation",
        "reading": "Uppläsning"
      }
    },
    "shareLinkModal": {
      "title": "Dela"
    },
    "showWholeText": "Visa hela texten",
    "signup": {
      "alreadyLoggedIn": "Du är redan inloggad. Om du är lärare kan du prova alla våra produkter genom att <a href=\"/\" target=\"_blank\" class=\"ui-link\">gå till startsidan</a> och trycka på <em>Lägg till produkt</em>.",
      "cancel": "Avbryt",
      "description": "När du har fyllt i formuläret får du ett mejl med en länk som du följer för att skapa ditt konto.",
      "done": {
        "header": "Kontot skapat",
        "nomail": "Fick du ingen aktiveringslänk?",
        "text": "En aktiveringslänk har skickats till din e‑postadress. Tryck på länken i meddelandet för att fullfölja processen. Om meddelandet inte dyker upp inom fem minuter så kan det ha hamnat i skräpposten.",
        "tryagain": "Försök igen"
      },
      "email": "E-postadress",
      "emailPlaceholder": "Din e-postadress",
      "errors": {
        "accountTypeRequired": "Ingen kontotyp har valts.",
        "emailRequired": "Ingen e-postadress är ifylld.",
        "invalidEmailAddress": "E-postadressen är ogiltig. Fyll i en korrekt e-postadress (i formatet namn@domän.se) och försök igen.",
        "invalidPhoneNumber": "Telefonnumret är inte giltigt.",
        "nameRequired": "Inget namn är ifyllt.",
        "phoneNumberRequired": "Inget telefonnummer är ifyllt.",
        "schoolUnitCodeRequired": "Ingen skola har valts.",
        "usernameExists": "E-postadressen finns redan registrerad hos oss. Avbryt och tryck på ”Glömt lösenordet?” om du har glömt ditt lösenord."
      },
      "header": "Skapa ett konto",
      "municipality": "Kommun",
      "municipalityPlaceholder": "Välj kommun",
      "name": "Namn",
      "namePlaceholder": "Ange namn",
      "phonenumber": "Telefonnummer (Valfritt)",
      "phonenumberPlaceholder": "Ange telefonnummer",
      "private": {
        "accountType": "Privatkonto",
        "accountTypeDescription": "Med ett privatkonto kan du aktivera och använda produkter för privat bruk.",
        "accountTypeInfo": "Ditt konto kan kopplas till din skola som elev eller lärare för att du ska få tillgång till skolans resurser."
      },
      "school": "Skola",
      "schoolPlaceholder": "Välj skola",
      "send": "Skapa konto",
      "terms": "Jag har läst och godkänner <a href=\"https://www.nok.se/juridisk-information/villkor/licensavtal/\" class=\"ui-link ui-link--link--default ui-link--medium\" target=\"_blank\">licensavtalet</a>.",
      "test": {
        "accountType": "Testkonto",
        "accountTypeDescription": "Med ett testkonto för lärare kan du prova våra produkter gratis.",
        "accountTypeInfo": "Ditt konto måste kopplas till din skola för att du ska få tillgång till skolans produkter och lärarhandledning."
      },
      "unknownError": "Kontot kunde inte skapas på grund av ett tillfälligt fel. Försök igen senare.",
      "validationError": "Kontot kunde inte skapas. Kontrollera att du angivit informationen på rätt sätt nedan och försök sedan igen."
    },
    "sourceCopiedToClipboard": "Källan lades till i urklipp",
    "sourceFailedToCopyToClipboard": "Källan kunde inte läggas till i urklipp",
    "spaces": {
      "content": "Innehåll",
      "glossaries": "Ordlistor",
      "workspace": "Uppgifter"
    },
    "speaker": {
      "noAutoplayAction": "Tryck här för att prova igen",
      "noAutoplayContent": "Av någon anledning ville din webbläsare inte starta uppläsningen.",
      "noAutoplayHeading": "Uppläsningen kunde inte startas"
    },
    "speakerInfo": {
      "click": "Starta eller stäng av uppläsningsfunktionen genom att trycka på <b>Lyssna</b> ovanför texten. Tryck på ett textstycke för att starta eller pausa uppläsningen.",
      "clickHeading": "Du kan lyssna på all text",
      "confirm": "Okej, jag förstår",
      "customize": "Du kan få uppläsningen precis som du vill ha den, till exempel ändra hastighet och röst. Tryck på knappen <b>Anpassa</b> längst upp om du vill göra särskilda inställningar för uppläsningen. Där kan du också hitta den här informationen igen.",
      "customizeHeading": "Inställningar för uppläsning",
      "heading": "Uppläsning av innehåll",
      "keyboard": "Du kan styra uppläsningen med tangentbordet. Gör så här: <ul class=\"nt1\"><li><b>Shift och mellanslag: Start och stopp</b></li><li><b>Shift och pil upp: Förra stycket</b></li><li><b>Shift och pil ned: Nästa stycke</b></li></ul>",
      "keyboardHeading": "Styr med tangenterna"
    },
    "speakerToggleButtonTooltip": "Uppläsning är inte tillgänglig för det aktuella språket.",
    "start": "Starta",
    "student": "Elev",
    "tableOfContents": "Innehållsförteckning",
    "teacher": "Lärare",
    "teacherAssignment": {
      "breadcrumb": {
        "assignments": "Uppgifter",
        "edit": "Redigera",
        "new": "Ny"
      },
      "confirmDelete": "Uppgiften kommer tas bort.",
      "edit": {
        "answeringTemplate": "Svarsmall",
        "cancel": "Avbryt",
        "delete": "Ta bort",
        "publish": "Publicera",
        "question": "Uppgift",
        "saveAsDraft": "Spara",
        "switchToDraft": "Avpublicera",
        "title": "Titel",
        "titlePlaceholder": "Ange titel på uppgift",
        "update": "Uppdatera",
        "visibleForStudents": "Synlig för elever"
      },
      "header": {
        "importFromArchive": "Från mitt arkiv",
        "newAssignment": "Ny uppgift"
      },
      "importModal": {
        "close": "Stäng",
        "import": "Välj"
      },
      "overview": {
        "draft": "Utkast",
        "filter": "Filtrera",
        "newAssignment": "Ny uppgift",
        "noAssignments": "<b>Du har inga läraruppgifter än.</b> Här kommer du hitta alla dina uppgifter som du som lärare har skapat till olika delar i dina produkter. Du kan också skapa nya läraruppgifter här. Om du vill kan du återanvända uppgifter och koppla dem till flera ställen i dina produkter eller i flera kurser till olika elevgrupper.",
        "noTitle": "(titel saknas)",
        "search": "Sök",
        "searchPlaceholder": "Sök …"
      }
    },
    "teacherGuide": {
      "text": "<h1 class=\\\"text-primary\\\">Vi hjälper dig!</h1><p>Om du behöver hjälp så finns vi alltid nära till hands. I vårt kundcenter kan du söka efter svar på vanliga frågor. Där finns även guider som lär dig hur du får ut mesta möjliga av de digitala produkterna.</p><p>Du öppnar kundcentret genom att trycka på <i>Support</i> uppe i menyn. Du får automatiskt förslag på artiklar som passar den sida du befinner dig på. Du som är lärare kan också chatta med kundsupporten. När du inte är inloggad hittar du kundcentret på <a href=\\\"https://support.nok.se\\\" target=\\\"_blank\\\">support.nok.se</a>.</p> <p>Om du har några som helst frågor &ndash; tveka inte att höra av dig till oss! <br/>Telefon: <a href=\\\"tel:+4684538700\\\">08-453 87 00</a> <br/> Mejl: <a href=\\\"mailto:kundsupport@nok.se\\\">kundsupport@nok.se</a></p>",
      "title": "Kom igång"
    },
    "templates": {
      "area": "Område",
      "book": "Bok",
      "chapter": "Kapitel",
      "concepts": "Begrepp",
      "content": "Innehåll",
      "diagnosis": "Diagnos",
      "diagnosis_part": "Diagnosdel",
      "flow": "Uppgiftsflöde",
      "folder": "Mapp",
      "glossaries": "Glosor",
      "grid": "Rutnät",
      "list": "Lista",
      "practice": "Uppgifter",
      "quiz": "Nivåträning",
      "standard": "Uppgifter",
      "story": "Bildberättelse",
      "workflow": "Arbetsflöde"
    },
    "terms": {
      "approve": "Jag godkänner användarvillkoren",
      "header": "Användarvillkor"
    },
    "todo": {
      "stepType": {
        "assignments": "Uppgifter",
        "collection": "Uppgiftssamling",
        "content": "Innehåll",
        "diagnosis": "Diagnos"
      }
    },
    "todoShare": {
      "modalTitle": "Dela uppdrag",
      "shareTodo": "Dela uppdrag"
    },
    "topMessage": {
      "close": "Stäng meddelande"
    },
    "training": {
      "continue": "Fortsätt",
      "heading": "Tävling som träning",
      "info": "Tävling som träning har flera nivåer. Du måste klara {answers} övningar för gå vidare till nästa nivå. Lycka till!",
      "restart": "Börja om",
      "start": "Starta",
      "stepDone": {
        "heading": "Grattis! Du har klarat denna tävling som träning!"
      },
      "stepUp": {
        "heading": "Härligt! Du klarade nivå {level}",
        "info": "Tävling som träning har flera nivåer. Gå vidare för att ta dig an nästa nivå."
      },
      "wellDone": "Bra jobbat!"
    },
    "types": {
      "archives": "Arkiv",
      "areas": "Arbetsområde",
      "assignments": "Arbetsuppgift",
      "books": "Bok",
      "collections": "Uppgiftsgrupp",
      "contents": "Innehåll",
      "exercises": "Övning",
      "folders": "Mapp",
      "glossaries": "Ordlista",
      "interactives": "Uppgifter",
      "sections": "Avsnitt",
      "studies": "Instudering"
    },
    "uncategorized": "Okategoriserat",
    "updateNotification": {
      "buttonLabel": "Uppdatera nu",
      "later": "Senare",
      "message": "Det finns en ny uppdatering.",
      "title": "Ny uppdatering"
    },
    "upload": {
      "default": "Ladda upp",
      "file": "Ladda upp fil",
      "image": "Ladda upp bild"
    },
    "uploadProfileImage": "Ladda upp bild",
    "userSelect": {
      "cancel": "Avbryt",
      "done": "Klar",
      "noAddedUsers": "Inga elever valda",
      "noUsersMessage": "Inga användare",
      "searchHeading": "Sök",
      "searchPlaceholder": "Sök användare",
      "selectUser": "Välj användare",
      "userEmailHeading": "E-post",
      "userNameHeading": "Namn"
    },
    "version": "VERSION {version}",
    "videoPlayer": {
      "advertisement": "Annons",
      "all": "Alla",
      "buffered": "Buffrat",
      "captions": "Undertexter",
      "currentTime": "Nuvarande tid",
      "disableCaptions": "Stäng av undertexter",
      "disabled": "Avstängd",
      "duration": "Längd",
      "enableCaptions": "Visa undertexter",
      "enabled": "Påslagen",
      "end": "Slut",
      "enterFullscreen": "Slå på fullskärm",
      "exitFullscreen": "Stäng av fullskärm",
      "fastForward": "Spola fram {seektime}s",
      "frameTitle": "Spelare för {title}",
      "loop": "Loopa",
      "menuBack": "Tillbaka till föregående meny",
      "mute": "Stäng av ljudet",
      "normal": "Normal",
      "pause": "Pausa",
      "play": "Starta",
      "played": "Spelat",
      "quality": "Kvalitet",
      "qualityBadge": {
        "1080": "HD",
        "1440": "HD",
        "2160": "4K",
        "480": "SD",
        "576": "SD",
        "720": "HD"
      },
      "reset": "Återställ",
      "restart": "Starta om",
      "rewind": "Spola tillbaka {seektime}s",
      "seek": "Sök",
      "seekLabel": "{currentTime} av {duration}",
      "settings": "Inställningar",
      "speed": "Hastighet",
      "start": "Start",
      "unmute": "Slå på ljudet",
      "volume": "Volym"
    },
    "weekdays": "Söndag,Måndag,Tisdag,Onsdag,Torsdag,Fredag,Lördag",
    "weekdaysShort": "Sön,Mån,Tis,Ons,Tor,Fre,Lör",
    "wordExplanation": {
      "addPlaceholder": "Lägg till …",
      "examples": "Exempel",
      "explanation": "Förklaring",
      "listName": "Ord från {title}",
      "savedTo": "Sparad till: {list}",
      "translation": "Översättning",
      "wordNotSavedMessage": "Ordet kunde inte sparas just nu.",
      "wordSavedMessage": "Ordet har sparats i ordlistan."
    },
    "wordQuiz": {
      "answer": "Svara",
      "cancel": "Avsluta",
      "explanation": "Förklaring",
      "inputPlaceholder": "Skriv ord …",
      "next": "Nästa",
      "noWordsMessage": {
        "concepts": "<p>Du kan inte träna på denna ordlista för att den saknar ord med förklaring. Lägg till en förklaring på orden genom att redigera dem ett ord i taget.</p><p>Du kan också lägga till nya ord i listan eller spara de gulmarkerade ord som finns i våra texter.</p>",
        "glossaries": "<p>Du kan inte träna på denna ordlista för att den saknar ord med översättning. Lägg till en översättning på orden genom att redigera dem ett ord i taget.</p><p>Du kan också lägga till nya ord i listan eller spara de gulmarkerade ord som finns i våra texter.</p>"
      },
      "practiceOnWords": "Träna på ord",
      "replaceWord": "(ord)",
      "restart": "Starta om",
      "summary": "Du hade {result} rätt av {total} möjliga.",
      "translation": "Översättning",
      "word": "Ord",
      "wrongAnswer": "Tyvärr, det rätta svaret är:"
    },
    "wordlists": {
      "addNewWord": "Lägg till ord",
      "addToMyWordlists": "Spara en kopia",
      "addedToMyWordlistsFailed": "Ordlistan kunde inte sparas till arkivet",
      "addedToMyWordlistsSuccess": "Ordlistan har sparats till arkivet",
      "alerts": {
        "noWordlists": "<b>Du har inga ordlistor än.</b> Här kommer du hitta alla ordlistor som du har gjort i dina produkter. Du kan också göra nya ordlistor här. Om du vill kan du lägga till samma ordlista till flera ställen i dina produkter.",
        "noWordlistsInContent": "Du har inga aktuella ordlistor till detta innehåll.",
        "noWordsinWordlist": "Ordlistan saknar ord."
      },
      "breadcrumb": {
        "edit": "Redigera",
        "new": "Ny",
        "wordlists": "Ordlistor"
      },
      "cancel": "Avbryt",
      "categories": {
        "relatedWordlists": "Relaterade ordlistor"
      },
      "create": "Skapa",
      "draft": "Utkast",
      "edit": "Redigera",
      "editWord": "Redigera ord",
      "filter": "Filtrera",
      "filterOptions": {
        "all": "Alla"
      },
      "heading": "Ordlistor",
      "importFromArchive": "Från mitt arkiv",
      "importModal": {
        "close": "Stäng",
        "import": "Välj"
      },
      "newWordHeading": "Nytt ord",
      "newWordlistButton": "Ny ordlista",
      "newWordlistHeading": "Ny ordlista",
      "remove": "Ta bort",
      "removeFromWorkspace": "Koppla bort",
      "save": "Spara",
      "searchLabel": "Sök",
      "searchPlaceholder": "Sök …",
      "share": "Dela",
      "startQuiz": "Träna",
      "wordForm": {
        "cancel": "Avbryt",
        "confirmDelete": "Ordet kommer tas bort.",
        "create": "Skapa",
        "delete": "Ta bort",
        "examplesLabel": "Exempel",
        "examplesPlaceholder": "",
        "explanationLabel": "Förklaring",
        "explanationPlaceholder": "",
        "save": "Spara",
        "translationLabel": "Översättning",
        "translationPlaceholder": "",
        "wordClassLabel": "Ordklass",
        "wordClassPlaceholder": "",
        "wordConstructionLabel": "Ordbildning",
        "wordConstructionPlaceholder": "",
        "wordError": "Skriv ett ord.",
        "wordLabel": "Ord",
        "wordPlaceholder": "Ord"
      },
      "wordlistForm": {
        "cancel": "Avbryt",
        "confirmDelete": "Ordlistan kommer tas bort.",
        "languages": {
          "english": "Engelska",
          "french": "Franska",
          "german": "Tyska",
          "italian": "Italienska",
          "spanish": "Spanska",
          "swedish": "Svenska"
        },
        "publish": "Publicera",
        "publishTooltip": "Dela ordlistan med dina elever",
        "remove": "Ta bort",
        "save": "Spara",
        "saveAsDraft": "Spara",
        "switchToDraft": "Avpublicera",
        "typeConcept": "Begrepp",
        "typeConceptExplanation": "Skriv förklaringar till ord, med samma språk som orden är skrivna på.",
        "typeGlossary": "Översättning",
        "typeGlossaryExplanation": "Översätt ord från ett annat språk.",
        "update": "Uppdatera",
        "visibleForStudents": "Synlig för elever",
        "wordlistLabel": "Titel",
        "wordlistLanguageLabel": "Språk",
        "wordlistLanguagePlaceholder": "Välj språk …",
        "wordlistPlaceholder": "Ange titel på ordlista",
        "wordlistTypeLabel": "Typ av ordlista"
      }
    },
    "workspace": {
      "assignments": {
        "noAssignments": "<p><strong>Det finns inga uppgifter här.</strong> Det kan bero på:</p><ul><li>Det finns inga färdiga uppgifter på denna position.</li><li>En lärare har valt att dölja de färdiga uppgifter som finns.</li></ul>{isTeacher, select, true {<p>Du som är lärare kan lägga till dina egna uppgifter här.</p>} other {}}"
      },
      "error": {
        "assignments": {
          "buttonText": "Fortsätt",
          "heading": "Uppgiften har tagits bort"
        },
        "notes": {
          "buttonText": "Fortsätt",
          "heading": "Anteckningen har tagits bort"
        },
        "wordlists": {
          "buttonText": "Fortsätt",
          "heading": "Ordlistan har tagits bort"
        }
      },
      "menu": {
        "exercises": "Uppgifter",
        "notes": "Anteckningar",
        "wordlists": "Ordlistor"
      },
      "workspace": "Arbetsbord"
    }
  }]];
});